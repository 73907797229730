import moment from "moment";
import { forwardRef } from "react";

import { Container, Copy, Error, InputWrapper, Label } from "../../styles";

import {
  InputContainer,
  StyledButton,
  StyledDatePicker,
  StyledInput,
} from "./styles";

interface Props {
  defaultValue?: Date;
  description?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  label: string;
  name: string;
  onChange({
    date,
    name,
  }: {
    date?: Date | [Date | null, Date | null] | null;
    name?: string;
  }): void;
  minDate?: Date;
  placeholder?: string;
}

export const DateTimePicker = ({
  defaultValue,
  description,
  disabled = false,
  error,
  errorMessage,
  label,
  minDate,
  name,
  onChange,
  placeholder = "Not set",
}: Props) => {
  const CustomInput = forwardRef((props: any, ref: any) => {
    const date = moment(new Date(props.value));
    const defVal = props.value ? date.format("MMM Do - h:mm A") : undefined;

    return (
      <>
        <InputContainer>
          <StyledInput
            defaultValue={defVal}
            disabled={true}
            placeholder={placeholder}
            ref={ref}
            type="text"
          />

          <StyledButton
            disabled={disabled}
            onClick={() => {
              if (!disabled) props.onClick();
            }}
            variant="green"
          >
            Select time
          </StyledButton>
        </InputContainer>
      </>
    );
  });

  CustomInput.displayName = "CustomInput";

  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>

      {description && <Copy>{description}</Copy>}

      <InputWrapper>
        <div>
          <StyledDatePicker
            customInput={<CustomInput />}
            dateFormat="MMMM d, yyyy h:mm aa"
            minDate={minDate}
            selected={defaultValue}
            showTimeInput={true}
            timeFormat="HH:mm"
            onChange={(date) => onChange({ date, name })}
          />
        </div>

        {error && <Error>⚠️ {errorMessage}</Error>}
      </InputWrapper>
    </Container>
  );
};
