import styled from "styled-components";

import { Borders, NewColors } from "@/themes/Default";

const Button = styled.button`
  border: none;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.35rem;
  position: absolute;
  right: -0.75rem;
  top: -0.75rem;
`;

export const DeleteButton = styled(Button)`
  background: ${NewColors.Negative};
`;

export const Drag = styled.div`
  background-color: ${NewColors.Deadfill};
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 1rem;
  cursor: grab;
  display: grid;
  place-items: center;
`;

export const EditButton = styled(Button)`
  background: ${NewColors.Green};
`;

export const OuterWrapper = styled.div`
  background: white;
  border: ${Borders.User};
  border-radius: 1rem;
  box-shadow: 0 8px 16px 0 rgb(74 75 79 / 20%);
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 2rem;
  position: relative;
`;

export const Overlay = styled.div`
  border: 0.25rem solid ${NewColors.Green};
  border-radius: 1rem;
  cursor: pointer;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
`;
