import { useMatch } from "react-router-dom";
import type { LinkProps } from "react-router-dom";

import { Channel, Image, ImageContainer, Name } from "./styles";

interface Props {
  image: string;
  name: string;
  onClick?(): void;
  url: string;
}

const NavLink = ({ children, to, ...props }: LinkProps) => {
  const match = useMatch("/channels/:slug/*");
  const active = to.toString().includes(match?.params.slug ?? "");

  return (
    <Channel $active={active} to={to} {...props}>
      {children}
    </Channel>
  );
};

export const ChannelLink = ({ image, name, onClick, url }: Props) => (
  <NavLink to={url} onClick={onClick}>
    <ImageContainer>
      <Image alt="" src={image} />
    </ImageContainer>

    <Name>{name}</Name>
  </NavLink>
);
