/* eslint-disable import/no-named-as-default-member */
import ReactPixel from "react-facebook-pixel";
import type { AdvancedMatching } from "react-facebook-pixel";

export function initFacebookPixel() {
  // See: https://developers.facebook.com/docs/facebook-pixel/implementation
  const options = {
    // Autoconfig was unreliable in identifying appropriate events so disabled
    autoConfig: false,
    debug: false,
  };

  // Init the pixel and fire a basic pageview event throughout the app
  const pixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;

  if (pixelId) ReactPixel.init(pixelId, {} as AdvancedMatching, options);
}

// For more info on available Facebook pixel events, see:
// https://www.facebook.com/business/help/402791146561655

export const logFacebookPageView = () => {
  // Pulls the current page URL out of the window so requires no args
  ReactPixel.pageView();
};
