import { ApolloQueryResult } from "@apollo/client";
import { Children, useRef } from "react";
import type { ReactNode } from "react";

import { Button } from "../../../../components/Button";
import { LoadingBoundary } from "../../../../components/LoadingBoundary";

import { ButtonWrapper, Container, Grid } from "./styles";

interface Props {
  children: ReactNode;
  error: boolean;
  fetchMore(): Promise<ApolloQueryResult<any>>;
  hasMore?: boolean;
  loading: boolean;
}

// TODO: No items state

export const EventGrid = ({
  children,
  error,
  fetchMore,
  hasMore = false,
  loading,
}: Props) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const { length } = Children.toArray(children);
  const initialLoading = loading && !length;

  const handleClick = async () => {
    const prevLength = length;
    const response = await fetchMore();

    if (response) {
      const previousLastElement = gridRef.current?.querySelector(
        `*:nth-child(${prevLength})`,
      );
      const focusableElement =
        previousLastElement?.querySelector("a") ||
        previousLastElement?.querySelector("button");

      focusableElement?.focus();
    }
  };

  return (
    <Container>
      <LoadingBoundary error={error} loading={initialLoading}>
        <Grid ref={gridRef}>{children}</Grid>

        {hasMore && (
          <ButtonWrapper>
            <Button onClick={handleClick} disabled={loading}>
              {loading ? "Loading" : "Load more…"}
            </Button>
          </ButtonWrapper>
        )}
      </LoadingBoundary>
    </Container>
  );
};
