import { faBars, faPencil, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useState } from "react";
import type { KeyboardEvent, MouseEvent } from "react";

import { NewColors } from "@/themes/Default";

import { DeleteModal, EditModal } from "./components";
import {
  ButtonContainer,
  DeleteButton,
  Drag,
  EditButton,
  OuterWrapper,
  Overlay,
} from "./styles";

// TODO: Rename this

interface LotCardWrapperProps {
  children: ReactNode;
}

export const LotCardWrapper = ({
  children = "Placeholder",
}: LotCardWrapperProps) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleClick = () => setShowOverlay(!showOverlay);
  const handleDeleteBtn = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowDeleteModal(!showDeleteModal);
  };
  const handleEditBtn = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowEditModal(!showEditModal);
  };

  const handleKeyDown = ({ key }: KeyboardEvent) => {
    if (key === "Enter") handleClick();
  };

  return (
    <>
      {showDeleteModal && (
        <DeleteModal handleToggle={handleDeleteBtn} show={showDeleteModal} />
      )}

      {showEditModal && (
        <EditModal handleToggle={handleEditBtn} show={showEditModal} />
      )}

      <OuterWrapper
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        {showOverlay && (
          <Overlay>
            <ButtonContainer>
              <EditButton aria-label="Edit lot" onClick={handleEditBtn}>
                <FontAwesomeIcon color="white" icon={faPencil} size="lg" />
              </EditButton>

              <DeleteButton aria-label="Delete lot" onClick={handleDeleteBtn}>
                <FontAwesomeIcon color="white" icon={faXmark} size="lg" />
              </DeleteButton>
            </ButtonContainer>
          </Overlay>
        )}

        {children}

        <Drag>
          <FontAwesomeIcon color={NewColors.TextWhisper} icon={faBars} />
        </Drag>
      </OuterWrapper>
    </>
  );
};
