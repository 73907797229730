export interface Error {
  code: number;
  message: string;
}

export enum WalletProvider {
  METAMASK = "MetaMask",
  PHANTOM = "Phantom",
}

export enum Currency {
  ETH = "ETH",
  SOL = "SOL",
}

export type Balances = { balance: string; currency: Currency }[];

export type SolNetwork = "mainnet" | "devnet";

export type EthNetwork = "eth" | "goerli";
