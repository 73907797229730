import type { ReactNode } from "react";

import { Container, Copy, Label } from "../../styles";

import { InputGrid } from "./styles";

interface InlineInputContainerProps {
  children: ReactNode;
  description?: string;
  label: string;
}

export const InlineInputContainer = ({
  children,
  description,
  label,
}: InlineInputContainerProps) => (
  <Container>
    <Label>{label}</Label>
    {description && <Copy>{description}</Copy>}

    <InputGrid>{children}</InputGrid>
  </Container>
);
