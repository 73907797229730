import { Navigate, Route, Routes } from "react-router-dom";

import { LoggedIn } from "@/components/LoggedIn";
import { MinimumLayout } from "@/components/MinimumLayout";

import { Me } from "./Me";
import { User } from "./User";

export const UsersRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate replace to="/" />} />

    <Route
      path="/me"
      element={
        <LoggedIn fallback={<Navigate replace to="/" />}>
          <MinimumLayout>
            <Me />
          </MinimumLayout>
        </LoggedIn>
      }
    />

    <Route
      path="/:walletAddress"
      element={
        <MinimumLayout>
          <User />
        </MinimumLayout>
      }
    />
  </Routes>
);
