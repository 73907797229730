import moment from "moment";

import { TimeLeft } from "@/components/TimeLeft";
import { formatWalletAddress } from "@/utils/formatWalletAddress";

import { useLot } from "../../hooks/useLot";

import { Container, Entry, Label, Status } from "./styles";

interface Props {
  id: string;
  insideModal?: boolean;
}

export const LotStatus = ({ id, insideModal = false }: Props) => {
  const { lot } = useLot(id);

  const formattedStartDate = moment(lot.scheduledStartTime).format(
    "MMM Do - h:mm A",
  );
  const hasBids = !!lot.bids.length;
  const winningWallet = formatWalletAddress({
    address: lot.highestBid.walletId,
  });
  const winningWalletLabel = lot.isUserWinning
    ? `You (${winningWallet})`
    : winningWallet;

  return (
    <Container insideModal={insideModal}>
      {lot.isUpcoming && (
        <Status full={true} insideModal={insideModal}>
          <Label>Bidding opens at</Label>
          <Entry>{formattedStartDate || "Coming soon"}</Entry>
        </Status>
      )}

      {lot.isAcceptingBids && (
        <>
          <Status full={true} insideModal={insideModal}>
            <Label>Time remaining</Label>
            <Entry>
              <TimeLeft endTime={lot.biddingEndsAfter} />
            </Entry>
          </Status>
        </>
      )}

      <Status>
        {lot.hasSold ? (
          <Label>Winning bid</Label>
        ) : (
          <Label>{hasBids ? "Current bid" : "Opening price"}</Label>
        )}

        <Entry>
          {lot.hasSold || hasBids
            ? lot.formattedPrices.highestBid
            : lot.formattedPrices.openingPrice}
        </Entry>
      </Status>

      <Status>
        {hasBids ? (
          <>
            <Label>Bid placed by</Label>
            <Entry title={lot.highestBid.walletId}>{winningWalletLabel}</Entry>
          </>
        ) : (
          <>
            <Label></Label>
            <Entry></Entry>
          </>
        )}
      </Status>
    </Container>
  );
};
