import { useMutation, useQuery } from "@apollo/client";

import { AuctionState, TeamMemberRole } from "@/generated/schema";
import type {
  AddTeamMemberToChannel,
  AuctionNodeEdge,
  ChannelNode,
  RemoveTeamMemberFromChannel,
  TeamMemberNodeEdge,
} from "@/generated/schema";
import { useFetchAccountData } from "@/hooks/useFetchAccountData";

import { ADD_TEAM_MEMBER } from "../api/ADD_TEAM_MEMBER";
import { GET_CHANNEL } from "../api/GET_CHANNEL";
import { REMOVE_TEAM_MEMBER } from "../api/REMOVE_TEAM_MEMBER";

interface QueryResponse {
  channel: ChannelNode;
}

interface AddMemberResponse {
  addTeamMemberToChannel: AddTeamMemberToChannel;
}

interface RemoveMemberResponse {
  removeTeamMemberFromChannel: RemoveTeamMemberFromChannel;
}

export const useChannel = (slug: string) => {
  const { accountData } = useFetchAccountData();
  const { data, error, loading, refetch } = useQuery<QueryResponse>(
    GET_CHANNEL,
    { variables: { slug } },
  );

  const [addTeamMember] = useMutation<AddMemberResponse>(ADD_TEAM_MEMBER, {
    variables: { channelId: data?.channel?.id },
    onCompleted: ({ addTeamMemberToChannel: { errors } }) => {
      const hasErrors = !!errors?.length;

      if (!hasErrors) refetch();
    },
  });

  const [removeTeamMember] = useMutation<RemoveMemberResponse>(
    REMOVE_TEAM_MEMBER,
    {
      onCompleted: ({ removeTeamMemberFromChannel: { errors } }) => {
        const hasErrors = !!errors?.length;

        if (!hasErrors) refetch();
      },
    },
  );

  const auctions = data?.channel?.auctions?.edges ?? [];
  const activeAuctions: AuctionNodeEdge[] = [];
  const draftAuctions: AuctionNodeEdge[] = [];
  const endedAuctions: AuctionNodeEdge[] = [];

  auctions.forEach((auction) => {
    const isActive = [AuctionState.LIVE, AuctionState.PUBLISHED].includes(
      auction?.node?.state!,
    );
    const isDraft = [AuctionState.DRAFT].includes(auction?.node?.state!);
    const isEnded = [AuctionState.CANCELED, AuctionState.ENDED].includes(
      auction?.node?.state!,
    );

    if (isActive) return activeAuctions.push(auction!);
    if (isDraft) return draftAuctions.push(auction!);
    if (isEnded) return endedAuctions.push(auction!);
  });

  const members = data?.channel?.teamMembers?.edges ?? [];
  const [owners, admins] = members?.reduce(
    (result, member) => {
      if (member?.node) {
        result[member?.node?.role === TeamMemberRole.OWNER ? 0 : 1].push(
          member,
        );
      }

      return result;
    },
    [[] as TeamMemberNodeEdge[], [] as TeamMemberNodeEdge[]],
  );

  const isOwner = !!owners?.filter(
    (owner) => owner?.node?.user.username === accountData.username,
  ).length;

  return {
    addTeamMember,
    admins,
    auctions: {
      active: activeAuctions,
      draft: draftAuctions,
      ended: endedAuctions,
    },
    channel: data?.channel,
    error: !!error,
    hasAccess: !!members.length,
    hasMembers: !!admins.length,
    isOwner,
    loading,
    owners,
    refetch,
    removeTeamMember,
  };
};
