import { faHand, faPlay, faUser } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useParams, useResolvedPath } from "react-router-dom";
import type { LinkProps } from "react-router-dom";

import { ChannelSelector } from "../ChannelSelector";

import {
  Block,
  Container,
  Heading,
  Icon,
  IconContainer,
  Link,
  Links,
  LinkText,
} from "./styles";

const NavLink = ({ children, to, ...props }: LinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link $active={!!match} to={to} {...props}>
      {children}
    </Link>
  );
};

export const Sidebar = () => {
  const { channelSlug } = useParams();

  return (
    <Container>
      <Block>
        <Heading>Manage Channel</Heading>
        <ChannelSelector />
      </Block>

      <Block>
        <Heading>Events</Heading>

        <Links>
          <NavLink to={`/channels/${channelSlug}/auctions`}>
            <IconContainer>
              <Icon icon={faHand} />
            </IconContainer>

            <LinkText>Auctions</LinkText>
          </NavLink>
        </Links>
      </Block>

      <Block>
        <Heading>Settings</Heading>

        <Links>
          <NavLink to={`/channels/${channelSlug}/profile`}>
            <IconContainer>
              <Icon icon={faPlay} />
            </IconContainer>

            <LinkText>Channel profile</LinkText>
          </NavLink>

          <NavLink to={`/channels/${channelSlug}/team-members`}>
            <IconContainer>
              <Icon icon={faUser} />
            </IconContainer>

            <LinkText>Team members</LinkText>
          </NavLink>
        </Links>
      </Block>
    </Container>
  );
};
