import { useEffect, useState } from "react";

import { timeRemaining } from "../utils/timeRemaining";

import { useInterval } from "./useInterval";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

export const useTimeRemaining = (endTime: Date | null | undefined) => {
  const [timeLeft, setTimeLeft] = useState(timeRemaining(endTime));
  const [refreshTime, setRefreshTime] = useState(SECOND);
  const noTimeLeft = !timeLeft.total;

  useEffect(() => setTimeLeft(timeRemaining(endTime)), [endTime]);
  useEffect(() => {
    if (timeLeft.days >= 1) return setRefreshTime(HOUR);
    if (timeLeft.hours >= 1) return setRefreshTime(MINUTE);

    return setRefreshTime(SECOND);
  }, [timeLeft]);

  useInterval(
    () => setTimeLeft(timeRemaining(endTime)),
    noTimeLeft ? null : refreshTime,
  );

  return { noTimeLeft, ...timeLeft };
};
