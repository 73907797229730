import type { ReactNode } from "react";

import { LoadingBoundary } from "../LoadingBoundary";
import { Section } from "../Section";

import { Contents, Description, Heading, HeadingWrapper } from "./styles";

interface Props {
  children: ReactNode;
  description?: string;
  error?: boolean;
  id?: string;
  loading?: boolean;
  heading: string;
  headingComponent?: ReactNode;
}

export const ContentSection = ({
  children,
  description,
  error,
  id,
  loading,
  heading,
  headingComponent,
}: Props) => (
  <Section id={id}>
    <HeadingWrapper>
      <Heading>{heading}</Heading>
      {headingComponent && headingComponent}
    </HeadingWrapper>

    {description && <Description>{description}</Description>}

    <Contents>
      <LoadingBoundary error={error} loading={loading}>
        {children}
      </LoadingBoundary>
    </Contents>
  </Section>
);
