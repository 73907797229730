import { ResponsiveImage } from "@/components/ResponsiveImage";
import { TimeLeft } from "@/components/TimeLeft";

import { useLot } from "../../hooks/useLot";

import {
  Container,
  Content,
  Detail,
  DetailHeading,
  DetailValue,
  Heading,
  ImageContainer,
  LowerContent,
  StyledLink,
  Subtitle,
  UpperContent,
} from "./styles";

interface Props {
  id: string;
  link: string;
}

export const FeaturedLotCard = ({ id, link }: Props) => {
  const { lot } = useLot(id);

  return (
    <Container>
      <ImageContainer>
        <ResponsiveImage alt="" height={378} src={lot.image} width={378} />
      </ImageContainer>

      <Content>
        <UpperContent>
          <Subtitle>{lot.collection}</Subtitle>

          <StyledLink to={link}>
            <Heading>{lot.name}</Heading>
          </StyledLink>
        </UpperContent>
      </Content>

      <LowerContent>
        <Detail>
          <DetailHeading>Time remaining</DetailHeading>
          <DetailValue>
            <TimeLeft endTime={lot.biddingEndsAfter} />
          </DetailValue>
        </Detail>

        <Detail>
          <DetailHeading>
            {lot.highestBid.bidPrice ? "Current bid" : "Opening price"}
          </DetailHeading>
          <DetailValue>
            {lot.highestBid.bidPrice
              ? lot.formattedPrices.highestBid
              : lot.formattedPrices.openingPrice}
          </DetailValue>
        </Detail>
      </LowerContent>
    </Container>
  );
};
