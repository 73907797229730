import DiscordLogoWordmarkColor from "@/images/brands/discord/Discord-Logo-Wordmark-Color.svg";
import GrailLogoGradientPlusDark from "@/images/logos/Logo Gradient Wordmark Dark.svg";

import {
  DiscordLogoImg,
  GrailLogoImg,
  LogosContainer,
  LogoSeparator,
} from "./styles";

export const DiscordLogos = () => (
  <LogosContainer>
    <DiscordLogoImg src={DiscordLogoWordmarkColor} />
    <LogoSeparator>+</LogoSeparator>
    <GrailLogoImg src={GrailLogoGradientPlusDark} />
  </LogosContainer>
);
