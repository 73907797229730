import { Link } from "react-router-dom";
import styled from "styled-components";

import { Breakpoints, Colors } from "../../themes/Default";

export const Container = styled.footer`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  width: 100%;
`;

export const Contents = styled.div`
  align-items: center;
  background: #f6f7f8;
  border-top: 1px solid rgb(0 0 0 / 10%);
  display: grid;
  gap: 0.75rem;
  grid-template-areas:
    "copyright"
    "internal"
    "social";
  justify-content: center;
  padding: 1rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    gap: 1.5rem;
    grid-template-areas: "copyright internal social";
    grid-template-columns: auto 1fr auto;
  }
`;

export const Copyright = styled.p`
  grid-area: copyright;
  margin: 0;
  text-align: center;
`;

export const InternalLinkWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const InternalSpacer = styled.span`
  color: rgb(44 77 118 / 33%);
  display: none;

  @media (min-width: ${Breakpoints.Mobile}) {
    display: block;
  }
`;

export const InternalLink = styled(Link)`
  color: ${Colors.Astronaut}cc;
`;

export const InternalLinks = styled.div`
  display: flex;
  gap: 1.5rem;
  grid-area: internal;
  justify-content: center;

  @media (min-width: ${Breakpoints.Mobile}) {
    justify-content: flex-start;
  }
`;

export const SocialLink = styled.a`
  color: rgb(44 77 118 / 80%);
  font-size: 1rem;
`;

export const SocialLinks = styled.div`
  display: flex;
  gap: 1.5rem;
  grid-area: social;
  justify-content: center;
`;
