import styled from "styled-components";

import { Borders, Colors } from "../../themes/Default";

export const Container = styled.div`
  border-top: ${Borders.Live};
  padding: 1.5rem 0;

  :last-of-type {
    border-bottom: ${Borders.Live};
  }
`;

export const Contents = styled.div`
  margin-top: 1.5rem;
`;

export const Count = styled.span`
  font-size: 1rem;
  font-weight: normal;
  text-transform: none;
`;

export const Heading = styled.h3`
  color: ${Colors.Waikawa};
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
`;

export const Toggle = styled.button`
  all: inherit;
  /* stylelint-disable */
  align-items: center;
  /* stylelint-enable */
  display: grid;
  grid-gap: 1.75rem;
  grid-template-columns: 1fr auto auto;
  justify-content: space-between;
  width: 100%;

  svg {
    fill: ${Colors.Blue};
    width: 1.25rem;
  }

  &:focus svg {
    outline: 2px solid;
  }

  &[aria-expanded] rect {
    fill: currentColor;
  }

  &[aria-expanded="true"] .vert {
    display: none;
  }
`;
