import {
  faCopy,
  faTimes,
  faBan,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useCallback } from "react";

import { Button } from "@/components/Button";
import { formatWalletAddress } from "@/utils/formatWalletAddress";

import { Balances } from "../../../../types";
import { Currencies } from "../../../../utils";

import {
  Balance,
  CloseButton,
  Container,
  Content,
  ErrorContainer,
  Error,
  Icon,
  Image,
  List,
  ListButton,
  ListButtonText,
  ManageIcon,
  Title,
  WalletAddress,
} from "./styles";

export interface Props {
  error: string;
  setError: (value: string) => void;
  walletAddress: string | null | undefined;
  deactivate: () => void;
  balances: Balances;
}

export const WalletDetails = ({
  error,
  setError,
  walletAddress,
  deactivate,
  balances,
}: Props) => {
  const [copied, setCopied] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const handleDisconnect = () => {
    localStorage.removeItem("provider");
    deactivate();
  };

  const copyAddress = useCallback(async () => {
    if (!walletAddress) return;

    await navigator.clipboard.writeText(`${walletAddress}`);

    setCopied(true);
    setTimeout(() => setCopied(false), 600);
  }, [walletAddress]);

  const formattedAddress = formatWalletAddress({
    address: walletAddress!,
    length: 25,
  });

  if (error) {
    return (
      <ErrorContainer>
        <Error>
          {error}

          <CloseButton onClick={() => setError("")}>
            <Icon icon={faTimes} />
          </CloseButton>
        </Error>
      </ErrorContainer>
    );
  }

  return (
    <>
      <Container>
        <Title>Balance</Title>

        <Content>
          {balances.map(({ balance, currency }) => (
            <Balance key={currency}>
              <span>
                <Image alt="" src={Currencies[currency].icon} />
                {Currencies[currency].currency}
              </span>
              {balance}
            </Balance>
          ))}

          <WalletAddress>{formattedAddress}</WalletAddress>

          <Button $fullWidth onClick={toggleDropdown}>
            Manage wallet
            <ManageIcon icon={faCaretDown} aria-hidden={true} size="1x" />
          </Button>

          {showDropdown && (
            <List>
              <ListButton onClick={copyAddress}>
                <ManageIcon icon={faCopy} aria-hidden={true} size="1x" />
                <ListButtonText>
                  {copied ? "Copied!" : "Copy address"}
                </ListButtonText>
              </ListButton>

              <ListButton onClick={handleDisconnect}>
                <ManageIcon icon={faBan} aria-hidden={true} size="1x" />
                <ListButtonText>Disconnect</ListButtonText>
              </ListButton>
            </List>
          )}
        </Content>
      </Container>
    </>
  );
};
