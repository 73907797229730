import { EmptyTab } from "@/components/EmptyTab";
import { LoadingBoundary } from "@/components/LoadingBoundary";

import { useUserNFTs } from "../../hooks/useUserNFTs";
import { NFTCard } from "../NFTCard";

import { Container } from "./styles";

interface Props {
  walletAddress: string;
}

export const NFTList = ({ walletAddress }: Props) => {
  const { error, loading, nftItems } = useUserNFTs(walletAddress);
  const noItemsOrError = error || !nftItems?.length;

  return (
    <LoadingBoundary loading={loading}>
      {noItemsOrError ? (
        <EmptyTab copy="Looks like they haven’t added any items to their wallet yet." />
      ) : (
        <Container>
          {nftItems.map((nftItem) => (
            <NFTCard
              key={nftItem?.node?.id}
              image={nftItem?.node?.importedImageUri ?? ""}
              name={nftItem?.node?.name!}
              projectName={nftItem?.node?.collection.name!}
            />
          ))}
        </Container>
      )}
    </LoadingBoundary>
  );
};
