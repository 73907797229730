import styled from "styled-components";

import { Breakpoints } from "@/themes/Default";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 1.5rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 2rem 3rem 5rem;
  }
`;

export const Copy = styled.p`
  color: rgba(31 31 35 / 80%);
  line-height: 1.5rem;
  margin: 0;
`;

export const Wrapper = styled.div`
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr;
  width: 100%;

  @media (min-width: ${Breakpoints.Mobile}) {
    grid-template-columns: 16rem 1fr;
    max-width: 55rem;
  }
`;
