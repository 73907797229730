import { useEffect, useState } from "react";

import { Modal } from "@/components/Modal";
import { useIsLoggedIn } from "@/hooks/useIsLoggedIn";
import { ETH_CHAINS } from "@/settings";

import { Chain, Container, customModalStyles } from "./styles";

export const NetworkChecker = () => {
  const CHAIN_ID = process.env.REACT_APP_ETH_NETWORK_ID;
  const provider = (window as any).ethereum;

  const [incorrectNetwork, setIncorrectNetwork] = useState(false);
  const { isLoggedIn } = useIsLoggedIn();

  const loggedInAndConnected = CHAIN_ID && isLoggedIn && provider;

  const checkNetwork = async () => {
    if (loggedInAndConnected) {
      const chainId = await provider.request({ method: "eth_chainId" });
      const targetChain = ETH_CHAINS[CHAIN_ID].network;

      return setIncorrectNetwork(chainId !== targetChain);
    }
  };

  useEffect(() => {
    if (provider) {
      provider.on("chainChanged", checkNetwork);
    }
  });
  useEffect(() => {
    checkNetwork();
  });

  if (loggedInAndConnected && incorrectNetwork) {
    return (
      <Modal
        closeable={false}
        customStyles={customModalStyles}
        isOpen={true}
        title={"⚠️ Incorrect network"}
      >
        <Container>
          It looks like your wallet is connected to the incorrect blockchain
          network. Please connect to <Chain>{ETH_CHAINS[CHAIN_ID].name}</Chain>{" "}
          to continue.
        </Container>
      </Modal>
    );
  }

  return null;
};
