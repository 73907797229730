import { Navigate, Route, Routes } from "react-router-dom";

import { MinimumLayout } from "../../../components/MinimumLayout";

import { Auction } from "./Auction";

export const AuctionsRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate replace to="/" />} />

    <Route
      path=":slug"
      element={
        <MinimumLayout>
          <Auction />
        </MinimumLayout>
      }
    />

    <Route
      path=":slug/:lotId"
      element={
        <MinimumLayout>
          <Auction />
        </MinimumLayout>
      }
    />
  </Routes>
);
