import type { ReactNode } from "react";

import {
  Children,
  Container,
  Description,
  Heading,
  Subtitle,
  Title,
} from "./styles";

interface Props {
  children?: ReactNode;
  description: string;
  subtitle: string;
  title: string;
}

export const LiveVideoSection = ({
  children,
  description,
  subtitle,
  title,
}: Props) => (
  <Container>
    <Heading>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Heading>

    <Description>{description}</Description>

    {children && <Children>{children}</Children>}
  </Container>
);
