import { KeyboardEvent, MouseEvent as ReactMouseEvent } from "react";

import { Button } from "@/components/Button";
import {
  InlineInput,
  InlineInputContainer,
  Input,
  TextArea,
} from "@/components/FormElements";
import {
  Modal,
  ModalButtonContainer,
  ModalContent,
  smallModalStyle,
} from "@/components/Modal";
import { useForm } from "@/hooks/useForm";

import { LotCard } from "../../../../../LotCard";

interface EditModalProps {
  handleToggle(
    event?: KeyboardEvent<Element> | ReactMouseEvent<Element, MouseEvent>,
  ): void;
  show: boolean;
}

// TODO: Input validation/mandatory fields

export const EditModal = ({ handleToggle, show }: EditModalProps) => {
  // TODO: Pull from actual item, using query/cache
  const { formState, handleInputChange } = useForm({
    description:
      "Infinite Eels is a collection of 999 pieces of generative abstract art that challenges viewers to see the world through the winding eel forms presented to them.",
    externalLink: "https://opensea.io/assets/ethereum/0x09b1a9dffd64e6dcad",
    openingPrice: "0.1",
    reservePrice: "3",
    title: "Infinite Eel #8792",
  });

  const handleSubmit = () => alert("To be implemented...");

  return (
    <Modal
      customStyles={{
        ...smallModalStyle,
        content: {
          ...smallModalStyle.content,
          maxHeight: "95vh",
        },
      }}
      handleClose={handleToggle}
      isOpen={show}
      title="Edit lot"
    >
      <ModalContent>
        <LotCard showDetails={false} />

        <Input
          defaultValue={formState.title}
          handleInput={handleInputChange}
          label="Title"
          name="title"
          type="text"
        />

        <TextArea
          defaultValue={formState.description}
          description="Presents the NFT’s own description by default."
          handleInput={handleInputChange}
          label="Description"
          name="description"
        />

        <Input
          defaultValue={formState.externalLink}
          description="Links out to the NFT’s OpenSea page by default."
          handleInput={handleInputChange}
          label="External link URL"
          name="externalLink"
          type="text"
        />

        <InlineInputContainer
          description="Specify where bidding should open and an optional reserve price."
          label="Prices"
        >
          <InlineInput
            defaultValue={formState.openingPrice}
            handleInput={handleInputChange}
            label="Opening price"
            name="openingPrice"
            placeholder="Enter a minimum of 0.01"
            type="text"
          />

          <InlineInput
            defaultValue={formState.reservePrice}
            handleInput={handleInputChange}
            label="Reserve price"
            name="reservePrice"
            placeholder="Optional value above opening price"
            type="text"
          />
        </InlineInputContainer>

        <ModalButtonContainer>
          <Button onClick={handleSubmit} variant="green">
            Save changes
          </Button>
        </ModalButtonContainer>
      </ModalContent>
    </Modal>
  );
};
