import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import { Colors } from "../../themes/Default";
import { Section } from "..//Section";
import { LoadingSpinner } from "../LoadingSpinner";

import { Loading, Status } from "./styles";

interface Props {
  children: ReactNode;
  error?: boolean;
  loading?: boolean;
  loadingColor?: string;
  onLoaded?(): void;
}

export const PageBoundary = ({
  children,
  error,
  loading,
  loadingColor = Colors.Waikawa,
  onLoaded = () => {},
}: Props) => {
  const [onloadCalled, setOnloadCalled] = useState(false);

  useEffect(() => {
    if (!error && !loading && !onloadCalled) {
      setOnloadCalled(true);
      onLoaded();
    }
  }, [error, loading, onloadCalled, onLoaded]);

  if (error)
    return (
      <Section id="error">
        <Status>There was an issue.</Status>
      </Section>
    );

  if (loading)
    return (
      <Section id="loading">
        <Loading>
          <LoadingSpinner color={loadingColor} size={4} />
          <Status>Loading...</Status>
        </Loading>
      </Section>
    );

  return <>{children}</>;
};
