import { Navigate, Route, Routes } from "react-router-dom";

import { LoggedIn } from "@/components/LoggedIn";
import { MinimumLayout } from "@/components/MinimumLayout";

import { Auctions } from "./Auctions";
import { ManageAuction } from "./ManageAuction";
import { Profile } from "./Profile";
import { TeamMembers } from "./TeamMembers";

// TODO: Move "Has Access Check" to this level rather than page level.
// TODO: Maybe make wrapper component that handles everything inside LoadingBoundary in the pages

export const ChannelsRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate replace to="/" />} />

    <Route
      path="/:channelSlug"
      element={
        <LoggedIn fallback={<Navigate replace to="/" />}>
          <MinimumLayout>
            <Auctions />
          </MinimumLayout>
        </LoggedIn>
      }
    />

    <Route
      path="/:channelSlug/auctions"
      element={
        <LoggedIn fallback={<Navigate replace to="/" />}>
          <MinimumLayout>
            <Auctions />
          </MinimumLayout>
        </LoggedIn>
      }
    />

    <Route
      path="/:channelSlug/profile"
      element={
        <LoggedIn fallback={<Navigate replace to="/" />}>
          <MinimumLayout>
            <Profile />
          </MinimumLayout>
        </LoggedIn>
      }
    />

    <Route
      path="/:channelSlug/team-members"
      element={
        <LoggedIn fallback={<Navigate replace to="/" />}>
          <MinimumLayout>
            <TeamMembers />
          </MinimumLayout>
        </LoggedIn>
      }
    />

    <Route
      path="/:channelSlug/manage-auction/:auctionId"
      element={
        <LoggedIn fallback={<Navigate replace to="/" />}>
          <MinimumLayout>
            <ManageAuction />
          </MinimumLayout>
        </LoggedIn>
      }
    />
  </Routes>
);
