import { gql } from "@apollo/client";

export const UPLOAD_CHANNEL_BANNER = gql`
  mutation UPLOAD_CHANNEL_BANNER($bannerImage: Upload!, $channelId: ID!) {
    uploadChannelBannerImage(bannerImage: $bannerImage, channelId: $channelId) {
      errors {
        field
        message
      }
    }
  }
`;
