import { gql } from "@apollo/client";

export const REMOVE_TEAM_MEMBER = gql`
  mutation REMOVE_TEAM_MEMBER($teamMemberId: ID!) {
    removeTeamMemberFromChannel(teamMemberId: $teamMemberId) {
      errors {
        field
        message
      }
    }
  }
`;
