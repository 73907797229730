import styled, { css } from "styled-components";

interface ContainerProps {
  showChat: boolean;
}

export const Container = styled.div<ContainerProps>`
  padding-top: 58.75%;
  position: relative;

  @media (min-width: 62rem) {
    padding-top: 56.25%;
  }

  ${({ showChat }) =>
    showChat &&
    css`
      padding-top: 140%;

      @media (min-width: 45rem) {
        padding-top: 40.95%;
      }
    `}
`;

export const Embed = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
`;
