import { useRef, useState } from "react";
import type { KeyboardEvent } from "react";
import { useParams } from "react-router-dom";

import { useFetchAccountData } from "@/hooks/useFetchAccountData";
import { useKeyboardNavigation } from "@/hooks/useKeyboardNavigation";

import { ChannelLink } from "./components/ChannelLink";
import {
  Arrow,
  Channel,
  Container,
  Contents,
  Name,
  Image,
  ImageContainer,
  Selector,
} from "./styles";

export const ChannelSelector = () => {
  const {
    accountData: { channels },
  } = useFetchAccountData();
  const { channelSlug } = useParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const [showContents, setShowContents] = useState(false);

  useKeyboardNavigation(containerRef, setShowContents);

  const handleKeydown = ({ key }: KeyboardEvent) => {
    if (key === "Enter") setShowContents(!showContents);
  };

  const activeChannel =
    channels.find((channel) => channel?.slug === channelSlug) ?? channels[0];

  return (
    <Container ref={containerRef}>
      <Selector
        onClick={() => setShowContents(!showContents)}
        onKeyDown={handleKeydown}
        open={showContents}
        tabIndex={0}
      >
        <Channel>
          <ImageContainer>
            <Image src={activeChannel?.profileImageUrl ?? ""} alt="" />
          </ImageContainer>

          <Name>{activeChannel?.name}</Name>
        </Channel>

        <Arrow />
      </Selector>

      <Contents hidden={!showContents}>
        {channels.map((channel) => (
          <ChannelLink
            key={channel?.id}
            image={channel?.profileImageUrl ?? ""}
            name={channel?.name!}
            onClick={() => setShowContents(false)}
            url={`/channels/${channel?.slug}/auctions`}
          />
        ))}
      </Contents>
    </Container>
  );
};
