import { useEffect } from "react";

import { logAmplitudeEvent } from "@/utils/analytics";
import { SUBMISSIONS_EVENTS } from "@/utils/analytics/events";

export const SubmitNFTs = () => {
  useEffect(() => {
    logAmplitudeEvent(SUBMISSIONS_EVENTS.EXTERNAL_FORM_VIEWED);
    window.location.replace("https://73l904at9oj.typeform.com/to/hm46UJaG");
  });

  return null;
};
