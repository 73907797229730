import styled, { css } from "styled-components";

import { Borders, NewColors } from "@/themes/Default";

export const Auctions = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
  margin-top: 1.5rem;
`;

export const Container = styled.section``;

export const Content = styled.div`
  width: 100%;
`;

export const Control = styled.button<{ active?: boolean }>`
  background: transparent;
  border: none;
  color: ${NewColors.TextShout};
  font-weight: bold;
  line-height: 2rem;
  opacity: 0.4;
  outline-offset: 0.25rem;
  padding: 0;

  ${({ active }) =>
    active &&
    css`
      color: ${NewColors.Purple};
      opacity: 1;
    `}
`;

export const Controls = styled.div`
  border-bottom: ${Borders.User};
  display: flex;
  gap: 2rem;
  padding-bottom: 0.75rem;
`;
