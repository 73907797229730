import styled from "styled-components";

import { NewColors } from "@/themes/Default";

export const Container = styled.div`
  cursor: pointer;
  position: relative;
`;

export const IconContainer = styled.div`
  background: ${NewColors.Green};
  border: none;
  border-radius: 50%;
  display: grid;
  height: 2rem;
  place-items: center;
  position: absolute;
  right: -0.75rem;
  top: -0.75rem;
  width: 2rem;
`;

export const Selected = styled.div`
  border: 0.25rem solid ${NewColors.Green};
  border-radius: 1rem;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
