import { gql } from "@apollo/client";

export const GET_REMINT = gql`
  query GET_REMINT($id: ID, $slug: String) {
    remint: boxBreak(id: $id, slug: $slug) {
      description
      maxSpotsPerUser
      name
      scheduledStartTime
      state
      twitchChannelName
      twitchVideoId
      youtubeVideoId

      boxbreakphotoSet {
        edges {
          node {
            photoMd
          }
        }
      }

      format {
        description
        name
      }

      myParticipant {
        spotSet {
          totalCount
          edges {
            node {
              id
              name
              price
              priceCurrency
              purchasedAt
              rank
            }
          }
        }
      }

      remintnftSet {
        totalCount
        edges {
          node {
            id
            collectionName
            externalLink
            externalLinkText
            image
            name
          }
        }
      }

      spotSet {
        totalCount
        edges {
          node {
            id
            name
            price
            priceCurrency
            purchasedAt
            rank
          }
        }
      }
    }
  }
`;
