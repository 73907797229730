export interface TimeRemaining {
  total: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export function timeRemaining(endtime: Date | null | undefined): TimeRemaining {
  const total = endtime
    ? Date.parse(endtime.toString()) - Date.parse(new Date().toString())
    : 0;
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total: total > 0 ? total : 0,
    days,
    hours,
    minutes,
    seconds,
  };
}
