import { gql } from "@apollo/client";

// We cannot use the Apollo client inside the link middleware so these need to be JSON
export const DELETE_COOKIES_MUTATION = () =>
  JSON.stringify({
    query: `mutation { deleteTokenCookie { deleted } deleteRefreshTokenCookie { deleted } }`,
  });

export const DELETE_COOKIES = gql`
  mutation {
    deleteTokenCookie {
      deleted
    }
    deleteRefreshTokenCookie {
      deleted
    }
  }
`;
