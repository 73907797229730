import styled, { css } from "styled-components";

import { Borders, Gradients, NewColors } from "@/themes/Default";

const Wrapper = styled.div`
  background: white;
  border: ${Borders.User};
  border-radius: 0.5rem;
  padding: 0.5rem;
`;

export const Arrow = styled.div`
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid ${NewColors.TextShout};
  height: 0;
  width: 0;
`;

export const Channel = styled.div`
  align-items: center;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 3rem 1fr;
  padding: 0.25rem;
`;

export const Container = styled.div`
  position: relative;
`;

export const Contents = styled(Wrapper)`
  box-shadow: 0 4px 8px 0 rgb(74 75 79 / 20%);
  display: grid;
  gap: 0.5rem;
  margin-top: 0.25rem;
  position: absolute;
  top: 100%;
  width: 100%;
`;

export const Image = styled.img`
  width: 100%;
`;

export const ImageContainer = styled.div`
  background: ${Gradients.Channels};
  background-color: ${NewColors.Deadfill};
  border-radius: 0.25rem;
  height: 3rem;
  overflow: hidden;
  width: 3rem;
`;

export const Name = styled.p`
  color: ${NewColors.TextShout};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin: 0;
`;

export const Selector = styled(Wrapper)<{ open?: boolean }>`
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr auto;
  padding-right: 0.75rem;

  ${({ open }) =>
    open &&
    css`
      border-color: ${NewColors.Purple};

      ${Arrow} {
        border-bottom: 8px solid ${NewColors.Purple};
        border-top: none;
      }
    `}
`;
