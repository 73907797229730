import { useQuery } from "@apollo/client";
import type { OperationVariables } from "@apollo/client";

import type { BoxBreakNodeConnection } from "../../../generated/schema";
import { GET_REMINTS } from "../api/GET_REMINTS";

interface QueryResponse {
  remints: BoxBreakNodeConnection;
}

export const useRemints = (options?: OperationVariables) => {
  const { data, error, fetchMore, loading } = useQuery<QueryResponse>(
    GET_REMINTS,
    {
      ...options,
      notifyOnNetworkStatusChange: true,
    },
  );

  const remints = data?.remints.edges ?? [];
  const cursor = data?.remints.pageInfo.endCursor;
  const hasMore = data?.remints.pageInfo.hasNextPage ?? false;

  return {
    error: !!error,
    fetchMore: () => fetchMore({ variables: { cursor } }),
    hasMore,
    loading,
    remints,
  };
};
