export const formatWalletAddress = ({
  address,
  cropDomain,
  length = 10,
}: {
  address: string;
  cropDomain?: boolean;
  length?: number;
}) => {
  const hasDomain = address?.includes(".");
  const canBeCropped = address?.length > length && cropDomain;

  if (hasDomain)
    return canBeCropped ? `${address?.slice(0, length)}…` : address;
  if (address?.length <= length) return address;

  return `${address?.slice(0, length - 4)}…${address?.slice(-4)}`;
};
