import { useParams } from "react-router-dom";

import { ProfileHeader } from "../components/ProfileHeader";
import { ProfileNavigation } from "../components/ProfileNavigation";

export const User = () => {
  const { walletAddress } = useParams();

  return (
    <>
      <ProfileHeader walletAddress={walletAddress!} />
      <ProfileNavigation walletAddress={walletAddress!} />
    </>
  );
};
