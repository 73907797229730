import { useCallback, useEffect } from "react";
import { useMoralis } from "react-moralis";

import { CryptoCurrencies } from "@/generated/schema";
import { useWalletAuthentification } from "@/hooks/useWalletAuthentification";

export const useMetamask = () => {
  const { authenticate, user } = useMoralis();
  const { login, logout } = useWalletAuthentification({
    cryptoWalletId: user?.attributes?.ethAddress!,
    moralisUserId: user?.id,
    logOutUser: false,
    chain: CryptoCurrencies.ETH,
    user,
  });

  const handleAccountChange = useCallback(async () => {
    await logout();
    await authenticate({
      onSuccess: (newUser) => {
        const variables = {
          cryptoWalletId: newUser?.attributes?.ethAddress,
          moralisUserId: newUser?.id,
          logOutUser: false,
          signature: newUser?.attributes?.authData?.moralisEth?.signature,
          moralisToken: newUser?.attributes?.authData?.moralisEth?.data,
        };

        login({ variables });
      },
      provider: "metamask",
      signingMessage:
        "Sign this message to to take part in NFT events and customize your profile",
    });
  }, [authenticate, login, logout]);

  useEffect(() => {
    const eth = window.ethereum as any;

    if (window.ethereum) {
      eth.on("accountsChanged", () => handleAccountChange());
    }
  }, [handleAccountChange]);
};
