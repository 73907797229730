import parse from "html-react-parser";
import remark from "remark";
import footnotes from "remark-footnotes";
import gfm from "remark-gfm";
import html from "remark-html";

export const getContentful = async (query: string) => {
  const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
  const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
  const ENVIRONMENT_ID = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID;
  
  const CONTENTFUL_URL = `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/${ENVIRONMENT_ID}`;

  const data = await window.fetch(CONTENTFUL_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    },
    body: JSON.stringify({ query }),
  });
  const response = data.json();

  return response;
};

export const markdown = (md: string) =>
  parse(remark().use(gfm).use(footnotes).use(html).processSync(md).toString());
