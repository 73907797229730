export const pluralize = (
  word: string,
  count: number | null,
  customPlural?: string,
) => {
  return customPlural
    ? count === 1
      ? `${count} ${word}`
      : `${count} ${customPlural}`
    : count === 1
    ? `${count} ${word}`
    : `${count} ${word}s`;
};
