import { useMutation } from "@apollo/client";

import { useAuction } from "@/features/nftAuctions";
import {
  AuctionDraftDelete,
  AuctionDraftEdit,
  AuctionState,
} from "@/generated/schema";

import { AUCTION_DELETE } from "../api/AUCTION_DELETE";
import { AUCTION_EDIT } from "../api/AUCTION_EDIT";

interface DeleteDraftMutationRequest {
  auctionDraftDelete: AuctionDraftDelete;
}

interface EditDraftMutationRequest {
  auctionDraftEdit: AuctionDraftEdit;
}

export const useDraftAuction = (id: string) => {
  const { auction, loading, refetchAuction } = useAuction({ id });

  const auctionDraftDelete = useMutation<DeleteDraftMutationRequest>(
    AUCTION_DELETE,
    {
      variables: { auctionId: auction.id },
    },
  );

  const auctionDraftEdit = useMutation<EditDraftMutationRequest>(AUCTION_EDIT, {
    variables: {
      auctionId: auction.id,
      description: auction.description,
      name: auction.name,
      twitchChannelName: auction.twitchChannelName,
      twitchVideoId: auction.twitchVideoId,
      youtubeVideoId: auction.youtubeVideoId,
    },
    onError: (err) => console.log(err),
  });

  const isDraft = auction.state === AuctionState.DRAFT;
  const isPublished = auction.state === AuctionState.PUBLISHED;
  const isEditable = isDraft || isPublished;

  const hasEventDetails = !!(auction.name && auction.description);
  const hasLiveStreamDetails = !!(
    (auction.youtubeVideoId || auction.twitchChannelName) &&
    auction.scheduledStartTime
  );
  const hasLots = !!auction.lotCount;
  const canBePublished = hasEventDetails && hasLiveStreamDetails && hasLots;

  const getPlatform = (): "" | "Twitch" | "YouTube" => {
    if (auction.twitchChannelName || auction.twitchVideoId) return "Twitch";
    if (auction.youtubeVideoId) return "YouTube";

    return "";
  };

  return {
    auction: auction.name ? auction : null,
    auctionDraftDelete,
    auctionDraftEdit,
    canBePublished,
    isDraft,
    isPublished,
    isEditable,
    loading,
    platform: getPlatform(),
    refetchAuction,
  };
};
