import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { Page } from "@/components/Contentful/Page";
import { ContentWrapper } from "@/components/ContentWrapper";
import { logAmplitudeEvent } from "@/utils/analytics";
import { SUPPORT_EVENTS } from "@/utils/analytics/events";
import { markdown } from "@/utils/contentful";

export const Faq = () => {
  useEffect(() => logAmplitudeEvent(SUPPORT_EVENTS.FAQ.PAGE_VIEWED));

  return (
    <>
      <Helmet>
        <title>FAQ</title>
      </Helmet>

      <ContentWrapper>
        <Page slug="nfts-faq">
          {(page) => <>{page.body && markdown(page.body)}</>}
        </Page>
      </ContentWrapper>
    </>
  );
};
