import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Button } from "@/components/Button";
import { Colors, NewColors } from "@/themes/Default";

export const Category = styled.h3`
  color: ${NewColors.TextWhisper};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
`;

export const Contents = styled.div`
  background: #f7f9fd;
  border: 1px solid ${Colors.Glacier};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

export const Heading = styled.h2`
  color: ${NewColors.TextShout};
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 0;
  padding: 1.5rem;
`;

export const LinkIcon = styled(FontAwesomeIcon)`
  color: ${NewColors.Purple};
  height: 1.25rem;
  width: 1.25rem;
`;

export const Logout = styled(Button)`
  margin-top: 1.25rem;
  width: 100%;
`;

export const Menu = styled.div`
  background-color: white;
  border: 1px solid ${Colors.Glacier};
  border-radius: 0.5rem;
  box-shadow: 0 4px 16px 0 rgb(5 21 52 / 12%);
  margin-top: 0.5rem;
  overflow: hidden;
  position: absolute;
  right: -0.5rem;
  width: 20rem;
  z-index: 999;
`;

export const Navigation = styled.div`
  padding: 1.5rem 1rem 1rem;
`;

export const NavLink = styled(Link)`
  align-items: center;
  color: ${NewColors.TextSpeak};
  display: grid;
  grid-gap: 0.625rem;
  grid-template-columns: auto 1fr;
  line-height: 1.25rem;
  padding: 0.875rem 0;
`;

export const Section = styled.div`
  & + & {
    margin-top: 1.5rem;
  }
`;

export const ToggleButton = styled.button`
  background: ${NewColors.Secondary};
  border: 0;
  border-radius: 50%;
  display: grid;
  height: 2.5rem;
  margin: 0;
  padding: 0;
  place-items: center;
  width: 2.5rem;

  :hover {
    background: ${NewColors.SecondaryDarkened};
  }
`;

export const UserIcon = styled(FontAwesomeIcon)`
  height: 1.15rem;
`;

export const Wrapper = styled.div`
  position: relative;
`;
