import type { OperationVariables } from "@apollo/client";

import type { CryptoCurrencies } from "@/generated/schema";

import { EventGrid } from "../../../events";
import { useRemints } from "../../hooks/useRemints";
import { RemintCard } from "../RemintCard";

interface Props {
  options?: OperationVariables;
}

export const RemintGrid = ({ options }: Props) => {
  const { error, fetchMore, hasMore, loading, remints } = useRemints(options);

  return (
    <EventGrid
      error={error}
      fetchMore={fetchMore}
      hasMore={hasMore}
      loading={loading}
    >
      {remints?.map((remint) => (
        <RemintCard
          key={remint?.node?.id}
          featuredRank={remint?.node?.featuredRank}
          currency={remint?.node?.spotsCurrency as CryptoCurrencies}
          image={remint?.node?.boxbreakphotoSet.edges[0]?.node?.photoMd}
          minPrice={remint?.node?.spotsMinPrice}
          scheduledStartTime={remint?.node?.scheduledStartTime}
          slug={remint?.node?.slug!}
          spotAvailable={remint?.node?.spotsAvailableCount!}
          spotTotal={remint?.node?.spotsTotalCount!}
          state={remint?.node?.state!}
          title={remint?.node?.name!}
        />
      ))}
    </EventGrid>
  );
};
