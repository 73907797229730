import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { KeyboardEvent, ReactNode } from "react";

import { Container, IconContainer, Selected } from "./styles";

interface NFTCardWrapperProps {
  children: ReactNode;
  onClick(): void;
  selected?: boolean;
}

export const NFTCardWrapper = ({
  children,
  onClick,
  selected,
}: NFTCardWrapperProps) => {
  const handleKeydown = ({ key }: KeyboardEvent) => {
    if (key === "Enter") onClick();
  };

  return (
    <Container
      onClick={onClick}
      onKeyDown={handleKeydown}
      role="button"
      tabIndex={0}
    >
      {children}

      {selected && (
        <Selected>
          <IconContainer>
            <FontAwesomeIcon color="white" icon={faCheck} size="lg" />
          </IconContainer>
        </Selected>
      )}
    </Container>
  );
};
