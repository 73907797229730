import { useState } from "react";

import { ContentSection, Copy, Label, Placeholder } from "../Capsule";

import { EditModal } from "./components/EditModal";
import { Container } from "./styles";

export const AuctionSettings = () => {
  const [showModal, setShowModal] = useState(false);
  const handleToggle = () => setShowModal(!showModal);

  return (
    <>
      {showModal && <EditModal handleToggle={handleToggle} show={showModal} />}

      <Container edit={handleToggle} heading="Auction settings">
        <ContentSection>
          <Label>Open lots for bidding</Label>
          <Copy>Automatically when auction is published</Copy>
        </ContentSection>

        <ContentSection>
          <Label>First lot concludes at</Label>
          <Placeholder>Not set</Placeholder>
        </ContentSection>

        <ContentSection>
          <Label>Time gap between lots</Label>
          <Copy>5 minutes</Copy>
        </ContentSection>

        <ContentSection>
          <Label>Late bid extensions</Label>
          <Copy>30 seconds</Copy>
        </ContentSection>
      </Container>
    </>
  );
};
