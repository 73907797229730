import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

import { CREATE_BID } from "../api/CREATE_BID";
import { GET_AUCTION } from "../api/GET_AUCTION";
import { GET_LOT } from "../api/GET_LOT";
import type { BidCreatedResponse } from "../types";

export const useUpdateCache = () => {
  const client = useApolloClient();

  const addBid = useCallback(
    ({ bid }: BidCreatedResponse) => {
      const newBid = {
        id: bid.id,
        bidPrice: bid.bidPrice,
        bidPriceCurrency: bid.bidPriceCurrency,
        createdAt: bid.createdAt,
        state: bid.state,
        user: {
          id: bid.user.id,
          username: bid.user.username,
          __typename: "UserType",
        },
        __typename: "BidNode",
      };

      client.writeQuery({
        query: CREATE_BID,
        variables: {
          lotId: bid.lot.id,
          bidCurrency: bid.bidPriceCurrency,
          bidPrice: bid.bidPrice,
        },
        data: {
          bidCreate: {
            errors: [],
            bid: newBid,
            lot: {
              id: bid.lot.id,
              biddingEndsAfter: bid.lot.biddingEndsAfter,
              highestBidPrice: bid.lot.highestBidPrice,
              bidPriceIncrement: bid.lot.bidPriceIncrement,
              currency: bid.lot.currency,
              minimumNextBidPrice: bid.lot.minimumNextBidPrice,
              name: bid.lot.name,
              order: bid.lot.order,

              highestBid: newBid,
            },
          },
        },
      });

      const id = client.cache.identify({
        id: bid.lot.id,
        __typename: "LotNode",
      });

      client.cache.modify({
        id,
        fields: {
          bidSet(currentBids = []) {
            const newBidNodeEdge = {
              __typename: "BidNodeEdge",
              node: { __ref: `BidNode:${bid.id}` },
            };

            const bidExists = currentBids.edges.some(
              (ref: any) => ref.node["__ref"] === `BidNode:${bid.id}`,
            );

            if (bidExists) return currentBids;

            return {
              ...currentBids,
              edges: [...currentBids.edges, newBidNodeEdge],
            };
          },
          biddingEndsAfter: () => bid.lot.biddingEndsAfter,
          highestBidPrice: () => bid.lot.highestBidPrice,
          minimumNextBidPrice: () => bid.lot.minimumNextBidPrice,
          highestBid: () => ({ ...newBid }),
        },
      });
    },
    [client],
  );

  const refetchAuction = useCallback(
    () => client.refetchQueries({ include: [GET_AUCTION] }),
    [client],
  );

  const refetchLot = useCallback(
    () => client.refetchQueries({ include: [GET_LOT] }),
    [client],
  );

  return { addBid, refetchAuction, refetchLot };
};
