export const useDeviceType = () => {
  const ua = navigator.userAgent;
  const tablet = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua);
  const mobile =
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/i.test(
      ua,
    );

  return {
    isMobileDevice: mobile || tablet,
  };
};
