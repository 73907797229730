import { Count, CollapsibleSection } from "@/components/CollapsibleSection";
import { ContentSection } from "@/components/ContentSection";
import { EmptyTab } from "@/components/EmptyTab";
import { pluralize } from "@/utils/content";

import type { Auction, AuctionAnalytics } from "../../hooks/useAuction";
import { LotCard } from "../LotCard";

import { Grid } from "./styles";

interface Props {
  auction: Auction;
  auctionAnalytics?: AuctionAnalytics;
}

export const LotsSection = ({ auction, auctionAnalytics }: Props) => {
  const {
    lotCount,
    lotIds: { bidding, concluded, upcoming },
  } = auction;

  const biddingLotCards = bidding.map((id) => (
    <LotCard key={id} id={id} auctionAnalytics={auctionAnalytics} />
  ));
  const concludedLotCards = concluded.map((id) => (
    <LotCard key={id} id={id} auctionAnalytics={auctionAnalytics} />
  ));
  const upcomingLotCards = upcoming.map((id) => (
    <LotCard key={id} id={id} auctionAnalytics={auctionAnalytics} />
  ));

  return (
    <ContentSection
      description="Check out all of the lots up for grabs in this auction event"
      heading="🛒 Auction lots"
      id="lots"
    >
      {!lotCount ? (
        <EmptyTab copy="Nothing yet, check back later!" />
      ) : (
        <>
          {!!bidding.length && (
            <CollapsibleSection
              headingComponent={
                <Count>{pluralize("lot", bidding.length)}</Count>
              }
              title="Bidding Lots"
            >
              <Grid>{biddingLotCards}</Grid>
            </CollapsibleSection>
          )}

          {!!upcoming.length && (
            <CollapsibleSection
              headingComponent={
                <Count>{pluralize("lot", upcoming.length)}</Count>
              }
              title="Upcoming Lots"
            >
              <Grid>{upcomingLotCards}</Grid>
            </CollapsibleSection>
          )}

          {!!concluded.length && (
            <CollapsibleSection
              headingComponent={
                <Count>{pluralize("lot", concluded.length)}</Count>
              }
              title="Concluded Lots"
            >
              <Grid>{concludedLotCards}</Grid>
            </CollapsibleSection>
          )}
        </>
      )}
    </ContentSection>
  );
};
