import styled, { css } from "styled-components";

import { Breakpoints, Colors, CONTENT_WIDTH } from "../../themes/Default";

interface ContainerProps {
  full?: boolean;
}

export const Content = styled.div`
  max-width: ${CONTENT_WIDTH};
  width: 100%;
`;

export const Container = styled.section<ContainerProps>`
  background: white;
  box-shadow: 0 1px 0 0 ${Colors.Border}, 0 -1px 0 0 ${Colors.Border};
  display: grid;
  grid-template-columns: 100%;
  padding: 1.5rem 1rem;
  place-items: center;
  position: relative;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 3rem;
  }

  ${({ full }) =>
    full &&
    css`
      padding: 0;

      @media (min-width: ${Breakpoints.Mobile}) {
        padding: 0;
      }

      ${Content} {
        max-width: 100%;
      }
    `}

  & + & {
    margin-top: 0.75rem;

    @media (min-width: ${Breakpoints.Mobile}) {
      margin-top: 1.5rem;
    }
  }
`;
