import { useWallet } from "../hooks/useWallet";
import { WalletProvider } from "../types";
import { getWalletByProvider } from "../utils";

import { useMoralisUser } from "./useMoralisUser";

export const useConnect = (provider: WalletProvider) => {
  const { provider: walletName, chain } = getWalletByProvider(provider);

  const { currentMoralisUserProvider } = useMoralisUser();
  const {
    connect,
    isWalletConnected,
    noWalletDetected,
    provider: fetchedProvider,
  } = useWallet(chain);

  const connectedWalletIsWrong =
    isWalletConnected && fetchedProvider !== currentMoralisUserProvider;
  const userHasWalletExtension = !isWalletConnected && !!fetchedProvider;

  const handleConnect = () => {
    localStorage.setItem("provider", `${walletName}`);
    connect();
  };

  return {
    connectedWalletIsWrong,
    handleConnect,
    isWalletConnected,
    noWalletDetected,
    provider: fetchedProvider,
    userHasWalletExtension,
  };
};
