import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Error, SubmitButton, Success } from "@/components/FormElements";
import {
  Modal,
  ModalContent,
  ModalCopy,
  smallModalStyle,
} from "@/components/Modal";

import { useChannel } from "../../../../hooks/useChannel";
import { useDraftAuction } from "../../../../hooks/useDraftAuction";
import { ButtonContainer } from "../../styles";

interface DeleteDraftModalProps {
  auctionId: string;
  channelSlug: string;
  handleToggle(): void;
  show: boolean;
}

export const DeleteDraftModal = ({
  auctionId,
  channelSlug,
  handleToggle,
  show,
}: DeleteDraftModalProps) => {
  const navigate = useNavigate();
  const { refetch } = useChannel(channelSlug);
  const { auctionDraftDelete } = useDraftAuction(auctionId);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [deleteDraft, { loading: submitting }] = auctionDraftDelete;

  const handleClick = () => {
    deleteDraft({
      onCompleted: ({ auctionDraftDelete: { errors } }) => {
        const hasErrors = !!errors?.length;

        setError(hasErrors);
        setSuccess(!hasErrors);

        if (!hasErrors) {
          refetch();
          setTimeout(() => navigate(`/channels/${channelSlug}`), 1500);
        }
      },
      onError: () => setError(true),
    });
  };

  return (
    <Modal
      customStyles={smallModalStyle}
      handleClose={handleToggle}
      isOpen={show}
      title="Delete auction"
    >
      <ModalContent>
        <ModalCopy>
          Are you sure you wish to delete this auction? Once deleted it cannot
          be restored.
        </ModalCopy>

        <ButtonContainer>
          <SubmitButton
            disabled={submitting || success}
            onClick={handleClick}
            submitting={submitting}
            variant="red"
          >
            Yes, delete auction
          </SubmitButton>
        </ButtonContainer>

        {error && <Error>❌ Something went wrong, please try again!</Error>}
        {success && <Success>✅ Auction has been deleted</Success>}
      </ModalContent>
    </Modal>
  );
};
