import styled from "styled-components";

import { Breakpoints, Colors } from "../../../../../../themes/Default";

export const Container = styled.div`
  background-color: #dee2ea;
  border-radius: 0.5rem;
  display: none;
  height: 0.5rem;
  margin: 3.75rem auto 0;
  max-width: 41.5rem;
  overflow: hidden;
  position: relative;
  width: 50%;

  @media (min-width: ${Breakpoints.Mobile}) {
    display: block;
  }
`;

export const Bar = styled.div`
  background-color: ${Colors.GreyBlue};
  border-radius: 0.25rem;
  bottom: 0;
  left: -100%;
  position: absolute;
  top: 0;
  width: 100%;
`;
