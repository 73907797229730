import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "@/components/Button";
import {
  InlineInput,
  InlineInputContainer,
  Input,
  TextArea,
} from "@/components/FormElements";
import { ModalContent } from "@/components/Modal";
import { useForm } from "@/hooks/useForm";
import { NewColors } from "@/themes/Default";

import { LotCard } from "../../../LotCard";

import { Buttons, GoBack } from "./styles";

// TODO: Pull from actual item, using query/cache
const PLACEHOLDERS = {
  description:
    "Infinite Eels is a collection of 999 pieces of generative abstract art that challenges viewers to see the world through the winding eel forms presented to them.",
  externalLink: "https://opensea.io/assets/ethereum/0x09b1a9dffd64e6dcad",
  title: "Infinite Eel #8792",
};

interface AddLotProps {
  handleGoBack(): void;
}

// TODO: Add parseFloat to save strings as int, see wesbos code

export const AddLot = ({ handleGoBack }: AddLotProps) => {
  const { formState, handleInputChange } = useForm({
    description: "",
    externalLink: "",
    openingPrice: "",
    reservePrice: "",
    title: "",
  });

  const handleSubmit = () => alert("To be implemented...");

  return (
    <ModalContent>
      <LotCard showDetails={false} />

      <Input
        defaultValue={formState.title}
        handleInput={handleInputChange}
        label="Title"
        name="title"
        placeholder={PLACEHOLDERS.title}
        type="text"
      />

      <TextArea
        defaultValue={formState.description}
        description="Presents the NFT’s own description by default."
        handleInput={handleInputChange}
        label="Description"
        name="description"
        placeholder={PLACEHOLDERS.description}
      />

      <Input
        defaultValue={formState.externalLink}
        description="Links out to the NFT’s OpenSea page by default."
        handleInput={handleInputChange}
        label="External link URL"
        name="externalLink"
        placeholder={PLACEHOLDERS.externalLink}
        type="text"
      />

      <InlineInputContainer
        description="Specify where bidding should open and an optional reserve price."
        label="Prices"
      >
        <InlineInput
          defaultValue={formState.openingPrice}
          handleInput={handleInputChange}
          label="Opening price"
          name="openingPrice"
          placeholder="Enter a minimum of 0.01"
          type="text"
        />

        <InlineInput
          defaultValue={formState.reservePrice}
          handleInput={handleInputChange}
          label="Reserve price"
          name="reservePrice"
          placeholder="Optional value above opening price"
          type="text"
        />
      </InlineInputContainer>

      <Buttons>
        <GoBack onClick={handleGoBack}>
          <FontAwesomeIcon color={NewColors.Purple} icon={faArrowLeft} />
          <span>Select a different NFT</span>
        </GoBack>

        <Button onClick={handleSubmit} variant="green">
          Add lot to auction
        </Button>
      </Buttons>
    </ModalContent>
  );
};
