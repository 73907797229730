import styled from "styled-components";

import { Breakpoints } from "../../../../themes/Default";

export const Grid = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr;

  @media (min-width: ${Breakpoints.Mobile}) {
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  }
`;
