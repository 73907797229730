import { faGavel } from "@fortawesome/free-solid-svg-icons";

import { ResponsiveImage } from "@/components/ResponsiveImage";
import { Section } from "@/components/Section";
import { AuctionState, EventEventType } from "@/generated/schema";

import type { Auction } from "../../hooks/useAuction";

import {
  Container,
  Description,
  Event,
  Format,
  Header,
  Icon,
  Time,
  Wrapper,
} from "./styles";

interface Props {
  auction: Auction;
}

export const Details = ({ auction }: Props) => (
  <Section id="details">
    <Container>
      <Wrapper>
        <Time
          event={EventEventType.AUCTION}
          scheduledStartTime={auction?.scheduledStartTime}
          state={auction?.state}
          STATES={AuctionState}
        />

        <ResponsiveImage
          alt={""}
          height={320}
          src={auction.image}
          width={568}
        />
      </Wrapper>

      <Wrapper>
        <Event>
          <Icon icon={faGavel} /> Auction
        </Event>

        <Header>{auction?.name}</Header>

        <Format>About the Event</Format>
        <Description>{auction.description}</Description>
      </Wrapper>
    </Container>
  </Section>
);
