import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { LiveStreamSection } from "@/components/LiveStreamSection";
import { PageBoundary } from "@/components/PageBoundary";
import { EventEventType } from "@/generated/schema";
import { logAmplitudeEvent } from "@/utils/analytics";
import { AUCTIONS_EVENTS } from "@/utils/analytics/events";

import { Details } from "../components/Details";
import { LotsSection } from "../components/LotsSection";
import { useAuction } from "../hooks/useAuction";
import { usePusher } from "../hooks/usePusher";

export const Auction = () => {
  const { slug } = useParams();
  const { auctionAnalytics, auction, error, loading } = useAuction({ slug });

  usePusher(auction?.pusherChannel);

  const logPageViewed = () => {
    logAmplitudeEvent(AUCTIONS_EVENTS.AUCTION.PAGE_VIEWED, auctionAnalytics);
  };

  return (
    <PageBoundary error={error} loading={loading} onLoaded={logPageViewed}>
      <Helmet>
        <title>{auction.name}</title>
      </Helmet>

      <Details auction={auction} />

      <LiveStreamSection
        event={EventEventType.AUCTION}
        eventState={auction?.state}
        scheduledStartTime={auction?.scheduledStartTime}
        twitchChannelName={auction?.twitchChannelName}
        twitchVideoId={auction?.twitchVideoId}
        youtubeVideoId={auction?.youtubeVideoId}
      />

      {!!auction.lotCount && (
        <LotsSection auction={auction} auctionAnalytics={auctionAnalytics} />
      )}
    </PageBoundary>
  );
};
