import type { Error } from "@/generated/schema";

import { useBidExtension } from "../../../../hooks/useBidExtension";

import { AnimatedInfo, ErrorInfo, Info } from "./styles";

interface Props {
  acceptingBids: boolean;
  balance: string;
  balanceTooLow: boolean;
  bidAccepted: boolean;
  bidMessageTimeout: boolean;
  endTime: Date | null;
  errors: Error[];
  minNextBid: string;
}

export const Feedback = ({
  acceptingBids,
  balance,
  balanceTooLow,
  bidAccepted,
  bidMessageTimeout,
  endTime,
  errors,
  minNextBid,
}: Props) => {
  const { timeExtendingBid } = useBidExtension({ acceptingBids, endTime });
  const showBidAccepted = bidAccepted && bidMessageTimeout;
  const showError = !!errors.length && bidMessageTimeout;

  const formattedErrorMessage =
    errors[0] &&
    errors[0].message &&
    errors[0].message.charAt(0).toLowerCase() + errors[0].message.slice(1);

  if (showError) return <ErrorInfo>Sorry, {formattedErrorMessage}.</ErrorInfo>;
  if (balanceTooLow)
    return (
      <ErrorInfo>
        Your wallet balance of {balance} is too low for this bid.
      </ErrorInfo>
    );
  if (showBidAccepted)
    return <AnimatedInfo>Your bid has been accepted!</AnimatedInfo>;
  if (timeExtendingBid) return <AnimatedInfo>⏱ Time extended!</AnimatedInfo>;
  if (!acceptingBids) return <Info>This lot is now closed.</Info>;

  return <Info>Your bid must be at least ({minNextBid}).</Info>;
};
