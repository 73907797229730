import styled from "styled-components";

import { ResponsiveImage } from "@/components/ResponsiveImage";
import { Borders, NewColors } from "@/themes/Default";

export const Container = styled.div`
  border: ${Borders.User};
  border-radius: 1rem;
  box-shadow: 0 8px 16px 0 rgb(74 75 79 / 20%);
  color: ${NewColors.TextShout};
  overflow: hidden;
`;

export const Content = styled.div`
  background: white;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr;
  padding: 1rem;
`;

export const Name = styled.h2`
  color: inherit;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.75rem;
  margin: 0;
  opacity: 0.8;
`;

export const ProjectDetails = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;

export const ProjectLogo = styled.img`
  border: ${Borders.User};
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
`;

export const ProjectName = styled.p`
  color: ${NewColors.TextSpeak};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1rem;
  margin: 0;
  opacity: 0.8;
`;

export const StyledResponsiveImage = styled(ResponsiveImage)`
  background: ${NewColors.Deadfill};
`;
