import { LoadingBoundary } from "@/components/LoadingBoundary";
import { useFetchAccountData } from "@/hooks/useFetchAccountData";

import { ProfileHeader } from "../components/ProfileHeader";
import { ProfileNavigation } from "../components/ProfileNavigation";

export const Me = () => {
  const {
    accountData: { username },
  } = useFetchAccountData();

  return (
    <LoadingBoundary loading={!username}>
      <ProfileHeader walletAddress={username!} />
      <ProfileNavigation walletAddress={username!} />
    </LoadingBoundary>
  );
};
