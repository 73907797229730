import styled from "styled-components";

import { Colors } from "../../../../themes/Default";

export const Grid = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));

  @media (min-width: 67.5rem) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ItemCount = styled.span`
  color: ${Colors.Waikawa};
  line-height: 1.5rem;
`;
