import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as RouterLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { Borders, NewColors } from "@/themes/Default";

import type { Props } from "./Button";

const styling = css`
  background-color: ${NewColors.Glacier};
  border: 1px solid transparent;
  border-radius: 0.5rem;
  color: ${NewColors.Purple};
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  padding: 0.6875rem 1rem 0.8125rem;
  text-align: center;

  :disabled {
    opacity: 0.5;
  }

  :focus,
  :hover {
    background-color: ${NewColors.GlacierDarkened};
    border: 1px solid ${NewColors.Purple};
    box-shadow: 0 2px 0 ${NewColors.Glacier};
    text-decoration: none;
    transform: translateY(-2px);
    transition: 200ms ease-in-out;
  }
`;

const greenStyling = css`
  background-color: ${NewColors.Primary};
  border: 1px solid ${NewColors.Primary};
  color: white;

  :focus,
  :hover {
    background-color: ${NewColors.PrimaryDarkened};
    border-color: ${NewColors.PrimaryDarkened};
    box-shadow: 0 2px 0 ${NewColors.PrimaryShadow};
    color: white;
    transform: translateY(-2px);
    transition: 200ms ease-in-out;
  }
`;

const lightRedStyling = css`
  background-color: rgb(252 239 239);
  border: 1px solid rgb(252 239 239);
  color: ${NewColors.Negative};

  :focus,
  :hover {
    background-color: ${NewColors.NegativeDarkened};
    border-color: ${NewColors.NegativeDarkened};
    box-shadow: 0 2px 0 ${NewColors.NegativeShadow};
    color: white;
    transform: translateY(-2px);
    transition: 200ms ease-in-out;
  }
`;

const redStyling = css`
  background-color: ${NewColors.Negative};
  border: 1px solid ${NewColors.Negative};
  color: white;

  :focus,
  :hover {
    background-color: ${NewColors.NegativeDarkened};
    border-color: ${NewColors.NegativeDarkened};
    box-shadow: 0 2px 0 ${NewColors.NegativeShadow};
    color: white;
    transform: translateY(-2px);
    transition: 200ms ease-in-out;
  }
`;

const purpleStyling = css`
  background-color: ${NewColors.Secondary};
  border: 1px solid ${NewColors.Secondary};
  color: white;

  :focus,
  :hover {
    background-color: ${NewColors.SecondaryDarkened};
    border-color: ${NewColors.SecondaryDarkened};
    box-shadow: 0 2px 0 ${NewColors.SecondaryShadow};
    color: white;
    transform: translateY(-2px);
    transition: 200ms ease-in-out;
  }
`;

const whiteStyling = css`
  background-color: white;
  border: ${Borders.Nft};
  color: ${NewColors.Secondary};

  :focus,
  :hover {
    background-color: ${NewColors.SecondaryOverlay};
    border-color: ${NewColors.Secondary};
    box-shadow: 0 2px 0 ${NewColors.SecondaryShadow};
    transform: translateY(-2px);
    transition: 200ms ease-in-out;
  }
`;

export const Anchor = styled.a<Props>`
  ${styling}

  ${({ variant }) =>
    (variant === "full" || variant === "purple") && purpleStyling}
  ${({ variant }) => variant === "green" && greenStyling}
  ${({ variant }) => variant === "light-red" && lightRedStyling}
  ${({ variant }) => variant === "red" && redStyling}
  ${({ variant }) => variant === "white" && whiteStyling}

  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
`;

export const Btn = styled.button<Props>`
  ${styling}

  ${({ variant }) =>
    (variant === "full" || variant === "purple") && purpleStyling}
  ${({ variant }) => variant === "green" && greenStyling}
  ${({ variant }) => variant === "light-red" && lightRedStyling}
  ${({ variant }) => variant === "red" && redStyling}
  ${({ variant }) => variant === "white" && whiteStyling}

  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
`;

export const IconAnchor = styled(Anchor)`
  display: inline-grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(2, auto);
  max-width: fit-content;
  place-items: center;
`;

export const Icon = styled(FontAwesomeIcon)`
  height: 1.25rem;
  width: 1.25rem;
`;

export const Link = styled(RouterLink)<Props>`
  ${styling}

  ${({ variant }) =>
    (variant === "full" || variant === "purple") && purpleStyling}
  ${({ variant }) => variant === "green" && greenStyling}
  ${({ variant }) => variant === "light-red" && lightRedStyling}
  ${({ variant }) => variant === "red" && redStyling}
  ${({ variant }) => variant === "white" && whiteStyling}

  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
`;

export const IconLink = styled(Link)`
  display: inline-grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(2, auto);
  max-width: fit-content;
  place-items: center;
`;
