import { useEffect, useRef, useState } from "react";
import type { ChangeEvent, KeyboardEvent } from "react";

import { LoadingSpinner } from "@/components/LoadingSpinner";
import { NewColors } from "@/themes/Default";

import {
  Container,
  Copy,
  Error,
  InputWrapper,
  Label,
  Success,
  Uploading,
} from "../../styles";

import { Image, ImageContainer, ImageCopy } from "./styles";

export interface ImageChangeEvent {
  image: File;
  onComplete(error: boolean): void;
}

interface Props {
  description?: string;
  disabled?: boolean;
  errorMessage?: string;
  img: {
    height: number;
    maxWidth?: number;
    src?: string;
    width: number;
  };
  label: string;
  name: string;
  onChange({ image, onComplete }: ImageChangeEvent): void;
  successMessage?: string;
}

export const ImageSelect = ({
  description,
  disabled,
  errorMessage = "Could not upload image",
  img,
  label,
  name,
  onChange,
  successMessage = "Image has been uploaded!",
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [uploading, setUploading] = useState(false);

  const showUploadText = !img.src && !uploading && !disabled;

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => setSuccess(false), 2500);
      return () => clearTimeout(timer);
    }
  }, [success, setSuccess]);

  const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files && e.target.files[0];
    const { valid } = e.target.validity;

    setError(!valid);

    if (valid && image) {
      setUploading(true);

      onChange({
        image,
        onComplete: (error) => {
          setError(error);
          setSuccess(!error);
          setUploading(false);

          e.target.value = "";
        },
      });
    }
  };

  const onKeyDown = ({ key }: KeyboardEvent) => {
    if (key === "Enter" && !disabled) inputRef?.current?.click();
  };

  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>

      {description && <Copy>{description}</Copy>}

      <InputWrapper>
        <ImageContainer
          disabled={disabled}
          maxWidth={img.maxWidth}
          onClick={() => {
            if (!disabled) inputRef?.current?.click();
          }}
          onKeyDown={onKeyDown}
          tabIndex={disabled ? undefined : 0}
        >
          <Image alt="" height={img.height} src={img.src} width={img.width} />

          {showUploadText && <ImageCopy>Upload</ImageCopy>}

          {uploading && (
            <Uploading>
              <LoadingSpinner color={NewColors.Purple} size={3} />
            </Uploading>
          )}
        </ImageContainer>

        <input
          accept="image/*"
          hidden={true}
          ref={inputRef}
          onChange={onImageChange}
          type="file"
        />

        {error && <Error>⚠️ {errorMessage}</Error>}
        {success && <Success>✅ {successMessage}</Success>}
      </InputWrapper>
    </Container>
  );
};
