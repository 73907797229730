import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { PageBanner } from "@/components/PageBanner";
import { AuctionCarousel, LotCarousel } from "@/features/nftAuctions";
import { AuctionState, LotState } from "@/generated/schema";
import { logAmplitudeEvent } from "@/utils/analytics";
import { EVENTS_EVENTS } from "@/utils/analytics/events";

import { CurrentlyLive } from "../components/CurrentlyLive";
import { useHasActiveEvents } from "../hooks/useHasActiveEvents";

export const Home = () => {
  const [analyticsLogged, setAnalyticsLogged] = useState(false);
  const { hasLiveEvents, hasPastAuctions } = useHasActiveEvents();

  useEffect(() => {
    if (!analyticsLogged) {
      setAnalyticsLogged(true);
      logAmplitudeEvent(EVENTS_EVENTS.HOME.PAGE_VIEWED);
    }
  }, [analyticsLogged]);

  return (
    <>
      <Helmet>
        <title>Events</title>
      </Helmet>

      <PageBanner
        description="The curated live auction experience for your favorite NFT projects, art, and personalities."
        heading="Events"
        link={{
          label: "Learn more",
          url: "/faq",
        }}
      />

      {hasLiveEvents && <CurrentlyLive />}

      <LotCarousel
        description=""
        options={{
          variables: {
            orderBy: "scheduled_start_time_asc,id",
            state: [LotState.BIDDING],
          },
        }}
        title="✨ Featured lots"
      />

      <AuctionCarousel
        description="Check out our live and upcoming auction events featuring the hottest NFT projects!"
        options={{
          variables: {
            orderBy: "scheduled_start_time_asc,id",
            state: [AuctionState.LIVE, AuctionState.PUBLISHED],
          },
        }}
        title="⚡️ Active auctions"
      />

      {hasPastAuctions && (
        <AuctionCarousel
          description="Look over the highlights of our previous auctions, and review past live shows."
          link="./past/auctions"
          options={{
            variables: {
              orderBy: "scheduled_start_time_desc,id",
              state: [AuctionState.CANCELED, AuctionState.ENDED],
            },
          }}
          title="👀 Past auctions"
        />
      )}
    </>
  );
};
