import type { ReactNode } from "react";

import { Colors } from "../../themes/Default";
import { LoadingSpinner } from "../LoadingSpinner";

import { Loading, Status } from "./styles";

interface Props {
  children: ReactNode;
  error?: boolean;
  loading?: boolean;
  loadingColor?: string;
}

export const LoadingBoundary = ({
  children,
  error,
  loading,
  loadingColor = Colors.Waikawa,
}: Props) => {
  if (error) return <Status>There was an issue.</Status>;
  if (loading)
    return (
      <Loading>
        <LoadingSpinner color={loadingColor} size={4} />
        <Status>Loading...</Status>
      </Loading>
    );

  return <>{children}</>;
};
