import styled, { css } from "styled-components";

import { IconAnchorButton } from "@/components/Button";
import { ResponsiveImage } from "@/components/ResponsiveImage";
import { Breakpoints } from "@/themes/Default";

export const ExternalLink = styled(IconAnchorButton)`
  align-self: end;
`;

export const Heading = styled.h3`
  grid-area: heading;
  margin: 0;
`;

export const Image = styled(ResponsiveImage)`
  border-radius: 0.5rem;
  overflow: hidden;
`;

export const ImageContainer = styled.div`
  border-radius: 0.5rem;
  grid-area: image;
  justify-self: center;
  max-width: 12rem;
  width: 100%;
`;

export const NftName = styled.span`
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  margin-top: 0.25rem;
`;

export const ProjectName = styled.span`
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const Section = styled.section<{ hasImage: boolean }>`
  padding: 1.25rem;
  position: relative;

  @media {
    ${({ hasImage }) =>
      hasImage &&
      css`
        display: grid;
        grid-gap: 1rem 1.5rem;
        grid-column-gap: 2rem;
        grid-template-columns: 13rem auto;

        @media (min-width: ${Breakpoints.Mobile}) {
          grid-template: auto 1fr / 11.23rem 1fr;
          grid-template-areas:
            "image heading"
            "image children";
        }
      `}
  }
`;

export const PropertyName = styled.div`
  font-size: 1rem;
  text-align: center;
`;

export const Properties = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
`;

export const PropertyValue = styled.div`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
`;

export const Value = styled.div`
  background-color: #fafbfe;
  border: 0.031rem solid #c9d8f1;
  border-radius: 0.5rem;
  display: grid;
  gap: 0.5rem;
  padding: 0.75rem;
`;
