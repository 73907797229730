import styled, { css } from "styled-components";

import { Breakpoints } from "../../../../themes/Default";

export const Container = styled.div<{ insideModal?: boolean }>`
  display: grid;
  grid-row-gap: 1rem;
  grid-template-columns: 1fr 1fr;

  @media (min-width: ${Breakpoints.Large}) {
    grid-template-columns: 1fr 1fr;
  }

  ${({ insideModal }) =>
    !insideModal &&
    css`
      @media (min-width: ${Breakpoints.Mobile}) {
        grid-template-columns: 2fr 1fr 1fr;
      }

      @media (min-width: ${Breakpoints.Large}) {
        grid-template-columns: 1fr 1fr;
      }
    `}
`;

export const Entry = styled.span`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin: 0;

  @media (min-width: ${Breakpoints.Mobile}) {
    font-size: 1.125rem;
  }
`;

export const Label = styled.span`
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const Status = styled.p<{ full?: boolean; insideModal?: boolean }>`
  display: grid;
  grid-row-gap: 0.125rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  margin: 0;

  @media (min-width: ${Breakpoints.Mobile}) {
    grid-row-gap: 0.25rem;
  }

  ${({ full }) =>
    full &&
    css`
      grid-column: 1/-1;

      @media (min-width: ${Breakpoints.Large}) {
        grid-column: 1/-1;
      }
    `}

  ${({ full, insideModal }) =>
    full &&
    !insideModal &&
    css`
      @media (min-width: ${Breakpoints.Mobile}) {
        grid-column: initial;
      }

      @media (min-width: ${Breakpoints.Large}) {
        grid-column: 1/-1;
      }
    `}
`;
