import { useMutation } from "@apollo/client";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { captureException } from "@sentry/react";
import { useState } from "react";

import { Button } from "@/components/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { useUpdateCache } from "@/features/nftAuctions";
import { useApproveTransfer } from "@/features/smartContract";
import {
  LotNftApprovalState,
  UpdateLotNftApprovalStateToApproving,
} from "@/generated/schema";

import { UPDATE_LOT_NFT_APPROVAL_STATE_TO_APPROVING } from "../../../../api/UPDATE_LOT_NFT_APPROVAL_STATE_TO_APPROVING";
import { useLot } from "../../../../hooks/useLot";
import { LotStatus } from "../../../LotStatus";

import {
  Approve,
  Buttons,
  Container,
  Emoji,
  Heading,
  Icon,
  Info,
  InfoCopy,
  InfoHeading,
  Won,
  WonContainer,
} from "./styles";

interface Props {
  id: string;
  showClaimItem(): void;
  showDetails(): void;
  showPlaceBid(): void;
}

interface MutationRequest {
  updateLotNftApprovalStateToApproving: UpdateLotNftApprovalStateToApproving;
}

export const BiddingPanel = ({
  id,
  showClaimItem,
  showDetails,
  showPlaceBid,
}: Props) => {
  const { lot } = useLot(id);
  const { refetchLot } = useUpdateCache();
  const [submitting, setSubmitting] = useState(false);

  const { fetch } = useApproveTransfer({
    tokenType: lot.nftItem?.tokenType,
    collectionAddress: lot.nftItem?.tokenAddress,
    tokenId: lot.nftItem?.tokenId,
  });

  const [updateLotNftApprovalStateToApproving] = useMutation<MutationRequest>(
    UPDATE_LOT_NFT_APPROVAL_STATE_TO_APPROVING,
    {
      variables: { lotId: lot.id },
      onCompleted({ updateLotNftApprovalStateToApproving: { errors } }) {
        if (errors && errors.length > 0) {
          setSubmitting(false);
        } else {
          refetchLot();
        }
      },
    },
  );

  const nftWaitingForApproval =
    lot.nftApprovalState === LotNftApprovalState.WAITING_FOR_APPROVAL;
  const nftApproving = lot.nftApprovalState === LotNftApprovalState.APPROVING;
  const nftApproved = lot.nftApprovalState === LotNftApprovalState.APPROVED;
  const nftApprovedSaleNotCompleted = nftApproved && !lot.hasSaleCompleted;
  const nftApprovedSaleCompleted = nftApproved && lot.hasSaleCompleted;

  const biddingHeadingLabel = lot.isUpcoming
    ? "Not yet open for bidding"
    : "Bidding open";

  const approveNft = () => {
    if (!nftWaitingForApproval) return;

    setSubmitting(true);

    fetch({
      onError: (err) => {
        setSubmitting(false);
        captureException(err, (scope) => {
          scope.setTransactionName("BiddingPanel - approveNft - onError");
          return scope;
        });
      },
      onSuccess: () => updateLotNftApprovalStateToApproving(),
    });
  };

  if (lot.userOwnsNft) {
    return (
      <Container standard>
        <Heading>{biddingHeadingLabel}</Heading>

        <Info success={nftApproved}>
          <InfoHeading>
            {nftWaitingForApproval && "Your approval is required"}
            {nftApproving && "Processing approval…"}
            {nftApprovedSaleNotCompleted && "Your NFT is approved for sale"}
            {nftApprovedSaleCompleted && "Congratulations!"}
          </InfoHeading>
          <InfoCopy>
            {nftWaitingForApproval &&
              "To complete the auction, we need your permission to transfer the NFT to the winning bidder. It will not sell below your agreed reserve price."}
            {nftApproving &&
              "This is an on-chain transaction and may take a minute or two to complete."}
            {nftApprovedSaleNotCompleted &&
              "Good luck with the auction! Remember, your item will not sell for less than the agreed reserve price."}
            {nftApprovedSaleCompleted &&
              "You sold your NFT to a proud new owner."}
          </InfoCopy>
        </Info>

        <LotStatus id={id} />

        <Buttons row>
          <Button $fullWidth onClick={showDetails} variant="white">
            View details
          </Button>

          <Button
            $fullWidth
            disabled={!nftWaitingForApproval || submitting}
            onClick={approveNft}
            variant="green"
          >
            {nftWaitingForApproval && "Approve for auction"}
            {nftApproving && (
              <Approve>
                <span>Approving…</span>{" "}
                <LoadingSpinner color={"#fff"} size={1} />
              </Approve>
            )}
            {nftApprovedSaleNotCompleted && (
              <Approve>
                <span>Approved</span> <Icon icon={faCheck} />
              </Approve>
            )}
            {nftApprovedSaleCompleted && (
              <Approve>
                <span>Sold</span> <Icon icon={faCheck} />
              </Approve>
            )}
          </Button>
        </Buttons>
      </Container>
    );
  }

  if (lot.hasUserWon) {
    return (
      <Container>
        <WonContainer>
          <Won>
            <Emoji background={lot.isCompletedSale ? "#c4face" : "#E3EAF8"}>
              {lot.isAwaitingNftApproval && "⏳"}
              {lot.isProcessingNftApproval && <LoadingSpinner size={2} />}
              {lot.isAwaitingPayment && "🙌"}
              {lot.isCompletingSale && <LoadingSpinner size={2} />}
              {lot.isCompletedSale && "✅"}
            </Emoji>
            <InfoHeading>
              {lot.isAwaitingNftApproval && "Congratulations, you won!"}
              {lot.isProcessingNftApproval &&
                "Preparing your new NFT for transfer…"}
              {lot.isAwaitingPayment && "Your new NFT is ready for you!"}
              {lot.isCompletingSale && "Processing payment…"}
              {lot.isCompletedSale && "Purchase complete"}
            </InfoHeading>
            <InfoCopy>
              {lot.isAwaitingNftApproval &&
                "Awaiting seller transferring us this NFT. Please check back a little later, or contact support for details."}
              {lot.isProcessingNftApproval &&
                "This is an on-chain transaction and may take a minute or two to complete."}
              {lot.isAwaitingPayment &&
                "Hit the 'claim item' button below to complete your purchase."}
              {lot.isCompletingSale &&
                "This is an on-chain transaction and may take a minute or two to complete."}
              {lot.isCompletedSale &&
                "Your new NFT has been transferred to your wallet. Enjoy!"}
            </InfoCopy>
          </Won>
        </WonContainer>

        <Buttons row>
          <Button $fullWidth onClick={showDetails} variant="white">
            View details
          </Button>

          {lot.isAwaitingPayment && (
            <Button $fullWidth onClick={showClaimItem} variant="purple">
              Claim item
            </Button>
          )}
        </Buttons>
      </Container>
    );
  }

  if (lot.hasEnded) {
    return (
      <Container>
        <Heading>Bidding results</Heading>

        <Info>
          <InfoHeading>Bidding has ended</InfoHeading>
          <InfoCopy>
            Going, going… gone! Bidding has now ended on this auction lot.
          </InfoCopy>
        </Info>

        {lot.hasSold && <LotStatus id={id} />}

        <Buttons>
          <Button $fullWidth onClick={showDetails} variant="white">
            View details
          </Button>
        </Buttons>
      </Container>
    );
  }

  return (
    <Container standard>
      <Heading>{biddingHeadingLabel}</Heading>

      <LotStatus id={id} />

      <Buttons>
        <Button $fullWidth onClick={showDetails} variant="white">
          View details
        </Button>

        {lot.isAcceptingBids && (
          <Button $fullWidth onClick={showPlaceBid} variant="purple">
            Place bid
          </Button>
        )}
      </Buttons>
    </Container>
  );
};
