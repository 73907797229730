import { useApolloClient, useMutation } from "@apollo/client";
import { Moralis } from "moralis";
import { useState } from "react";

import { DELETE_COOKIES } from "@/api/mutations/DELETE_COOKIES";
import { WALLET_AUTH } from "@/api/mutations/WALLET_AUTH";
import { IS_LOGGED_IN } from "@/api/queries/IS_LOGGED_IN";
import { getAuthDataByChain } from "@/features/wallet";
import { CryptoCurrencies } from "@/generated/schema";
import { isLoggedInVar } from "@/utils/apolloConfig";
import { setAccessTokenExpiry, setRefreshTokenExpiry } from "@/utils/auth";

interface Props {
  cryptoWalletId: string;
  moralisUserId: string | undefined;
  logOutUser: boolean;
  chain: CryptoCurrencies;
  user: Moralis.User<Moralis.Attributes> | null;
}

export const useWalletAuthentification = ({
  cryptoWalletId,
  moralisUserId,
  logOutUser,
  chain,
  user,
}: Props) => {
  const { signature, token } = getAuthDataByChain(chain, user);

  const client = useApolloClient();
  const [errorMessage, setErrorMessage] = useState("");

  const variables = {
    cryptoWalletId,
    moralisUserId,
    logOutUser,
    signature,
    moralisToken: token,
  };

  const [deleteCookies] = useMutation(DELETE_COOKIES, {
    onCompleted() {
      localStorage.clear();

      client.clearStore().then(() => isLoggedInVar(false));
      client.refetchQueries({ include: [IS_LOGGED_IN] });
    },
  });

  const [login, { error: loginError }] = useMutation(WALLET_AUTH, {
    variables,

    onCompleted({ walletAuth }) {
      setAccessTokenExpiry(walletAuth.exp);
      setRefreshTokenExpiry(walletAuth.refreshExpiresIn);

      client.clearStore().then(() => isLoggedInVar(true));
      client.refetchQueries({ include: [IS_LOGGED_IN] });
    },

    onError() {
      setErrorMessage(loginError?.message!);
    },
  });

  const [logout, { error: logoutError }] = useMutation(WALLET_AUTH, {
    variables: { ...variables, logOutUser: true },

    onCompleted() {
      deleteCookies();
    },

    onError() {
      setErrorMessage(logoutError?.message!);
    },
  });

  return {
    deleteCookies,
    login,
    logout,
    error: errorMessage,
  };
};
