import { useQuery } from "@apollo/client";
import type { OperationVariables } from "@apollo/client";

import type { LotNodeConnection } from "@/generated/schema";

import { GET_LOTS } from "../api/GET_LOTS";

interface QueryResponse {
  lots: LotNodeConnection;
}

export const useLots = (options?: OperationVariables) => {
  const { data, error, loading } = useQuery<QueryResponse>(GET_LOTS, {
    ...options,
    notifyOnNetworkStatusChange: true,
  });

  const lots = data?.lots.edges ?? [];

  return {
    lots,
    error: !!error,
    loading,
  };
};
