import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Children, useMemo } from "react";
import type { ReactNode } from "react";

import { useCarousel } from "../../../../components/EmblaCarousel";

import { ProgressBar, ViewMore } from "./components";
import {
  ButtonIcon,
  Container,
  Embla,
  Slide,
  StyledNextButton,
  StyledPrevButton,
  Viewport,
} from "./styles";

interface Props {
  className?: string;
  children: ReactNode;
  featured?: boolean;
  link?: string;
}

export const Carousel = ({
  className,
  children,
  featured = false,
  link,
}: Props) => {
  const {
    emblaRef,
    nextBtnEnabled,
    scrollNext,
    prevBtnEnabled,
    scrollPrev,
    scrollProgress,
  } = useCarousel({
    align: "center",
    containScroll: "trimSnaps",
    speed: 15,
  });

  const wrappedChildren = useMemo(
    () =>
      Children.toArray(children).map((child, idx) => (
        <Slide key={idx} featured={featured}>
          {child}
        </Slide>
      )),
    [children, featured],
  );

  const showProgressBar = Children.toArray(children).length > 2;
  const showNextBtn = nextBtnEnabled && !!wrappedChildren;
  const showPrevBtn = prevBtnEnabled && !!wrappedChildren;

  return (
    <Embla className={className}>
      <Viewport
        hasPrev={prevBtnEnabled}
        hasNext={nextBtnEnabled}
        ref={emblaRef}
      >
        <Container>
          {wrappedChildren}
          {link && <ViewMore link={link} />}
        </Container>
      </Viewport>

      {showPrevBtn && (
        <StyledPrevButton enabled={prevBtnEnabled} onClick={scrollPrev}>
          <ButtonIcon icon={faAngleLeft} />
        </StyledPrevButton>
      )}

      {showNextBtn && (
        <StyledNextButton enabled={nextBtnEnabled} onClick={scrollNext}>
          <ButtonIcon icon={faAngleRight} />
        </StyledNextButton>
      )}

      {showProgressBar && <ProgressBar progress={scrollProgress} />}
    </Embla>
  );
};
