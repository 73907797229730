import { debounce } from "lodash";
import Pusher from "pusher-js";
import { useEffect, useState } from "react";

import { BidCreatedResponse } from "../types";

import { useUpdateCache } from "./useUpdateCache";

export const usePusher = (channelName: string | null | undefined) => {
  const [notConnected, setNotConnected] = useState(false);
  const { addBid, refetchAuction, refetchLot } = useUpdateCache();

  useEffect(() => {
    const { REACT_APP_PUSHER_APP_KEY, REACT_APP_PUSHER_CLUSTER } = process.env;
    const isMissingPusher =
      !REACT_APP_PUSHER_APP_KEY || !REACT_APP_PUSHER_CLUSTER || !channelName;

    if (isMissingPusher) return setNotConnected(true);

    const pusher = new Pusher(REACT_APP_PUSHER_APP_KEY, {
      cluster: REACT_APP_PUSHER_CLUSTER,
    });

    const refetch = () => {
      refetchAuction();
      refetchLot();
    };

    const channel = pusher.subscribe(channelName);
    const debouncedRefetch = debounce(refetch, 200, { trailing: true });

    channel.bind("pusher:subscription_succeeded", () => refetch());
    channel.bind("auction_refetch", () => debouncedRefetch());
    channel.bind("bid_created", (data: BidCreatedResponse) => addBid(data));

    setNotConnected(false);

    return function cleanup() {
      channel.unbind();
      pusher.unsubscribe(channelName);
      pusher.disconnect();
    };
  }, [addBid, channelName, refetchAuction, refetchLot]);

  return { notConnected };
};
