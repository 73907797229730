import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { Button } from "@/components/Button";
import { InfoNotice } from "@/components/InfoNotice";
import { ConnectWalletModal } from "@/features/wallet";
import { useIsLoggedIn } from "@/hooks/useIsLoggedIn";
import { Borders } from "@/themes/Default";

import { DiscordConnect } from "../components/DiscordConnect";
import { DiscordLogos } from "../components/DiscordLogos";

const Container = styled.div`
  background-color: white;
  border: ${Borders.Live};
  border-radius: 0.5rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin-top: 3rem;
  max-width: 35rem;
  padding: 1rem;
  place-content: center;
  text-align: center;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  place-content: center;
  width: 100%;
`;

export const Discord = () => {
  const { isLoggedIn } = useIsLoggedIn();
  const { token } = useParams();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isLoggedIn) setShow(false);
  }, [isLoggedIn]);

  return (
    <Wrapper>
      <ConnectWalletModal show={show} setShow={setShow} />

      {isLoggedIn ? (
        <DiscordConnect token={token ?? ""} />
      ) : (
        <Container>
          <DiscordLogos />
          <InfoNotice
            icon={faWallet}
            message={[
              "Please connect the wallet you'd like to associate with your Discord user. You will then be able to place bids as this wallet from inside Discord.",
            ]}
            state="neutral"
            title="Wallet connection required"
          />

          <div>
            <Button variant="purple" onClick={() => setShow(true)}>
              Connect wallet to Discord user
            </Button>
          </div>
        </Container>
      )}
    </Wrapper>
  );
};
