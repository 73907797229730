import {
  ChatContainer,
  ChatWrapper,
  Container,
  Embed,
  VideoContainer,
  VideoWrapper,
} from "./styles";

interface Props {
  autoplay?: boolean;
  mute?: boolean;
  showChat?: boolean;
  videoId: string;
}

export const YouTubeEmbed = ({
  autoplay = false,
  mute = false,
  showChat = true,
  videoId,
}: Props) => {
  const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=${+autoplay}&mute=${+mute}`;
  const embed_domain = window.location.hostname;
  const chatUrl = `https://www.youtube.com/live_chat?v=${videoId}&embed_domain=${embed_domain}`;

  return (
    <Container>
      <VideoContainer>
        <VideoWrapper>
          <Embed
            width="100%"
            height="100%"
            frameBorder="1"
            title="YouTube video player"
            src={videoUrl}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowFullScreen={true}
          />
        </VideoWrapper>
      </VideoContainer>

      {showChat && (
        <ChatContainer>
          <ChatWrapper>
            <Embed
              width="100%"
              height="100%"
              frameBorder="1"
              title="YouTube chat player"
              src={chatUrl}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowFullScreen={true}
            />
          </ChatWrapper>
        </ChatContainer>
      )}
    </Container>
  );
};
