import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { Container, Icon, IconWrapper } from "./styles";

interface Props {
  className?: string;
  label?: string;
  link?: string;
}

export const ViewMore = ({
  className,
  label = "View all",
  link = "#",
}: Props) => (
  <Container className={className} to={link}>
    <IconWrapper>
      <Icon icon={faArrowRight} />
    </IconWrapper>

    {label}
  </Container>
);
