import { gql } from "@apollo/client";

export const CREATE_BID = gql`
  mutation createBid($lotId: ID!, $bidPrice: Float!, $bidCurrency: String) {
    bidCreate(lotId: $lotId, bidPrice: $bidPrice, bidCurrency: $bidCurrency) {
      errors {
        field
        message
      }

      bid {
        id
        bidPrice
        bidPriceCurrency
        createdAt
        state
        user {
          id
          username
        }
      }

      lot {
        id
        biddingEndsAfter
        bidPriceIncrement
        currency
        highestBidPrice
        minimumNextBidPrice
        name
        order
      }
    }
  }
`;
