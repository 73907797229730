import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import type { DropResult } from "react-beautiful-dnd";

import { LotCard } from "../../../LotCard";
import { LotCardWrapper } from "../LotCardWrapper";

const INITIAL_LOTS = [
  {
    id: "1",
    name: "Item 1",
  },
  {
    id: "2",
    name: "Item 2",
  },
  {
    id: "3",
    name: "Item 3",
  },
];

// TODO: Rename and refactor

export const List = () => {
  const [lots, setLots] = useState(INITIAL_LOTS);

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const tempLots = Array.from(lots);
    const [reorderedLot] = tempLots.splice(result.source.index, 1);
    tempLots.splice(result.destination?.index!, 0, reorderedLot);

    setLots(tempLots);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="lots">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ marginTop: "-1rem" }}
          >
            {lots.map((lot, idx) => (
              <Draggable key={lot.id} draggableId={lot.id} index={idx}>
                {(provided) => (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    style={{
                      ...provided.draggableProps.style,
                      cursor: "default",
                      marginTop: "1rem",
                    }}
                  >
                    <LotCardWrapper>
                      <LotCard border={false} name={lot.name} />
                    </LotCardWrapper>
                  </div>
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
