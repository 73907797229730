import type { ReactNode } from "react";

import { Container, Content } from "./styles";

interface Props {
  children: ReactNode;
  className?: string;
  full?: boolean;
  id?: string;
}

export const Section = ({ children, className, full = false, id }: Props) => (
  <Container className={className} full={full} id={id}>
    <Content>{children}</Content>
  </Container>
);
