import { formatWalletAddress } from "@/utils/formatWalletAddress";

import {
  Avatar,
  AvatarContainer,
  Banner,
  Below,
  Container,
  Content,
  ContentContainer,
  Username,
  WalletAddress,
  WalletContainer,
} from "./styles";

interface Props {
  avatar?: string;
  background?: string;
  username?: string;
  walletAddress: string;
}

export const ProfileHeader = ({
  avatar,
  background,
  username,
  walletAddress,
}: Props) => {
  const formattedWalletAddress = formatWalletAddress({
    address: walletAddress,
  });

  return (
    <Container>
      <Banner background={background}>
        <Content>
          <ContentContainer>
            <AvatarContainer>
              {avatar && <Avatar alt="" src={avatar} />}
            </AvatarContainer>

            <Username>{username ? username : formattedWalletAddress}</Username>
          </ContentContainer>
        </Content>
      </Banner>

      <Below>
        <WalletContainer>
          <WalletAddress>{walletAddress}</WalletAddress>
        </WalletContainer>
      </Below>
    </Container>
  );
};
