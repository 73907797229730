import { Bar, Container } from "./styles";

interface Props {
  className?: string;
  progress: number;
}

export const ProgressBar = ({ className, progress }: Props) => (
  <Container className={className}>
    <Bar style={{ transform: `translateX(${progress}%)` }} />
  </Container>
);
