import { Link } from "react-router-dom";

import { LoadingBoundary } from "@/components/LoadingBoundary";

import { useLot } from "../../hooks/useLot";

import { PaymentDetails } from "./components/PaymentDetails";
import {
  Collection,
  Copy,
  Heading,
  Image,
  Info,
  LotBanner,
  Name,
} from "./styles";

interface Props {
  id: string;
}

export const LotPayment = ({ id }: Props) => {
  const { error, loading, lot } = useLot(id);
  const userDidntWin = !lot.isUserWinning;

  return (
    <LoadingBoundary error={error || userDidntWin} loading={loading}>
      <LotBanner>
        {lot.image && <Image src={lot.image} alt="" />}

        <Heading>
          <Collection>{lot.collection}</Collection>
          <Name>{lot.name}</Name>
        </Heading>
      </LotBanner>

      <PaymentDetails
        buyersPremiumFee={lot.buyersPremiumFee!}
        buyersPremiumPercentage={lot.buyersPremiumPercentage!}
        finalPurchasePrice={lot.finalPurchasePrice!}
        lotId={lot.id}
        smartContractLotId={lot.smartContractLotId}
        hasSaleCompleted={lot.hasSaleCompleted}
        purchasePrice={lot.highestBid.bidPrice!}
      />

      <Info>
        <Copy>
          Check out our <Link to="/faq">FAQ</Link> or{" "}
          <Link to="/support">contact support</Link> with any questions.
        </Copy>
      </Info>
    </LoadingBoundary>
  );
};
