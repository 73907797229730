import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  Breakpoints,
  Colors,
  CONTENT_WIDTH,
  NewColors,
} from "../../themes/Default";
import { Logo } from "../Logo";

export const Container = styled.div`
  background: #f9fbff;
  border-top: 1px solid ${Colors.Glacier};
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
`;

export const Controls = styled.div`
  position: absolute;
  right: 0;

  @media (min-width: ${Breakpoints.Mobile}) {
    display: flex;
    place-items: center;
    position: relative;
  }
`;

export const Menu = styled.div`
  border-left: 1px solid #d4dff2;
  padding-left: 1rem;
`;

export const NavItem = styled.div`
  display: none;

  @media (min-width: ${Breakpoints.Mobile}) {
    display: block;
    padding-right: 1rem;
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  color: ${NewColors.Purple};
  height: 1.25rem;
  padding-right: 0.5rem;
  width: 1.25rem;
`;

export const StyledLink = styled(Link)`
  color: ${NewColors.TextSpeak};
  display: flex;
  font-weight: bold;
  line-height: 1.25rem;
  padding: 0.5rem;
  place-items: center;
`;

export const StyledLogo = styled(Logo)`
  height: 2.5rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    height: 2.5rem;
  }
`;

export const TopBar = styled.div`
  background: white;
  border-bottom: 1px solid ${Colors.Glacier};
  display: grid;
  padding: 1rem 1.5rem;
  place-items: center;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 1rem 3rem;
  }
`;

export const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: ${CONTENT_WIDTH};
  position: relative;
  width: 100%;

  @media (min-width: ${Breakpoints.Mobile}) {
    justify-content: space-between;
  }
`;
