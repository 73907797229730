import { Route, Routes } from "react-router-dom";

import { MinimumLayout } from "../../../components/MinimumLayout";

import { Home } from "./Home";
import { Past } from "./Past";

export const EventsRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={
        <MinimumLayout>
          <Home />
        </MinimumLayout>
      }
    />

    <Route
      path="/past"
      element={
        <MinimumLayout>
          <Past />
        </MinimumLayout>
      }
    />

    <Route
      path="/past/:event"
      element={
        <MinimumLayout>
          <Past />
        </MinimumLayout>
      }
    />
  </Routes>
);
