import { useState } from "react";
import type { ChangeEvent, ReactNode } from "react";

import { Container, Copy, Label } from "../../styles";

import { Select as StyledSelect, SelectWrapper } from "./styles";

interface Props {
  children: ReactNode;
  defaultValue?: string;
  description?: string;
  handleChange(e: ChangeEvent<HTMLSelectElement>): void;
  label: string;
  name: string;
}

export const Select = ({
  children,
  defaultValue,
  description,
  handleChange,
  label,
  name,
}: Props) => {
  const [noInitialValue] = useState(!defaultValue);
  const [optionSelected, setOptionSelected] = useState(!!defaultValue);

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setOptionSelected(!!e.currentTarget.value);
    handleChange(e);
  };

  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>

      {description && <Copy>{description}</Copy>}

      <SelectWrapper>
        <StyledSelect
          defaultValue={defaultValue}
          id={name}
          name={name}
          optionSelected={optionSelected}
          onChange={handleSelectChange}
        >
          {noInitialValue && (
            <option disabled value="">
              Not set
            </option>
          )}

          {children}
        </StyledSelect>
      </SelectWrapper>
    </Container>
  );
};
