import { Link } from "react-router-dom";
import styled from "styled-components";

import { Borders, Breakpoints, NewColors } from "@/themes/Default";

export const Content = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 0.75rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 1rem;
  }
`;

export const Container = styled.div`
  background: white;
  border: ${Borders.Nft};
  border-radius: 0.5rem;
  box-shadow: 0 0 4px 0 rgb(5 21 52 / 8%);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: all 200ms ease-in-out;

  &:hover,
  &:focus,
  &:active {
    border-color: #6086ce;
    outline: 0;
    transform: translateY(-2px);
  }
`;

export const Detail = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
`;

export const DetailHeading = styled.span`
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const DetailValue = styled.span`
  color: ${NewColors.TextShout};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

export const Heading = styled.h3`
  flex-grow: 1;
  font-size: 1rem;
  font-weight: bold;
  margin: 0.25rem 0 0;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const LowerContent = styled.div`
  background: rgba(201 216 241 / 15%);
  border-top: 1px solid #cad6ef;
  display: grid;
  gap: 0.5rem;
  padding: 0.75rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 1rem;
  }
`;

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;

  &::after {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
`;

export const Subtitle = styled.p`
  font-size: 0.875rem;
  margin: 0;
  text-transform: capitalize;
`;

export const UpperContent = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
`;
