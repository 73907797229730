// @ts-nocheck
/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  gql,
  makeVar,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { relayStylePagination } from "@apollo/client/utilities";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { createUploadLink } from "apollo-upload-client";

import { REFRESH_TOKEN_MUTATION } from "../api/mutations/REFRESH_TOKEN";

import { amplitudeIdentify } from "./analytics";
import {
  hasValidAccessToken,
  hasValidRefreshToken,
  removeTokens,
  setAccessTokenExpiry,
} from "./auth";

const errorMessages = [
  "You do not have permission to perform this action",
  "Refresh token is required",
];

const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: "refreshToken",
  isTokenValidOrUndefined: () => {
    if (hasValidRefreshToken()) {
      return hasValidAccessToken();
    }

    return true;
  },
  fetchAccessToken: () => {
    return fetch(process.env.REACT_APP_API_ENDPOINT, {
      method: "POST",
      body: REFRESH_TOKEN_MUTATION(),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  },
  handleFetch: (accessToken) => {
    amplitudeIdentify(accessToken.payload);
    setAccessTokenExpiry(accessToken.payload.exp);
  },
  handleError: () => {
    amplitudeIdentify(null);
    removeTokens();
    isLoggedInVar(false);
    localStorage.clear();
    apolloClient.clearStore();
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );

      const authIssues = graphQLErrors.some(({ message }) =>
        errorMessages.includes(message),
      );
      if (authIssues) {
        amplitudeIdentify(null);
        removeTokens();
        isLoggedInVar(false);
        localStorage.clear();
        apolloClient.clearStore();
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
  credentials: "include",
});

const link = ApolloLink.from([tokenRefreshLink, errorLink, uploadLink]);

export const isLoggedInVar = makeVar(hasValidRefreshToken());

const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`;

export const cache = {
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
        auctions: relayStylePagination(["first", "orderBy", "query", "state"]),
        remints: relayStylePagination([
          "featured",
          "first",
          "orderBy",
          "query",
          "spotsAvailable",
          "state",
        ]),
      },
    },
  },
};

export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(cache),
  typeDefs,
  resolvers: {},
});
