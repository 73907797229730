import styled, { css } from "styled-components";

import { Button } from "../../../../components/Button";
import { Borders, Colors } from "../../../../themes/Default";

interface ContainerProps {
  sold?: boolean;
}

export const Description = styled.p`
  color: ${Colors.GreyBlue};
  font-size: 1.125rem;
  font-weight: bold;
  grid-area: description;
  line-height: 1.5rem;
  margin: 0;
`;

export const Heading = styled.h4`
  color: ${Colors.Blue};
  font-size: 1rem;
  grid-area: heading;
  line-height: 1.25rem;
  margin: 0;
`;

export const Purchase = styled(Button)`
  border: 1px solid transparent;
  grid-area: purchase;
  text-transform: uppercase;

  :disabled {
    background: white;

    /* https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4 to make only the border 50% opacity */
    border: 1px solid ${Colors.Glacier}80;
    opacity: 1;
  }
`;

export const Container = styled.div<ContainerProps>`
  align-items: center;
  background: white;
  border: ${Borders.Live};
  border-radius: 0.5rem;
  box-shadow: 0 0 0.25rem 0 rgb(5 21 52 / 8%);
  display: grid;
  grid-column-gap: 1rem;
  grid-template-areas:
    "heading purchase"
    "description purchase";
  grid-template-columns: 1fr auto;
  padding: 1rem;
  width: 100%;

  ${({ sold }) =>
    sold &&
    css`
      ${Description},
      ${Heading} {
        opacity: 0.6;
      }
    `}
`;
