import { gql } from "@apollo/client";

export const EDIT_USER_PROFILE = gql`
  mutation EDIT_USER_PROFILE($emailAddress: String!) {
    editUserProfile(emailAddress: $emailAddress) {
      errors {
        message
      }
    }
  }
`;
