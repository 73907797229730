import type { ReactNode } from "react";

import { Container, Contents, Heading } from "./styles";

interface Props {
  heading: string;
  children: ReactNode;
}

export const NarrowContent = ({ children, heading }: Props) => (
  <Container>
    <Heading>{heading}</Heading>

    <Contents>{children}</Contents>
  </Container>
);
