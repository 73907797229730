import type { Styles } from "react-modal";
import styled from "styled-components";

import { IconAnchorButton } from "../../../../components/Button";
import { Borders, Breakpoints, NewColors } from "../../../../themes/Default";

export const Container = styled.div`
  background-color: white;
  border: ${Borders.Live};
  border-radius: 0.5rem;
  box-shadow: 0 0 4px 0 rgb(5 21 52 / 8%);
  overflow: hidden;

  @media (min-width: ${Breakpoints.Large}) {
    display: grid;
    grid-template-columns: minmax(62.5%, 1fr) minmax(auto, 27rem);
  }
`;

export const Description = styled.p`
  font-size: 0.875rem;
  margin: 0;

  @media (min-width: ${Breakpoints.Mobile}) {
    font-size: 1rem;
  }
`;

export const Details = styled.div`
  grid-area: details;

  @media (min-width: ${Breakpoints.Mobile}) {
    display: grid;
    grid-template-rows: 1fr auto;
  }

  @media (min-width: ${Breakpoints.Large}) {
    padding: 0 1.5rem 1.5rem;
  }
`;

export const ExternalLink = styled(IconAnchorButton)`
  margin-top: 1rem;
`;

export const Image = styled.div`
  border-radius: 0.5rem;
  grid-area: image;
  overflow: hidden;

  @media (min-width: ${Breakpoints.Large}) {
    border-radius: 0;
  }
`;

export const Information = styled.div`
  display: grid;
  grid-gap: 1rem 0.75rem;
  grid-template-areas:
    "image heading"
    "details details";
  grid-template-columns: 40% auto;
  padding: 1rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    grid-column-gap: 1.5rem;
    grid-template: auto 1fr / 15rem auto;
    grid-template-areas:
      "image heading"
      "image details";
    padding: 1.5rem;
  }

  @media (min-width: ${Breakpoints.Large}) {
    grid-gap: 0.5rem 0;
    grid-template-areas:
      "image heading"
      "image details";
    grid-template-columns: 20.25rem auto;
    padding: 0;
  }
`;

export const LotHeading = styled.h4`
  grid-area: heading;
  margin: 0;

  @media (min-width: ${Breakpoints.Large}) {
    padding: 1.5rem 1.5rem 0;
  }
`;

export const Name = styled.span`
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin-top: 0.25rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

export const Project = styled.span`
  color: ${NewColors.TextSpeak};
  display: block;
  font-size: 0.875rem;
  line-height: 1.5rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    font-size: 1rem;
  }
`;

export const modalStyles: Styles = {
  content: {
    border: Borders.Live,
    borderRadius: "0.75rem",
    maxWidth: "43.5rem",
    overflow: "auto",
  },
};
