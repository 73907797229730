import {
  faExclamationTriangle,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useMemo } from "react";

import { Button } from "@/components/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { useFetchAccountData } from "@/hooks/useFetchAccountData";
import { Colors } from "@/themes/Default";
import { formatWalletAddress } from "@/utils/formatWalletAddress";

import { useLot } from "../../hooks/useLot";
import { LotHeader } from "../LotHeader";
import { LotStatus } from "../LotStatus";

import {
  Bid,
  BidDetail,
  Description,
  ExternalLink,
  Heading,
  History,
  Loading,
  LoadingText,
  NoBids,
  StyledInfoNotice,
  Wrapper,
} from "./styles";

interface Props {
  id: string;
  handlePlaceBid(): void;
}

export const LotDetails = ({ id, handlePlaceBid }: Props) => {
  const { accountData, isLoggedIn } = useFetchAccountData();
  const { error, loading, lot } = useLot(id);

  const showPlaceBidBtn = isLoggedIn && lot.isAcceptingBids;
  const bids = useMemo(
    () =>
      lot.bids.map((bid) => {
        const address = formatWalletAddress({ address: bid.username! });
        const isUserBid = accountData?.username === bid.username;
        const walletLabel = isUserBid ? `You (${address})` : address;

        return (
          <Bid key={bid.id}>
            <BidDetail title={bid.username} isUserBid={isUserBid}>
              {walletLabel}
            </BidDetail>
            <BidDetail>{bid.price}</BidDetail>
          </Bid>
        );
      }),
    [accountData, lot],
  );

  if (error) {
    return (
      <Wrapper>
        <StyledInfoNotice
          icon={faExclamationTriangle}
          message={["Unable to load Lot details. Please try again."]}
          state="negative"
        />
      </Wrapper>
    );
  }

  if (loading) {
    return (
      <Wrapper>
        <Loading>
          <LoadingSpinner color={Colors.Waikawa} size={4} />
          <LoadingText>Loading…</LoadingText>
        </Loading>
      </Wrapper>
    );
  }

  return (
    <>
      <Wrapper>
        <LotHeader id={id}>
          {lot.description && <Description>{lot.description}</Description>}

          {lot.externalLink && (
            <ExternalLink
              href={lot.externalLink}
              icon={faUpRightFromSquare}
              rel="noopener noreferrer"
              target="_blank"
              variant="white"
            >
              View token
            </ExternalLink>
          )}
        </LotHeader>
      </Wrapper>

      <Wrapper>
        <Heading>Bidding History</Heading>
        <History>
          {bids.length ? bids : <NoBids>No bids have been placed</NoBids>}
        </History>
      </Wrapper>

      <Wrapper>
        <Heading>Bidding</Heading>
        <LotStatus id={id} />
      </Wrapper>

      {showPlaceBidBtn && (
        <Wrapper>
          <Button $fullWidth onClick={handlePlaceBid} variant="purple">
            Place bid
          </Button>
        </Wrapper>
      )}
    </>
  );
};
