import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";

import { LoadingSpinner } from "@/components/LoadingSpinner";
import type { VerifyDiscordToken } from "@/generated/schema";
import { Colors } from "@/themes/Default";

import { VERIFY_DISCORD_TOKEN } from "../../api/VERIFY_DISCORD_TOKEN";
import { DiscordLogos } from "../DiscordLogos";

import { Status, StyledAlert, Wrapper } from "./styles";

interface Props {
  token: string;
}

interface MutationResponse {
  verifyDiscordToken: VerifyDiscordToken;
}

const DiscordInfo = () => (
  <>
    <p>
      With your Discord user connected to your Grail wallet, you can place bids
      directly from inside Discord. Just hit &ldquo;Place Bid&rdquo; on any
      auction to submit bids as your associated wallet on Grail. When the
      auction has ended, you will need to visit{" "}
      <a href="grail.live">grail.live</a> to complete the purchase.
    </p>
    <p>
      Run your own Discord, and want to include our bot? We&apos;d love to hear
      from you! We&apos;re currently in beta testing and working with select
      partners. If you&apos;d like to take part, contact us at{" "}
      <a href="mailto:support@grail.live">support@grail.live</a>.
    </p>
  </>
);

export const DiscordConnect = ({ token }: Props) => {
  const [error, setError] = useState(!token);
  const [mutationSent, setMutationSent] = useState(false);
  const [verified, setVerified] = useState(false);
  const [alreadyConnected, setAlreadyConnected] = useState(false);

  const [verifyDiscordToken] = useMutation<MutationResponse>(
    VERIFY_DISCORD_TOKEN,
    {
      variables: { token },
      onCompleted: ({ verifyDiscordToken: { errors } }) => {
        const hasErrors = errors && !!errors.length;
        const isAlreadyConnected =
          hasErrors && errors[0].message?.includes("already connected");

        setMutationSent(true);

        if (isAlreadyConnected) return setAlreadyConnected(true);
        if (hasErrors) return setError(true);

        return setVerified(true);
      },
      onError: () => setError(true),
    },
  );

  useEffect(() => {
    if (token && !mutationSent) {
      verifyDiscordToken();
    }
  }, [mutationSent, token, verifyDiscordToken]);

  if (error) {
    return (
      <Wrapper>
        <StyledAlert state="negative">
          Invalid or expired verification token. Please try making a new
          verification request inside Discord. If you continue having problems,
          contact <a href="mailto:support@grail.live">support@grail.live</a>.
        </StyledAlert>
        <DiscordLogos />
        <DiscordInfo />
      </Wrapper>
    );
  }

  if (alreadyConnected) {
    return (
      <Wrapper>
        <StyledAlert state="neutral">
          Your Grail account is already connected to a Discord user.
        </StyledAlert>
        <DiscordLogos />
        <DiscordInfo />
      </Wrapper>
    );
  }

  if (verified) {
    return (
      <Wrapper>
        <StyledAlert state="positive">
          Your Discord and Grail accounts are now connected.
        </StyledAlert>
        <DiscordLogos />
        <DiscordInfo />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <DiscordLogos />
      <LoadingSpinner color={Colors.Waikawa} size={4} />
      <Status>Loading…</Status>
    </Wrapper>
  );
};
