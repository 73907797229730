import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { Container, Icon, Message, StyledFAIcon, Title } from "./styles";

export type InfoNoticeState = "negative" | "neutral" | "positive" | "warning";

interface Props {
  className?: string;
  icon: IconDefinition;
  message: string[];
  optionalMessage?: string;
  state: InfoNoticeState;
  title?: string;
}

export const InfoNotice = ({
  className,
  icon,
  message,
  state,
  title,
}: Props) => (
  <Container className={className} state={state}>
    <Icon>
      <StyledFAIcon icon={icon} />
    </Icon>

    {title && <Title>{title}</Title>}

    {message.map((m, i) => (
      <Message key={i}>{m}</Message>
    ))}
  </Container>
);
