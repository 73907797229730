import { Link } from "react-router-dom";
import styled from "styled-components";

import { TimeBadge } from "@/components/TimeBadge";
import { Borders, Breakpoints, NewColors } from "@/themes/Default";

export const Card = styled.div`
  background: white;
  display: grid;
  gap: 1.25rem;
  grid-area: card;
  grid-template-rows: 1fr auto;
  padding: 1.25rem 1rem 1rem;
  position: relative;
`;

export const Container = styled.div`
  background: white;
  border: 1px solid white;
  border-radius: 0.5rem;
  color: ${NewColors.TextSpeak};
  overflow: hidden;

  @media (min-width: ${Breakpoints.Large}) {
    display: grid;
    grid-template-areas: "video card";
    grid-template-columns: auto 18.75rem;
  }
`;

export const Description = styled.p`
  -webkit-box-orient: vertical;
  /* stylelint-disable */
  display: -webkit-box;
  /* stylelint-enable */
  font-size: 0.875rem;
  -webkit-line-clamp: 3;
  margin: 0.5rem 0 0;
  overflow: hidden;
`;

export const Heading = styled.h3`
  color: ${NewColors.TextShout};
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0.75rem 0 0;
`;

export const Info = styled.p`
  color: ${NewColors.Purple};
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 1.5rem 0 0;
`;

export const LowerContent = styled.div`
  border-top: ${Borders.Nft};
  flex: 0;
  padding-top: 1rem;
`;

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;

  &::after {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
`;

export const StyledTimeBadge = styled(TimeBadge)`
  align-self: flex-start;
  margin-top: 1.5rem;
`;

export const Subtitle = styled.p`
  color: ${NewColors.TextSpeak};
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;

  span {
    padding: 0 0.5rem;
  }
`;

export const UpperContent = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-rows: 1fr auto;
`;

export const Video = styled.div`
  border-radius: 0.5rem 0.5rem 0 0;
  grid-area: video;
  overflow: hidden;

  @media (min-width: ${Breakpoints.Large}) {
    border-radius: 0.5rem 0 0 0.5rem;
  }
`;
