import { useState } from "react";

import { ContentSection, Label, Placeholder } from "../Capsule";

import { AddModal, List } from "./components";
import { Container, Details, EmptyList } from "./styles";

// TEMP PROPS
interface AuctionLotsProps {
  hasLots?: boolean;
}

export const AuctionLots = ({ hasLots }: AuctionLotsProps) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const handleToggle = () => setShowAddModal(!showAddModal);

  return (
    <>
      {showAddModal && (
        <AddModal handleToggle={handleToggle} show={showAddModal} />
      )}

      <Container heading="Included lots">
        <Details>
          <ContentSection>
            <Label>Lot summary</Label>
            <Placeholder>Not lots included</Placeholder>
          </ContentSection>

          <ContentSection>
            <Label>Opening price range</Label>
            <Placeholder>N/A</Placeholder>
          </ContentSection>
        </Details>

        <ContentSection>
          <Label>Lots included</Label>
          {hasLots ? (
            <List />
          ) : (
            <EmptyList onClick={handleToggle}>Add a lot</EmptyList>
          )}
        </ContentSection>
      </Container>
    </>
  );
};
