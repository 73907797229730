import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import {
  Container,
  Content,
  Description,
  Heading,
  Icon,
  LinkContainer,
  StyledLink,
} from "./styles";

interface Link {
  label: string;
  url: string;
  direction?: "backwards" | "forwards";
}

interface Props {
  background?: string;
  description: string;
  heading: string;
  link?: Link;
}

export const PageBanner = ({
  background,
  description,
  heading,
  link,
}: Props) => {
  const isBackwards = link?.direction === "backwards";
  const icon = isBackwards ? faArrowLeft : faArrowRight;

  return (
    <Container background={background}>
      <Content reverse={isBackwards}>
        <Heading>{heading}</Heading>

        <Description>{description}</Description>

        {link && (
          <LinkContainer $reverse={isBackwards}>
            <StyledLink $reverse={isBackwards} to={link.url}>
              {link.label}
              <Icon icon={icon} />
            </StyledLink>
          </LinkContainer>
        )}
      </Content>
    </Container>
  );
};
