import styled, { css } from "styled-components";

import {
  Borders,
  Breakpoints,
  CONTENT_WIDTH,
  NewColors,
} from "@/themes/Default";

export const Container = styled.section`
  display: grid;
  padding: 0 1.5rem;
  place-items: center;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 0 3rem;
  }
`;

export const Content = styled.div`
  width: 100%;
`;

export const Control = styled.h2<{ active?: boolean }>`
  background: transparent;
  border: none;
  color: ${NewColors.Purple};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  margin: 0;
  outline-offset: 0.25rem;
  padding: 0;

  ${({ active }) =>
    active &&
    css`
      color: ${NewColors.Purple};
      opacity: 1;
    `}
`;

export const Controls = styled.div`
  border-bottom: ${Borders.User};
  display: flex;
  gap: 2rem;
  padding-bottom: 1rem;
`;

export const Wrapper = styled.div`
  max-width: ${CONTENT_WIDTH};
  width: 100%;
`;
