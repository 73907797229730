import { gql } from "@apollo/client";

import { LOT_DETAILS } from "./fragments/LOT";

export const GET_LOT = gql`
  ${LOT_DETAILS}

  query GET_LOT($id: ID!) {
    lot(id: $id) {
      ...lotDetails
    }
  }
`;
