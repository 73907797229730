import { Styles } from "react-modal";
import styled from "styled-components";

export const customStyles: Styles = {
  content: {
    borderRadius: "0.75rem",
    maxWidth: "37.5rem",
    maxHeight: "28rem",
  },
};

export const Container = styled.div`
  display: grid;
  gap: 0.5rem;
  margin: 1rem;
`;
