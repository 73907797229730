import { gql } from "@apollo/client";

export const GET_ACCOUNT_DETAILS = gql`
  query GET_ACCOUNT_DETAILS {
    myAccount {
      balance
      username
      isStaff

      discorduser {
        discordUsername
        discordDiscriminator
      }

      teamMemberships {
        edges {
          node {
            channel {
              id
              name
              profileImageUrl(width: 256, height: 256)
              slug
            }
          }
        }
      }

      userprofile {
        emailAddress
      }
    }
  }
`;
