import { useState } from "react";
import type { ReactNode } from "react";

import { Container, Contents, Heading, Toggle } from "./styles";

interface Props {
  children: ReactNode;
  headingComponent?: ReactNode;
  opened?: boolean;
  title: string;
}

export const CollapsibleSection = ({
  children,
  headingComponent,
  opened = true,
  title,
}: Props) => {
  const [show, setShow] = useState(opened);
  const toggleShow = () => setShow(!show);

  return (
    <Container>
      <Heading>
        <Toggle aria-expanded={show} onClick={toggleShow}>
          {title}

          {headingComponent && headingComponent}

          <svg viewBox="0 0 10 10" aria-hidden="true" focusable="false">
            <rect className="vert" height="8" width="2" y="1" x="4" />
            <rect height="2" width="8" y="4" x="1" />
          </svg>
        </Toggle>
      </Heading>

      <Contents hidden={!show}>{children}</Contents>
    </Container>
  );
};
