export const ANNOUNCEMENT_EVENTS = {
  BANNER: {
    VIEWED: "Announcement - Banner Viewed",
    CLICKED: "Announcement - Banner Clicked",
  },
};

export const ACCOUNT_EVENTS = {
  BIDDING_DEPOSIT: {
    PAGE_VIEWED: "Account - Bidding Deposit Page Viewed",
    PAYMENT: {
      INITIATED: "Account - Bidding Deposit Payment Initiated",
      SUBMITTED: "Account - Bidding Deposit Payment Submitted",
    },
    WITHDRAWAL: {
      FORM_VIEWED: "Account - Bidding Deposit Withdrawal Form Viewed",
      SUBMITTED: "Account - Bidding Deposit Withdrawal Submitted",
    },
  },
  SIGN_OUT: {
    FAILED: "Account - Sign Out Failed",
    SUCCEEDED: "Account - Signed Out",
  },
};

export const CRYPTO_WALLET_EVENTS = {
  CONNECT: {
    FAILED: "Crypto Wallet - Account Connect Failed",
    SUCCEEDED: "Crypto Wallet - Account Connect Succeeded",
  },
  DISCONNECT: {
    FAILED: "Crypto Wallet - Account Disconnect Failed",
    SUCCEEDED: "Crypto Wallet - Account Disconnect Succeeded",
  },
};

export const EVENTS_EVENTS = {
  HOME: {
    PAGE_VIEWED: "Events - Home Page Viewed",
  },
  PAST_EVENTS: {
    PAGE_VIEWED: "Events - Past Events Page Viewed",
  },
};

export const AUCTIONS_EVENTS = {
  AUCTION: {
    PAGE_VIEWED: "Auctions - Auction Page Viewed",
  },
  LOT: {
    DETAIL_VIEWED: "Auctions - Lot Detail Viewed",
  },
  BID: {
    FORM_VIEWED: "Auctions - Bid Form Viewed",
    SUCCEEDED: "Auctions - Bid Succeeded",
    FAILED: "Auctions - Bid Failed",
  },
};

export const MANAGE_CHANNEL_EVENTS = {
  REQUEST_AUCTION: {
    EXTERNAL_FORM_VIEWED:
      "Manage Channel - Request Auction External Form Viewed",
  },
};

export const SUBMISSIONS_EVENTS = {
  EXTERNAL_FORM_VIEWED: "Submissions - External Form Viewed",
};

export const SUPPORT_EVENTS = {
  FAQ: {
    PAGE_VIEWED: "Support - FAQ Page Viewed",
  },
  PRIVACY_POLICY: {
    PAGE_VIEWED: "Support - Privacy Policy Page Viewed",
  },
  SUPPORT: {
    PAGE_VIEWED: "Support - Support Page Viewed",
  },
  TERMS_OF_SERVICE: {
    PAGE_VIEWED: "Support - Terms Of Service Page Viewed",
  },
};
