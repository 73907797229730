import styled from "styled-components";

import { Copy as LayoutCopy } from "../Layout";

export const Container = styled.div`
  color: rgba(31 31 35 / 80%);
  display: grid;
  gap: 2rem;
`;

export const Content = styled.div`
  margin-top: 1rem;
`;

export const Copy = styled(LayoutCopy)`
  margin: 0.5rem 0 0;
`;

export const Heading = styled.h2`
  color: inherit;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 0;
`;

export const Members = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
`;
