import type { Styles } from "react-modal";
import styled from "styled-components";

export const Chain = styled.span`
  font-weight: bold;
`;

export const Container = styled.div`
  padding: 1.5rem;
`;

export const customModalStyles: Styles = {
  content: {
    borderRadius: "0.75rem",
    maxWidth: "37.5rem",
    overflow: "hidden",
  },
};
