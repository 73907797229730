import styled, { css } from "styled-components";

import {
  Borders,
  Breakpoints,
  CONTENT_WIDTH,
  Gradients,
  NewColors,
} from "@/themes/Default";

export const Avatar = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const AvatarContainer = styled.div`
  background: ${Gradients.Channels};
  background-color: ${NewColors.Deadfill};
  border: 4px solid white;
  border-radius: 100%;
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 50%);
  height: 6rem;
  overflow: hidden;
  position: absolute;
  width: 6rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    border-width: 8px;
    height: 12rem;
    width: 12rem;
  }
`;

export const Banner = styled.div<{ background?: string }>`
  background-image: linear-gradient(
    135deg,
    ${NewColors.Purple} 0%,
    ${NewColors.Green} 100%
  );
  background-position: center;
  background-size: cover;
  border: ${Borders.User};
  display: flex;
  flex-direction: column-reverse;
  height: 16.875rem;
  width: 100%;

  @media (min-width: ${Breakpoints.Mobile}) {
    height: 22.5rem;
  }

  ${({ background }) =>
    background &&
    css`
      background-image: url(${background}),
        linear-gradient(135deg, ${NewColors.Purple} 0%, ${NewColors.Green} 100%);
    `}
`;

export const Below = styled.div`
  display: grid;
  padding: 0.75rem 1.5rem;
  place-items: center;
  width: 100%;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 0.75rem 3rem;
  }
`;

export const Container = styled.section`
  display: grid;
  margin-bottom: 1.75rem;
  place-items: center;

  @media (min-width: ${Breakpoints.Mobile}) {
    margin-bottom: 3rem;
  }
`;

export const Content = styled.div`
  background: rgb(0 0 0 / 40%);
  display: grid;
  padding: 1rem 1.5rem;
  place-items: center;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 1rem 3rem;
  }
`;

export const ContentContainer = styled.div`
  align-items: center;
  display: grid;
  max-width: ${CONTENT_WIDTH};
  padding-left: 7rem;
  position: relative;
  width: 100%;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding-left: 14rem;
  }
`;

export const Username = styled.h1`
  color: white;
  font-size: 2rem;
  font-weight: 300;
  line-height: 2.25rem;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: ${Breakpoints.Mobile}) {
    font-size: 3rem;
    line-height: 4rem;
  }
`;

export const WalletAddress = styled.p`
  color: ${NewColors.TextSpeak};
  margin: 0;
  overflow: hidden;
  padding-left: 7rem;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding-left: 14rem;
  }
`;

export const WalletContainer = styled.div`
  max-width: ${CONTENT_WIDTH};
  overflow: hidden;
  width: 100%;
`;
