import { Helmet } from "react-helmet";
import { Navigate, useParams } from "react-router-dom";

import { LoadingBoundary } from "@/components/LoadingBoundary";

import { Layout } from "../components/Layout";
import { ManageProfile } from "../components/ManageProfile";
import { useChannel } from "../hooks/useChannel";

export const Profile = () => {
  const { channelSlug } = useParams();
  const { channel, hasAccess, loading } = useChannel(channelSlug ?? "");

  return (
    <LoadingBoundary loading={loading}>
      {hasAccess && channelSlug ? (
        <>
          <Helmet>
            <title>{channel?.name} - Profile</title>
          </Helmet>

          <Layout heading="Channel profile">
            <ManageProfile channelSlug={channelSlug} />
          </Layout>
        </>
      ) : (
        <Navigate replace to="/" />
      )}
    </LoadingBoundary>
  );
};
