import type { ReactNode } from "react";

import { NarrowContent } from "@/components/NarrowContent";

import { Sidebar } from "../Sidebar";

import { Container, Wrapper } from "./style";

interface Props {
  children: ReactNode;
  heading: string;
}

export const Layout = ({ children, heading }: Props) => (
  <Container>
    <Wrapper>
      <Sidebar />

      <NarrowContent heading={heading}>{children}</NarrowContent>
    </Wrapper>
  </Container>
);
