import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";

import { NextButton, PrevButton } from "@/components/EmblaCarousel";
import { Breakpoints, Colors } from "@/themes/Default";

interface ViewportProps {
  hasPrev: boolean;
  hasNext: boolean;
}

const gradient = `
  hsl(0deg 0% 100% / 0%) 0%,
  hsl(0deg 0% 100% / 1.3%) 7.7%,
  hsl(0deg 0% 100% / 4.9%) 14%,
  hsl(0deg 0% 100% / 10.4%) 19.3%,
  hsl(0deg 0% 100% / 17.5%) 23.9%,
  hsl(0deg 0% 100% / 25.9%) 27.9%,
  hsl(0deg 0% 100% / 35.2%) 31.7%,
  hsl(0deg 0% 100% / 45%) 35.6%,
  hsl(0deg 0% 100% / 55%) 39.8%,
  hsl(0deg 0% 100% / 64.8%) 44.5%,
  hsl(0deg 0% 100% / 74.1%) 50.1%,
  hsl(0deg 0% 100% / 82.5%) 56.8%,
  hsl(0deg 0% 100% / 89.6%) 64.8%,
  hsl(0deg 0% 100% / 95.1%) 74.5%,
  hsl(0deg 0% 100% / 98.7%) 86.2%,
  hsl(0deg 0% 100% / 100%) 100%
`;

const CarouselButton = css`
  align-items: center;
  background-color: rgb(254 254 254 / 95%);
  border: 1px solid #dee2ea;
  border-radius: 2rem;
  display: none;
  height: 4rem;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 4rem;
  z-index: 3;

  :disabled {
    opacity: 0.6;
  }

  :hover {
    background-color: ${Colors.Glacier};
    border: 1px solid ${Colors.Blue};
    transform: translateY(-50%) translateY(-2px);
    transition: all 200ms ease-in-out;
  }

  @media (min-width: ${Breakpoints.Mobile}) {
    display: flex;
  }
`;

export const ButtonIcon = styled(FontAwesomeIcon)`
  color: ${Colors.Blue};
  height: 1.5rem;
  width: 1.5rem;
`;

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    margin-left: 3rem;
    margin-right: 3rem;
  }
`;

export const Embla = styled.div`
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-top: -1rem;
  overflow: hidden;
  position: relative;

  @media (min-width: ${Breakpoints.Mobile}) {
    margin-left: -3rem;
    margin-right: -3rem;
  }
`;

export const Slide = styled.div<{ featured?: boolean }>`
  max-width: 80%;
  min-width: 80%;

  @media (min-width: ${Breakpoints.Mobile}) {
    max-width: 22rem;
    min-width: 22rem;
  }

  ${({ featured }) =>
    featured &&
    css`
      max-width: 12rem;
      min-width: 12rem;

      @media (min-width: ${Breakpoints.Mobile}) {
        max-width: 16rem;
        min-width: 16rem;
      }
    `}
`;

export const StyledNextButton = styled(NextButton)`
  ${CarouselButton}

  right: 0;
`;

export const StyledPrevButton = styled(PrevButton)`
  ${CarouselButton}

  left: 0;
`;

export const Viewport = styled.div<ViewportProps>`
  margin-top: 1rem;
  position: relative;
  width: 100%;

  ::after,
  ::before {
    height: calc(100% + 1rem);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    z-index: 1;

    @media (min-width: ${Breakpoints.Mobile}) {
      width: 3rem;
    }
  }

  ::after {
    background-image: linear-gradient(to right, ${gradient});
    right: 0;

    ${({ hasNext }) =>
      hasNext &&
      css`
        content: "";
      `}
  }

  ::before {
    background-image: linear-gradient(to left, ${gradient});
    left: 0;

    ${({ hasPrev }) =>
      hasPrev &&
      css`
        content: "";
      `}
  }
`;
