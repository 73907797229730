import styled from "styled-components";

import { Breakpoints, NewColors } from "../../../../themes/Default";

export const Contents = styled.div`
  margin-top: 2rem;
`;

export const Description = styled.p`
  color: ${NewColors.TextSpeak};
  font-size: 1rem;
  grid-area: description;
  line-height: 1.5rem;
  margin: 0.5rem 0 0;
`;

export const Details = styled.div`
  @media (min-width: ${Breakpoints.Mobile}) {
    column-gap: 0.5rem;
    display: grid;
    grid-template-areas:
      "heading view-all"
      "description view-all";
    grid-template-columns: 1fr 8rem;
  }
`;

export const Heading = styled.h2`
  color: ${NewColors.Purple};
  font-size: 1.5rem;
  font-weight: bold;
  grid-area: heading;
  line-height: 2rem;
  margin: 0;

  @media (min-width: ${Breakpoints.Mobile}) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;

export const ViewAll = styled.div`
  grid-area: view-all;
  margin-top: 1.5rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    margin-top: 0;
  }
`;
