import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";

import { Breakpoints, Colors, CONTENT_WIDTH } from "../../../../themes/Default";

export const Button = styled.button<{ active?: boolean }>`
  background: white;
  border: 0;
  color: ${Colors.GreyBlue};
  display: grid;
  font-weight: bold;
  gap: 0.5rem;
  grid-template-columns: auto auto;
  line-height: 1.25rem;
  padding: 1.25rem 1rem 1.5rem;

  ${({ active }) =>
    active &&
    css`
      box-shadow: inset 0 -8px 0 0 ${Colors.Blue};
    `}
`;

export const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const Controls = styled.div`
  background: white;
  border-bottom: 1px solid ${Colors.Glacier};
  display: grid;
  padding: 0 1.5rem;
  place-items: center;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 0 3rem;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${Colors.Blue};
  height: 1.25rem;
  width: 1.25rem;
`;

export const Listings = styled.div`
  background: white;
  box-shadow: 0 1.5px 0 0 ${Colors.Glacier}, 0 -1.5px 0 0 ${Colors.Glacier};
  display: grid;
  margin-top: 1.5rem;
  padding: 1.5rem;
  place-items: center;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 3rem;
  }
`;

export const Wrapper = styled.div`
  max-width: ${CONTENT_WIDTH};
  width: 100%;
`;
