import { useState } from "react";

import type { Change } from "@/hooks/useForm";

import {
  Container,
  Copy,
  Error,
  InputWrapper,
  Label,
  TextArea as StyledTextArea,
} from "../../styles";

interface TextAreaProps {
  defaultValue?: string;
  description?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  handleInput(e: Change<HTMLTextAreaElement>): void;
  label: string;
  name: string;
  placeholder?: string;
  rows?: number;
}

export const TextArea = ({
  defaultValue,
  description,
  disabled,
  error = false,
  errorMessage,
  handleInput,
  label,
  name,
  placeholder,
  rows = 5,
}: TextAreaProps) => {
  const [beenEdited, setBeenEdited] = useState(false);

  const handleEntry = (e: Change<HTMLTextAreaElement>) => {
    setBeenEdited(true);
    handleInput(e);
  };

  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>

      {description && <Copy>{description}</Copy>}

      <InputWrapper>
        <StyledTextArea
          defaultValue={defaultValue}
          disabled={disabled}
          error={beenEdited && error}
          id={name}
          name={name}
          onInput={handleEntry}
          placeholder={placeholder}
          rows={rows}
        />

        {beenEdited && error && <Error>⚠️ {errorMessage}</Error>}
      </InputWrapper>
    </Container>
  );
};
