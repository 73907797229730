import styled from "styled-components";

export const Loading = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 4rem;
  place-items: center;
`;

export const Status = styled.p`
  font-weight: bold;
  margin: 0;
`;
