import { Button } from "@/components/Button";
import { DateTimePicker, Select } from "@/components/FormElements";
import {
  Modal,
  ModalButtonContainer,
  ModalContent,
  smallModalStyle,
} from "@/components/Modal";
import {
  AuctionLotBidExtensionPeriod,
  AuctionLotInterval,
  AuctionLotsOpenBidding,
} from "@/generated/schema";
import { useForm } from "@/hooks/useForm";
import { generateTimeLabel } from "@/utils/generateTimeLabel";

interface EditModalProps {
  handleToggle(): void;
  show: boolean;
}

interface FormState {
  bidExtension: AuctionLotBidExtensionPeriod;
  firstLotConclusion: Date | undefined;
  openBiddingAt: Date | undefined;
  openLotsForBidding: AuctionLotsOpenBidding;
  lotsTimeInterval: AuctionLotInterval;
}

export const EditModal = ({ handleToggle, show }: EditModalProps) => {
  const { formState, handleDateChange, handleInputChange } = useForm<FormState>(
    {
      bidExtension: AuctionLotBidExtensionPeriod.A_30,
      firstLotConclusion: undefined,
      openBiddingAt: undefined,
      openLotsForBidding: AuctionLotsOpenBidding.IMMEDIATELY,
      lotsTimeInterval: AuctionLotInterval.A_300,
    },
  );

  const isScheduled =
    formState.openLotsForBidding === AuctionLotsOpenBidding.SCHEDULED;
  const handleSubmit = () => alert("To be implemented");

  return (
    <>
      {show && (
        <Modal
          customStyles={{
            ...smallModalStyle,
            content: { ...smallModalStyle.content, maxHeight: "95vh" },
          }}
          handleClose={handleToggle}
          isOpen={show}
          title="Edit auction settings"
        >
          <ModalContent>
            <Select
              defaultValue={formState.openLotsForBidding}
              description="Customise whether lots are opened for bidding automatically when the auction is published, or await a specific date."
              handleChange={handleInputChange}
              label="Open lots for bidding"
              name="openLotsForBidding"
            >
              <option value={AuctionLotsOpenBidding.IMMEDIATELY}>
                Automatically, when auction is published
              </option>
              <option value={AuctionLotsOpenBidding.SCHEDULED}>
                At a specific time and date
              </option>
            </Select>

            {/* TODO: Should take right now as minimum, and before live show starts as max */}
            {isScheduled && (
              <DateTimePicker
                defaultValue={formState.openBiddingAt}
                description="The date and time that bidding will open for all lots."
                label="Open bidding at"
                minDate={new Date()}
                name="openBiddingAt"
                onChange={handleDateChange}
              />
            )}

            {/* TODO: Should take auction start time as minimum, and before live show starts as max */}
            <DateTimePicker
              defaultValue={formState.firstLotConclusion}
              description="The date and time that bidding will end on the first lot in the auction."
              label="First lot conclusion time"
              minDate={new Date()}
              name="firstLotConclusion"
              onChange={handleDateChange}
            />

            <Select
              defaultValue={formState.lotsTimeInterval}
              description="How long a time period is left between the scheduled conclusion time of adjacent lots."
              handleChange={handleInputChange}
              label="Lot conclusion time interval"
              name="lotsTimeInterval"
            >
              {Object.keys(AuctionLotInterval).map((value, idx) => (
                <option key={idx} value={value}>
                  {generateTimeLabel(value as AuctionLotInterval, "No delay")}
                </option>
              ))}
            </Select>

            <Select
              defaultValue={formState.bidExtension}
              description="If new bids are placed close to the end of the auction, the bidding timer will reset to this value. Prevents bid sniping and allows participants to take some time to consider whether to bid."
              handleChange={handleInputChange}
              label="Late bid extensions"
              name="bidExtension"
            >
              {Object.keys(AuctionLotBidExtensionPeriod).map((value, idx) => (
                <option key={idx} value={value}>
                  {generateTimeLabel(
                    value as AuctionLotBidExtensionPeriod,
                    "No extension",
                  )}
                </option>
              ))}
            </Select>

            <ModalButtonContainer>
              <Button onClick={handleSubmit} variant="green">
                Save changes
              </Button>
            </ModalButtonContainer>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
