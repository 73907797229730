import { useMutation } from "@apollo/client";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import type { FormEvent } from "react";

import { Button } from "@/components/Button";
import { Error, Input } from "@/components/FormElements";
import type { EditUserProfile } from "@/generated/schema";

import { EDIT_USER_PROFILE } from "../../api/EDIT_USER_PROFILE";
import { DiscordIntegration } from "../DiscordIntegration";

import {
  ApplyButton,
  Container,
  CopyAddress,
  Form,
  FormCopy,
  FormHeading,
  FormInput,
  FormSection,
  Heading,
  HeadingWithButton,
  SubmitButtonContainer,
  Success,
} from "./styles";

interface Props {
  discordUser?: string;
  emailAddress?: string;
  walletAddress: string;
}

interface MutationResponse {
  editUserProfile: EditUserProfile;
}

export const EditProfile = ({
  discordUser,
  emailAddress = "",
  walletAddress,
}: Props) => {
  const [copied, setCopied] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [failedToSubmit, setFailedToSubmit] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [copied, setCopied]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [error, setError]);

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => setSuccess(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [success, setSuccess]);

  useEffect(() => setEmail(emailAddress), [emailAddress]);

  const [updateUserEmail] = useMutation<MutationResponse>(EDIT_USER_PROFILE, {
    onError: () => {
      setError(true);
      setSubmitting(false);
    },
    onCompleted: ({ editUserProfile: { errors } }) => {
      const hasErrors = !!errors?.length;

      setError(hasErrors);
      setSubmitting(false);
      setSuccess(!hasErrors);
    },
    variables: { emailAddress: email },
  });

  const copyAddress = useCallback(async () => {
    if (!walletAddress) return;

    await navigator.clipboard.writeText(walletAddress);

    setCopied(true);
    setTimeout(() => setCopied(false), 600);
  }, [walletAddress]);

  const handleSubmit = () => {
    setFailedToSubmit(invalidEmail);

    if (!invalidEmail) {
      setSubmitting(true);
      updateUserEmail();
    }
  };

  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const isInvalid = !/^\S+@\S+\.\S+$/.test(value) && value !== "";

    setEmail(value);
    setInvalidEmail(isInvalid);
  };

  return (
    <Container>
      <Heading>Edit profile</Heading>

      <Form>
        <FormSection>
          <HeadingWithButton>
            <FormHeading>Wallet address</FormHeading>
            <CopyAddress onClick={copyAddress}>
              {copied ? "Copied" : "Copy address"}
            </CopyAddress>
          </HeadingWithButton>

          <FormInput defaultValue={walletAddress} disabled />
        </FormSection>

        <Input
          defaultValue={email}
          description="Used to contact you regarding your bids and any auctions you partcipate in. This is not shared with other users"
          error={invalidEmail && failedToSubmit}
          errorMessage="Invalid email address"
          handleInput={handleInput}
          label="Email address"
          name="email"
          placeholder="user@example.com"
          type="email"
        />

        <FormSection>
          <FormHeading>Apply for a Grail auctions channel</FormHeading>
          <FormCopy>
            Are you an NFT creator, artist, community manager or influencer?
            Interested in running your own livestreamed auctions on Grail?
            Follow the link below to find out more and apply for your own
            auctions channel.
          </FormCopy>

          <ApplyButton
            href="/request-an-auction"
            rel="noopener noreferrer"
            target="_blank"
          >
            Apply for auctions
            <FontAwesomeIcon icon={faUpRightFromSquare} />
          </ApplyButton>
        </FormSection>

        <FormSection>
          <FormHeading>App integrations</FormHeading>
          <FormCopy>
            Interact with Grail directly from other apps by connecting your
            accounts.
          </FormCopy>

          <DiscordIntegration username={discordUser} />
        </FormSection>
      </Form>

      <SubmitButtonContainer>
        <Button onClick={handleSubmit} variant="green">
          {submitting ? "Saving…" : "Save"}
        </Button>
      </SubmitButtonContainer>

      {error && <Error>❌ Something went wrong, please try again!</Error>}
      {success && <Success>✅ Your profile has been updated!</Success>}
    </Container>
  );
};
