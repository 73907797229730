import { Moralis } from "moralis";
import { useEffect, useMemo, useState } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";

import { CryptoCurrencies } from "@/generated/schema";
import { useDeviceType } from "@/hooks/useDeviceType";
import { useWalletAuthentification } from "@/hooks/useWalletAuthentification";
import { logAmplitudeEvent } from "@/utils/analytics";
import { CRYPTO_WALLET_EVENTS } from "@/utils/analytics/events";

import { Currency, Error, EthNetwork, SolNetwork } from "../types";
import {
  displayBalance,
  getMoralisEnvData,
  getProviderByChain,
  getWalletAddressByChain,
} from "../utils";

export const useWallet = (chain: CryptoCurrencies) => {
  const {
    authenticate,
    isAuthenticated,
    isInitialized,
    logout: moralisLogout,
    user,
  } = useMoralis();
  const web3Api = useMoralisWeb3Api();
  const { network } = getMoralisEnvData(chain);
  const provider = getProviderByChain(chain);
  const walletAddress = getWalletAddressByChain(chain, user);
  const { error, login, logout } = useWalletAuthentification({
    cryptoWalletId: walletAddress,
    moralisUserId: user?.id,
    logOutUser: false,
    chain,
    user,
  });

  const { isMobileDevice } = useDeviceType();

  const [balance, setBalance] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [noWalletDetected, setNoWalletDetected] = useState(false);

  const notDetected = !isAuthenticated && !provider && !isMobileDevice;

  const sendToMetaMask = isMobileDevice && !provider;

  const connect = async () => {
    try {
      setNoWalletDetected(notDetected);

      if (sendToMetaMask) {
        const currentLocation = `${window.location.host}${window.location.pathname}`;
        window.location.href = `https://metamask.app.link/dapp/${currentLocation}`;
      }

      if (isInitialized) {
        localStorage.setItem(`${provider}-wallet`, "true");
        await authenticate({
          onSuccess: () => {
            login();
            setErrorMessage(error ?? "");
            logAmplitudeEvent(CRYPTO_WALLET_EVENTS.CONNECT.SUCCEEDED, {
              "Wallet App": chain,
            });
          },
          provider: "metamask",
          signingMessage:
            "Sign this message to to take part in NFT events and customize your profile",
        });
      }

      setErrorMessage(error ?? "");
    } catch (err) {
      setErrorMessage((err as Error).message);
      logAmplitudeEvent(CRYPTO_WALLET_EVENTS.CONNECT.FAILED, {
        Error: err,
      });
      localStorage.removeItem(`${provider}-wallet`);
    }
  };

  const disconnect = async () => {
    try {
      if (isInitialized) {
        logout({
          onCompleted: async () => {
            setErrorMessage(error ?? "");
            await moralisLogout();
          },
        });
      }

      localStorage.removeItem(`${provider}-wallet`);
      logAmplitudeEvent(CRYPTO_WALLET_EVENTS.DISCONNECT.SUCCEEDED, {
        "Wallet App": chain,
      });
    } catch (err) {
      setErrorMessage((err as Error).message);
      logAmplitudeEvent(CRYPTO_WALLET_EVENTS.DISCONNECT.FAILED, {
        Error: err,
      });
    }
  };

  useEffect(() => {
    const getBalance = async (chain: CryptoCurrencies) => {
      if (chain === CryptoCurrencies.SOL) {
        const result = await Moralis.SolanaAPI.account.balance({
          network: network as SolNetwork,
          address: walletAddress,
        });

        if (user?.id) {
          setBalance(result.solana);
        }
      } else if (chain === CryptoCurrencies.ETH) {
        const result = await web3Api.account.getNativeBalance({
          chain: network as EthNetwork,
          address: walletAddress,
        });

        if (user?.id) {
          setBalance(Moralis.Units.FromWei(result?.balance));
        }
      }
    };

    if (isInitialized && walletAddress) {
      getBalance(chain);
    }
  }, [chain, isInitialized, network, user?.id, walletAddress, web3Api.account]);

  const getBalanceArray = useMemo(() => {
    let balances = [];

    if (chain === CryptoCurrencies.ETH) {
      balances.push(displayBalance(balance, Currency.ETH));
    } else balances.push(displayBalance(balance, Currency.SOL));

    return balances;
  }, [balance, chain]);

  return {
    balances: getBalanceArray,
    connect,
    disconnect,
    errorMessage,
    isWalletConnected: isAuthenticated,
    provider,
    noWalletDetected,
    setNoWalletDetected,
    walletAddress,
  };
};
