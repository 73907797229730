import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Styles } from "react-modal";
import styled from "styled-components";

import { Borders, Breakpoints, NewColors } from "../../themes/Default";

export const Header = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1rem 0;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 2rem 2rem 0;
  }
`;

export const Title = styled.h2`
  color: ${NewColors.TextShout};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  margin-bottom: 0;
  text-align: left;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  display: grid;
  padding: 0;
  place-items: center;
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  color: ${NewColors.TextShout};
  height: 1.5rem;
  width: 1.5rem;
`;

export const ModalButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 12rem;
  justify-content: end;
`;

export const ModalContent = styled.div`
  color: ${NewColors.TextSpeak};
  display: grid;
  gap: 1.5rem;
  padding: 1rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 1rem 2rem 2rem;
  }
`;

export const ModalCopy = styled.p`
  margin: 0;
`;

export const defaultStyles: Styles = {
  content: {
    bottom: "auto",
    border: Borders.User,
    borderRadius: "1rem",
    color: NewColors.TextSpeak,
    fontFamily: "Quicksand, sans-serif",
    left: "50%",
    margin: "auto",
    maxHeight: "80vh",
    maxWidth: "50rem",
    padding: "0",
    right: "auto",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
  },
  /* stylelint-disable */
  overlay: {
    backdropFilter: "blur(3px)",
    background: "rgb(0 0 0 / 40%)",
    zIndex: 1000,
  },
  /* stylelint-enable */
};

export const smallModalStyle: Styles = {
  content: {
    maxWidth: "36rem",
    overflow: "auto",
  },
};
