import styled from "styled-components";

import { Breakpoints } from "../../../../themes/Default";

export const Grid = styled.div`
  display: grid;
  row-gap: 1rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    row-gap: 1.5rem;
  }
`;
