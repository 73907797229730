import { OperationVariables } from "@apollo/client";

import { CarouselSection } from "@/features/events";

import { useLots } from "../../hooks/useLots";
import { FeaturedLotCard } from "../FeaturedLotCard";

interface Props {
  description: string;
  options: OperationVariables;
  title: string;
}

export const LotCarousel = ({ description, options, title }: Props) => {
  const { lots, loading, error } = useLots({
    ...options,
  });

  return (
    <CarouselSection
      description={description}
      error={error}
      featured={true}
      loading={loading}
      title={title}
    >
      {lots?.map((lot) => (
        <FeaturedLotCard
          id={lot?.node?.id!}
          link={`/auctions/${lot?.node?.auction?.slug}/${lot?.node?.id}`}
          key={lot?.node?.id!}
        />
      ))}
    </CarouselSection>
  );
};
