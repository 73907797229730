import styled, { css } from "styled-components";

import { Borders, Gradients, NewColors } from "@/themes/Default";

export const Container = styled.div<{ border?: boolean }>`
  background: white;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: 9.25rem auto;
  overflow: hidden;

  ${({ border }) =>
    border &&
    css`
      border: ${Borders.User};
      box-shadow: 0 8px 16px 0 rgb(74 75 79 / 20%);
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem;
`;

export const Detail = styled.div`
  color: ${NewColors.TextSpeak};
  display: grid;
  gap: 0.25rem;

  & + & {
    @media (min-width: 37.5rem) {
      text-align: right;
    }
  }
`;

export const Details = styled.div`
  display: grid;
  gap: 1rem;

  @media (min-width: 37.5rem) {
    grid-template-columns: auto auto;
  }
`;
export const Image = styled.img`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

export const ImageContainer = styled.div`
  background: ${Gradients.Channels};
  background-color: ${NewColors.Deadfill};
  border-bottom-right-radius: 0.5rem;
  height: min-content;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (min-width: 37.5rem) {
    border-bottom-right-radius: 0;
  }

  ::after {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;

export const Label = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  margin: 0;
`;

export const Logo = styled.img`
  width: 100%;
`;

export const LotName = styled.h4`
  color: ${NewColors.Black};
  flex-grow: 1;
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0;
`;

export const Project = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;

// Hexagon clip-path: https://css-tricks.com/hexagons-and-beyond-flexible-responsive-grid-patterns-sans-media-queries/
export const ProjectLogo = styled.div`
  background: ${NewColors.Deadfill};
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  display: grid;
  height: 1.5rem;
  place-items: center;
  width: 1.5rem;
`;

export const ProjectName = styled.p`
  color: ${NewColors.TextSpeak};
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
`;

export const Value = styled.p`
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0;
`;
