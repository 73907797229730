import { useState } from "react";

import { Button } from "@/components/Button";
import { Error } from "@/components/FormElements";
import {
  Modal,
  ModalContent,
  ModalCopy,
  smallModalStyle,
} from "@/components/Modal";

import { useChannel } from "../../../../hooks/useChannel";

interface Props {
  channelSlug: string;
  handleClose(): void;
  isOpen: boolean;
  teamMemberId: string;
  walletAddress: string;
}

export const RemoveModal = ({
  channelSlug,
  handleClose,
  isOpen,
  teamMemberId,
  walletAddress,
}: Props) => {
  const { removeTeamMember } = useChannel(channelSlug);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const removeMember = () => {
    setError(false);
    setSubmitting(true);

    removeTeamMember({
      variables: { teamMemberId },
      onCompleted: ({ removeTeamMemberFromChannel: { errors } }) => {
        const hasErrors = !!errors?.length;

        setError(hasErrors);
        setSubmitting(!hasErrors);

        if (!hasErrors) handleClose();
      },
      onError: () => {
        setError(true);
        setSubmitting(false);
      },
    });
  };

  return (
    <Modal
      customStyles={smallModalStyle}
      handleClose={handleClose}
      isOpen={isOpen}
      title="Remove team member"
    >
      <ModalContent>
        <ModalCopy>
          Are you sure you wish to remove <strong>{walletAddress}</strong> from
          the team?
        </ModalCopy>

        <div>
          <Button disabled={submitting} onClick={removeMember} variant="red">
            {submitting ? "Removing…" : "Remove team member"}
          </Button>
        </div>

        {error && <Error>❌ Something went wrong, please try again!</Error>}
      </ModalContent>
    </Modal>
  );
};
