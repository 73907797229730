import {
  Container,
  Content,
  Detail,
  Details,
  Image,
  ImageContainer,
  Label,
  Logo,
  LotName,
  Project,
  ProjectLogo,
  ProjectName,
  Value,
} from "./styles";

// TODO: Rename this

interface LotCardProps {
  border?: boolean;
  showDetails?: boolean;
  name?: string;
}

export const LotCard = ({
  border = true,
  name = "Placeholder",
  showDetails = true,
}: LotCardProps) => {
  const logo = true;

  return (
    <Container border={border}>
      <ImageContainer>
        <Image
          alt=""
          src="https://img.grail-dev.live/auctions/lot/photo/901E2D4DAF4DDC73E3D5873220BA3250/4aafb18dca0fbe0fbb73bb6ba968ca44.gif"
        />
      </ImageContainer>

      <Content>
        <Project>
          <ProjectLogo>
            {logo && (
              <Logo
                alt=""
                src="https://marvel-live.freetls.fastly.net/canvas/2022/9/e895c7ee734a47999607d83747f374f4?quality=95"
              />
            )}
          </ProjectLogo>
          <ProjectName>Abstracto</ProjectName>
        </Project>

        <LotName>{name}</LotName>

        {showDetails && (
          <Details>
            <Detail>
              <Label>Opening Price</Label>
              <Value>0.25 ETH</Value>
            </Detail>

            <Detail>
              <Label>Bidding ends at</Label>
              <Value>Sep 8th, 8:05pm ET</Value>
            </Detail>
          </Details>
        )}
      </Content>
    </Container>
  );
};
