import { gql } from "@apollo/client";

export const GET_ACTIVE_COUNT = gql`
  query GET_ACTIVE_COUNT {
    auctionsLive: auctions(state: ["LIVE"]) {
      totalCount
    }

    auctionsPublished: auctions(state: ["PUBLISHED"]) {
      totalCount
    }

    auctionsPast: auctions(state: ["ENDED", "CANCELED"]) {
      totalCount
    }
  }
`;
