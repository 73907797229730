import styled from "styled-components";

import { Section } from "@/components/Section";
import background from "@/images/backgrounds/section-bg.png";
import { Breakpoints, CONTENT_WIDTH } from "@/themes/Default";

export const Container = styled(Section)`
  background-image: linear-gradient(135deg, #0f1e51 0%, #53216d 100%);

  ::after {
    background-image: url(${background});
    background-size: 100%;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.1;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
`;

export const LiveContainer = styled.div`
  margin-bottom: 1.75rem;
`;

export const LiveCopy = styled.p`
  color: white;
  margin: 0;
`;

export const LiveHeading = styled.h2`
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const NoReplay = styled.p`
  color: white;
  margin: 0;
`;

export const VideoContainer = styled.div`
  margin: 0 -1.5rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    margin: 0 -3rem;
  }

  @media (min-width: ${Breakpoints.Large}) {
    margin: 0;
  }
`;

export const WaitingContainer = styled.div`
  background-color: rgb(0 6 17 / 40%);
  border-radius: 0.75rem;
  box-shadow: inset 0 0 8px 0 rgb(4 13 28 / 10%);
  color: rgb(255 255 255 / 75%);
  margin: 0 auto;
  max-width: 43.5rem;
  padding: 2.5rem 1.5rem 3rem;
  text-align: center;
  width: 90%;
`;

export const WaitingCopy = styled.p`
  color: inherit;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 1rem 0 0;
`;

export const WaitingHeading = styled.h2`
  color: inherit;
  font-size: 1.25rem;
  line-height: 1.6rem;
  margin: 0;

  @media (min-width: 32rem) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  span {
    color: white;
    display: block;
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 2rem;
    margin: 1rem 0 0;

    @media (min-width: 32rem) {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }
`;

export const Wrapper = styled.div`
  max-width: ${CONTENT_WIDTH};
  position: relative;
  width: 100%;
  z-index: 2;
`;
