import { useMemo } from "react";

import { LoadingBoundary } from "@/components/LoadingBoundary";
import { LiveVideoSection } from "@/features/events/";
import {
  AuctionState,
  BoxBreakState,
  CryptoCurrencies,
} from "@/generated/schema";
import { Colors } from "@/themes/Default";

import { AuctionVideoPanel, useAuctions } from "../../../nftAuctions";
import { RemintVideoPanel, useRemints } from "../../../remints";

import { Grid } from "./styles";

export const CurrentlyLive = () => {
  const {
    auctions,
    error: auctionsError,
    loading: auctionsLoading,
  } = useAuctions({
    variables: {
      orderBy: "scheduled_start_time_desc,id",
      state: [AuctionState.LIVE],
    },
  });

  const {
    remints,
    error: remintsError,
    loading: remintsLoading,
  } = useRemints({
    variables: {
      orderBy: "scheduled_start_time_desc,id",
      state: [BoxBreakState.LIVE_LOCKED, BoxBreakState.LIVE_OPEN],
    },
  });

  const entries = useMemo(() => {
    return [...auctions, ...remints]
      .filter(
        (event) =>
          event?.node?.youtubeVideoId || event?.node?.twitchChannelName,
      )
      .map((event) => {
        if (event?.__typename === "BoxBreakNodeEdge") {
          return (
            <RemintVideoPanel
              key={event?.node?.id}
              currency={event.node?.spotsCurrency as CryptoCurrencies}
              minPrice={event.node?.spotsMinPrice}
              slug={event.node?.slug!}
              spotAvailable={event.node?.spotsAvailableCount!}
              spotTotal={event.node?.spotsTotalCount!}
              title={event?.node?.name!}
              twitchChannelName={event?.node?.twitchChannelName}
              youtubeVideoId={event?.node?.youtubeVideoId}
            />
          );
        }

        return (
          <AuctionVideoPanel
            key={event?.node?.id}
            currency={event?.node?.currency!}
            description={event?.node?.description}
            lotCount={event?.node?.lotCount}
            openingPrice={event?.node?.lotMinOpeningPrice}
            slug={event?.node?.slug!}
            title={event?.node?.name!}
            twitchChannelName={event?.node?.twitchChannelName}
            youtubeVideoId={event?.node?.youtubeVideoId}
          />
        );
      });
  }, [auctions, remints]);

  return (
    <LiveVideoSection
      title="What’s on?"
      subtitle="We are LIVE!"
      description="Check out live events that are streaming right now."
    >
      <LoadingBoundary
        error={auctionsError && remintsError}
        loading={auctionsLoading && remintsLoading}
        loadingColor={Colors.Glacier}
      >
        <Grid>{entries}</Grid>
      </LoadingBoundary>
    </LiveVideoSection>
  );
};
