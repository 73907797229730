import { Helmet } from "react-helmet";

import { PageBanner } from "@/components/PageBanner";
import { AuctionState, BoxBreakState } from "@/generated/schema";

import { EventListings } from "../components/EventListings";

export const Past = () => (
  <>
    <Helmet>
      <title>Past Events</title>
    </Helmet>

    <PageBanner
      description="Look over the highlights of our previous auctions, and review past live shows."
      heading="Past Events"
      link={{
        direction: "backwards",
        label: "Back to all events",
        url: "/events",
      }}
    />

    <EventListings
      auctionOptions={{
        variables: {
          first: 12,
          orderBy: "scheduled_start_time_desc,id",
          state: [AuctionState.CANCELED, AuctionState.ENDED],
        },
      }}
      basePath="/events/past"
      remintOptions={{
        variables: {
          first: 12,
          orderBy: "scheduled_start_time_desc,id",
          state: [
            BoxBreakState.CANCELED,
            BoxBreakState.ENDED,
            BoxBreakState.SETTLED,
          ],
        },
      }}
    />
  </>
);
