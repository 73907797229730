import { Navigate, Route, Routes } from "react-router-dom";

import { LoggedIn } from "@/components/LoggedIn";
import { MinimumLayout } from "@/components/MinimumLayout";

import { Discord } from "./Discord";
import { Edit } from "./Edit";

export const AccountRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate replace to="/" />} />
    <Route
      path="/edit"
      element={
        <LoggedIn fallback={<Navigate replace to="/" />}>
          <MinimumLayout>
            <Edit />
          </MinimumLayout>
        </LoggedIn>
      }
    />
    <Route path="/discord" element={<Navigate replace to="/" />} />
    <Route
      path="/discord/:token"
      element={
        <MinimumLayout>
          <Discord />
        </MinimumLayout>
      }
    />
  </Routes>
);
