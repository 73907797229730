import styled from "styled-components";

import { Button } from "@/components/Button";
import { NewColors } from "@/themes/Default";

export const Continue = styled(Button)`
  width: 12rem;
`;

export const ContinueContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: end;
  padding: 2rem;
`;

export const CopyContainer = styled.div`
  padding: 1.5rem 2rem;
`;

export const NFTContainer = styled.div`
  background: ${NewColors.Deadfill};
  position: relative;

  ::before,
  ::after {
    content: "";
    height: 2.5rem;
    opacity: 0.1;
    position: absolute;
    width: 100%;
  }

  ::before {
    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 100%) 0%,
      rgb(0 0 0 / 0%) 100%
    );
    top: 0;
  }

  ::after {
    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 0%) 0%,
      rgb(0 0 0 / 100%) 100%
    );
    bottom: 0;
  }
`;

export const NFTGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  padding: 2rem;
`;

export const NFTScrollList = styled.div`
  max-height: 45rem;
  min-height: 10rem;
  overflow: auto;
`;

export const Selected = styled.p`
  color: ${NewColors.TextSpeak};
  line-height: 1.25rem;
  margin: 0;
`;

export const SelectedName = styled.span`
  color: ${NewColors.Green};
  opacity: 0.8;
`;
