import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Colors, NewColors } from "../../../../../../themes/Default";

export const Container = styled(Link)`
  align-items: center;
  background-color: white;
  color: ${NewColors.Purple};
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  font-weight: bold;
  gap: 0.75rem;
  justify-content: center;
  line-height: 1.25rem;
  max-width: 10rem;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${NewColors.Purple};
  height: 1.5rem !important;
  width: 1.5rem !important;
`;

export const IconWrapper = styled.div`
  align-items: center;
  background-color: ${Colors.Glacier};
  border-radius: 0.5rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  width: 3rem;
`;
