import type { FormEvent } from "react";

import {
  getWalletByProvider,
  MissingWalletNotice,
  useConnect,
  useWallet,
  WalletProvider,
} from "@/features/wallet";
import { LotCurrency } from "@/generated/schema";
import { useIsLoggedIn } from "@/hooks/useIsLoggedIn";

import { usePlaceBid } from "../../hooks/usePlaceBid";
import { LotHeader } from "../LotHeader";
import { LotStatus } from "../LotStatus";

import { Feedback } from "./components";
import {
  Bid,
  BidEntry,
  BidLabel,
  BidLabelContainer,
  Currency,
  FeeNotice,
  Input,
  InputWrapper,
  Submit,
  Wrapper,
} from "./styles";

interface Props {
  id: string;
}

export const PlaceBid = ({ id }: Props) => {
  const { isLoggedIn } = useIsLoggedIn();

  const {
    bid,
    bidAccepted,
    bidMessageTimeout,
    errors,
    handleSubmit,
    lot,
    setBid,
    setValidBid,
    submitting,
    validBid,
  } = usePlaceBid(id);

  const auctionProvider =
    lot.currency === LotCurrency.ETH
      ? WalletProvider.METAMASK
      : WalletProvider.PHANTOM;

  const { connectedWalletIsWrong, handleConnect, userHasWalletExtension } =
    useConnect(auctionProvider);

  const { chain } = getWalletByProvider(auctionProvider);
  const { balances } = useWallet(chain);
  const balance = balances?.length && balances[0];
  const balanceTooLow =
    !balance || parseFloat(balance.balance) < parseFloat(bid);
  const balanceString = balance ? `${balance.balance} ${balance.currency}` : ``;

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const parsedBid = parseFloat(value);
    const validBid = !(
      isNaN(parsedBid) ||
      !balance ||
      parseFloat(balance.balance) < parsedBid ||
      (lot.minimumNextBidPrice && lot.minimumNextBidPrice > parsedBid)
    );

    setValidBid(validBid);
    setBid(value);
  };

  const placeBidMessage = [
    `You'll need a ${auctionProvider} wallet to place bids in this auction. Learn more about ${auctionProvider} and connect below.`,
  ];

  const incorrectWalletMessage = [
    `Please disconnect your current crypto wallet and connect with a ${auctionProvider} wallet to bid in this auction.`,
  ];

  const setCustomMessage = () => {
    if (userHasWalletExtension) return placeBidMessage;
    if (connectedWalletIsWrong) return incorrectWalletMessage;
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit();
  };

  if (!isLoggedIn) {
    return (
      <>
        <Wrapper>
          <LotHeader id={id}>
            <LotStatus id={id} insideModal={true} />
          </LotHeader>
        </Wrapper>

        <Wrapper>
          <MissingWalletNotice
            auctionProvider={auctionProvider}
            customMessage={setCustomMessage()}
            connect={handleConnect}
            userHasWalletExtension={userHasWalletExtension}
          />
        </Wrapper>
      </>
    );
  }

  if (connectedWalletIsWrong) {
    return (
      <>
        <Wrapper>
          <LotHeader id={id}>
            <LotStatus id={id} insideModal={true} />
          </LotHeader>
        </Wrapper>

        <Wrapper>
          <MissingWalletNotice
            auctionProvider={auctionProvider}
            connect={handleConnect}
            customMessage={setCustomMessage()}
            title={"You've got the wrong wallet connected!"}
            userHasWalletExtension={userHasWalletExtension}
          />
        </Wrapper>
      </>
    );
  }

  return (
    <>
      <Wrapper>
        <LotHeader id={id}>
          <LotStatus id={id} insideModal={true} />
        </LotHeader>
      </Wrapper>

      <Wrapper>
        <Bid>
          {lot.isAcceptingBids && (
            <BidEntry onSubmit={onSubmit}>
              <BidLabelContainer>
                <BidLabel htmlFor="bid">Price</BidLabel>
                <FeeNotice>
                  A {lot.buyersPremiumPercentage! * 100}% fee will be added as a
                  buyer premium.
                </FeeNotice>
              </BidLabelContainer>

              <InputWrapper>
                <Currency>{lot.currency}</Currency>
                <Input
                  id="bid"
                  min={lot.minimumNextBidPrice ?? 0}
                  onChange={handleChange}
                  placeholder="Enter bid amount"
                  step={0.01}
                  type="number"
                  value={bid}
                />
              </InputWrapper>

              <Submit
                disabled={!validBid || submitting}
                type="submit"
                variant="purple"
              >
                {submitting ? "Submitting" : "Submit"}
              </Submit>
            </BidEntry>
          )}

          <Feedback
            acceptingBids={lot.isAcceptingBids}
            balance={balanceString}
            balanceTooLow={balanceTooLow}
            bidMessageTimeout={bidMessageTimeout}
            bidAccepted={bidAccepted}
            endTime={lot.biddingEndsAfter}
            minNextBid={lot.formattedPrices.minimumNextBid}
            errors={errors}
          />
        </Bid>
      </Wrapper>
    </>
  );
};
