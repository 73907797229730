import { StyledMarkdown } from "./styles";

interface Props {
  children: string;
  className?: string;
  disallowedElements?: string[];
}

export const Markdown = ({
  children,
  className,
  disallowedElements = ["br", "h1", "h2", "h3", "h4", "h5", "h6", "hr", "img"],
}: Props) => (
  <StyledMarkdown
    className={className}
    disallowedElements={disallowedElements}
    linkTarget="_blank"
  >
    {children}
  </StyledMarkdown>
);
