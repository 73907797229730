import {
  faArrowRight,
  faComment,
  faEdit,
  faQuestion,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useRef, useState } from "react";

import { Wallet, WalletProvider } from "@/features/wallet";
import { useIsLoggedIn } from "@/hooks/useIsLoggedIn";
import { Colors } from "@/themes/Default";

import { Login } from "./components";
import {
  Category,
  Contents,
  Heading,
  LinkIcon,
  Menu,
  Navigation,
  NavLink,
  Section,
  ToggleButton,
  UserIcon,
  Wrapper,
} from "./styles";

export const AccountMenu = () => {
  const { isLoggedIn } = useIsLoggedIn();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);

  const handleClick = () => setShow(!show);

  const handleEscape = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === "Escape" && show) {
        setShow(false);
        buttonRef?.current?.focus();
      }
    },
    [show],
  );

  const handleOutsideClick = useCallback(
    ({ target }: MouseEvent) => {
      const clickedOutside =
        !buttonRef?.current?.contains(target as Node) &&
        !menuRef?.current?.contains(target as Node);
      const modalClosed = !document.body.classList.contains(
        "ReactModal__Body--open",
      );
      const shouldCloseModal = clickedOutside && modalClosed && show;

      if (shouldCloseModal) {
        setShow(false);
        buttonRef?.current?.focus();
      }
    },
    [show],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleEscape, handleOutsideClick]);

  return (
    <Wrapper>
      <ToggleButton
        aria-label="Account menu"
        onClick={handleClick}
        ref={buttonRef}
      >
        <UserIcon color="white" icon={faUser} />
      </ToggleButton>

      <Menu hidden={!show} ref={menuRef}>
        {isLoggedIn ? (
          <>
            <Heading>Welcome!</Heading>

            <Contents>
              <Wallet provider={WalletProvider.METAMASK} />
            </Contents>
          </>
        ) : (
          <Login />
        )}

        <Navigation>
          {isLoggedIn && (
            <Section>
              <Category>Account</Category>

              <NavLink to={"/account/edit"}>
                <LinkIcon icon={faEdit} color={Colors.Blue} />
                <span>Edit Profile</span>
              </NavLink>
            </Section>
          )}

          <Section>
            <Category>Support</Category>

            <NavLink to={"/request-an-auction"}>
              <LinkIcon icon={faArrowRight} color={Colors.Blue} />
              <span>Request an auction</span>
            </NavLink>

            <NavLink to="/faq">
              <LinkIcon icon={faQuestion} color={Colors.Blue} />
              <span>Frequently asked questions</span>
            </NavLink>

            <NavLink to="/support">
              <LinkIcon icon={faComment} color={Colors.Blue} />
              <span>Contact us</span>
            </NavLink>
          </Section>
        </Navigation>
      </Menu>
    </Wrapper>
  );
};
