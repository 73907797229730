import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import type { ReactNode } from "react";
import { Link } from "react-router-dom";

import { AccountMenu } from "@/features/account";

import { Footer } from "../Footer";
import { SkipToContent } from "../SkipToContent";

import {
  Container,
  Controls,
  Menu,
  NavItem,
  StyledLink,
  StyledLogo,
  StyledIcon,
  TopBar,
  TopBarWrapper,
} from "./styles";

interface Props {
  children: ReactNode;
}

// This is a temporary Layout component designed for the basic MVP.
export const MinimumLayout = ({ children }: Props) => (
  <Container>
    <TopBar>
      <TopBarWrapper>
        <Link aria-label="Grail" to="/">
          <StyledLogo />
        </Link>

        <SkipToContent />

        <Controls>
          <NavItem>
            <StyledLink to="/">
              <StyledIcon icon={faCalendar} />
              Events
            </StyledLink>
          </NavItem>

          <Menu>
            <AccountMenu />
          </Menu>
        </Controls>
      </TopBarWrapper>
    </TopBar>

    <main id="content">{children}</main>

    <Footer />
  </Container>
);
