import { unix } from "moment";

import { DELETE_COOKIES_MUTATION } from "../api/mutations/DELETE_COOKIES";

const ACCESS_TOKEN_EXPIRY_KEY = "accessTokenExp";
const REFRESH_TOKEN_EXPIRY_KEY = "refreshTokenExp";

export const setAccessTokenExpiry = (value: string) => {
  localStorage.setItem(ACCESS_TOKEN_EXPIRY_KEY, value);
};

export const hasValidAccessToken = () => {
  const expiry = localStorage.getItem(ACCESS_TOKEN_EXPIRY_KEY);

  if (expiry) {
    // Check that the expiry is after now
    return unix(parseFloat(expiry)).isAfter();
  }

  // Not set
  return false;
};

export const setRefreshTokenExpiry = (value: string) => {
  localStorage.setItem(REFRESH_TOKEN_EXPIRY_KEY, value);
};

export const hasValidRefreshToken = () => {
  const expiry = localStorage.getItem(REFRESH_TOKEN_EXPIRY_KEY);

  if (expiry) {
    // Check that the expiry is after now
    return unix(parseFloat(expiry)).isAfter();
  }

  // Not set
  return false;
};

export const removeTokens = () => {
  if (process.env.REACT_APP_API_ENDPOINT) {
    fetch(process.env.REACT_APP_API_ENDPOINT, {
      method: "POST",
      body: DELETE_COOKIES_MUTATION(),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  }
};
