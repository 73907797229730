import { gql } from "@apollo/client";

export const UPDATE_LOT_STATE_TO_COMPLETING_SALE = gql`
  mutation updateLotStateToCompletingSale($lotId: ID!) {
    updateLotStateToCompletingSale(lotId: $lotId) {
      errors {
        field
        message
      }
    }
  }
`;
