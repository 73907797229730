import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Modal } from "@/components/Modal";
import { ResponsiveImage } from "@/components/ResponsiveImage";
import { logAmplitudeEvent } from "@/utils/analytics";
import { AUCTIONS_EVENTS } from "@/utils/analytics/events";

import { useLot } from "../../hooks/useLot";
import { LotDetails } from "../LotDetails";
import { LotPayment } from "../LotPayment";
import { PlaceBid } from "../PlaceBid";

import { BiddingPanel } from "./component/BiddingPanel";
import {
  Container,
  Description,
  Details,
  ExternalLink,
  Image,
  Information,
  LotHeading,
  modalStyles,
  Name,
  Project,
} from "./styles";

interface Props {
  id: string;
  auctionAnalytics?: {};
}

export const LotCard = ({ id, auctionAnalytics }: Props) => {
  const { lot, lotAnalytics } = useLot(id);
  const { lotId } = useParams();
  const cardRef = useRef<HTMLDivElement>(null);
  const [showClaimItem, setShowClaimItem] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showPlaceBid, setShowPlaceBid] = useState(false);

  const scrollToComponent = lotId && lotId === lot.id;

  const closeClaimItem = () => setShowClaimItem(false);
  const openClaimItem = () => setShowClaimItem(true);

  const closeDetails = () => setShowDetails(false);
  const openDetails = () => {
    logAmplitudeEvent(AUCTIONS_EVENTS.LOT.DETAIL_VIEWED, {
      ...auctionAnalytics,
      ...lotAnalytics,
    });
    setShowDetails(true);
  };

  const closePlaceBid = () => setShowPlaceBid(false);
  const openPlaceBid = () => {
    logAmplitudeEvent(AUCTIONS_EVENTS.BID.FORM_VIEWED, {
      ...auctionAnalytics,
      ...lotAnalytics,
    });
    setShowPlaceBid(true);
  };

  useEffect(() => {
    if (scrollToComponent) {
      cardRef?.current?.scrollIntoView({
        block: "center",
      });

      setShowDetails(true);
    }
  }, [scrollToComponent, setShowDetails]);

  return (
    <>
      {showClaimItem && (
        <Modal
          handleClose={closeClaimItem}
          isOpen={showClaimItem}
          title="Claim item"
          customStyles={modalStyles}
        >
          <LotPayment id={id} />
        </Modal>
      )}

      {showDetails && (
        <Modal
          handleClose={closeDetails}
          isOpen={showDetails}
          title="Lot Details"
          customStyles={modalStyles}
        >
          <LotDetails
            id={id}
            handlePlaceBid={() => {
              closeDetails();
              openPlaceBid();
            }}
          />
        </Modal>
      )}

      {showPlaceBid && (
        <Modal
          handleClose={closePlaceBid}
          isOpen={showPlaceBid}
          title="Place bid"
          customStyles={modalStyles}
        >
          <PlaceBid id={id} />
        </Modal>
      )}

      <Container ref={cardRef}>
        <Information>
          <Image>
            <ResponsiveImage
              alt={`${lot.collection}, ${lot.name}`}
              height={1}
              src={lot.image}
              width={1}
            />
          </Image>

          <LotHeading>
            <Project>{lot.collection}</Project>
            <Name>{lot.name}</Name>
          </LotHeading>

          <Details>
            <Description>{lot.description}</Description>

            {lot.externalLink && (
              <ExternalLink
                href={lot.externalLink}
                icon={faUpRightFromSquare}
                rel="noopener noreferrer"
                target="_blank"
                variant="white"
              >
                View token
              </ExternalLink>
            )}
          </Details>
        </Information>

        <BiddingPanel
          id={id}
          showClaimItem={openClaimItem}
          showDetails={openDetails}
          showPlaceBid={openPlaceBid}
        />
      </Container>
    </>
  );
};
