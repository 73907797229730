import {
  AuctionLotBidExtensionPeriod,
  AuctionLotInterval,
} from "../generated/schema";

export const generateTimeLabel = (
  timeEnum: AuctionLotBidExtensionPeriod | AuctionLotInterval,
  zeroMessage: string,
) => {
  const time = parseFloat(timeEnum.replace("A_", ""));

  if (Number.isNaN(time) || time === 0) return zeroMessage;

  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;

  const secondsLabel = seconds > 1 ? "seconds" : "second";
  const minutesLabel = minutes > 1 ? "minutes" : "minute";

  if (minutes <= 0) return `${seconds} ${secondsLabel}`;
  if (minutes > 0 && seconds > 0)
    return `${minutes} ${minutesLabel} ${seconds} ${secondsLabel}`;

  return `${minutes} ${minutesLabel}`;
};
