import { useQuery } from "@apollo/client";

import type { NFTItemNode } from "@/generated/schema";

import { GET_NFT } from "../api/GET_NFT";

interface QueryResponse {
  nftItem: NFTItemNode
}


export const useNft = (id: string | undefined) => {
  const { data, error, loading } = useQuery<QueryResponse>(
    GET_NFT, { variables: { id } },
  );

  return {
    nftDetails: data?.nftItem,
    error: !!error,
    loading,
  };
};
