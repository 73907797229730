import { gql } from "@apollo/client";

export const GET_USERS_NFTS = gql`
  query GET_USERS_NFTS($ownerAddress: String!) {
    importNftItemsForOwnerAddress(ownerAddress: $ownerAddress) {
      edgeCount

      edges {
        node {
          id
          importedImageUri
          name

          collection {
            id
            name
          }
        }
      }
    }
  }
`;
