import { gql } from "@apollo/client";

export const AUCTION_EDIT = gql`
  mutation AUCTION_EDIT(
    $auctionId: ID!
    $description: String
    $name: String
    $scheduledStartTime: DateTime
    $twitchChannelName: String
    $twitchVideoId: String
    $youtubeVideoId: String
  ) {
    auctionDraftEdit(
      auctionId: $auctionId
      description: $description
      name: $name
      scheduledStartTime: $scheduledStartTime
      twitchChannelName: $twitchChannelName
      twitchVideoId: $twitchVideoId
      youtubeVideoId: $youtubeVideoId
    ) {
      errors {
        message
      }
    }
  }
`;
