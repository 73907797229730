import styled from "styled-components";

import { NewColors } from "@/themes/Default";

import { Capsule } from "../Capsule";

export const Container = styled(Capsule)`
  grid-area: auction-lots;
`;

export const Details = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
`;

export const EmptyList = styled.button`
  background: white;
  border: 2px dashed rgb(31 31 35 / 20%);
  border-radius: 0.5rem;
  color: ${NewColors.Green};
  display: grid;
  font-weight: bold;
  min-height: 10rem;
  place-content: center;
  width: 100%;
`;
