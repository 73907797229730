import { useQuery } from "@apollo/client";
import type { ReactNode } from "react";

import { IS_LOGGED_IN } from "@/api/queries/IS_LOGGED_IN";

interface Props {
  additionalChecks?: boolean;
  children: ReactNode;
  fallback: ReactNode;
}

export const LoggedIn = ({
  additionalChecks = true,
  children,
  fallback = null,
}: Props) => {
  const { data: loginData } = useQuery(IS_LOGGED_IN);
  const canSeeChildren = additionalChecks && loginData && loginData.isLoggedIn;

  return <>{canSeeChildren ? children : fallback}</>;
};
