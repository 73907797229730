import { faMoneyBillWaveAlt } from "@fortawesome/free-solid-svg-icons";
import type { IconDefinition } from "@fortawesome/free-solid-svg-icons";

import { AnchorButton, Button } from "@/components/Button";
import { InfoNotice } from "@/components/InfoNotice";

import { WalletProvider } from "../../types";
import { Wallets } from "../../utils";

import { ButtonContainer } from "./styles";

interface Props {
  auctionProvider: WalletProvider;
  connect(): void;
  customMessage?: string[] | undefined;
  icon?: IconDefinition;
  title?: string;
  userHasWalletExtension?: boolean;
}

export const MissingWalletNotice = ({
  auctionProvider,
  connect,
  customMessage,
  icon = faMoneyBillWaveAlt,
  title = "We weren't able to find your crypto wallet",
  userHasWalletExtension = false,
}: Props) => {
  const defaultMessage = [
    "To take part in Grail auctions, you'll need to connect a MetaMask wallet.",
    `If you're having trouble connecting your ${auctionProvider} wallet, please make sure you have the ${auctionProvider} browser extension installed. Visit their website for more information.`,
  ];
  const link = Wallets[auctionProvider].externalUrl;

  return (
    <>
      <InfoNotice
        icon={icon}
        message={customMessage ?? defaultMessage}
        state="neutral"
        title={title}
      />

      <ButtonContainer>
        <AnchorButton href={link} target="_blank" rel="noopener">
          Learn more about {auctionProvider}
        </AnchorButton>

        {userHasWalletExtension && (
          <Button variant="purple" onClick={connect}>
            Connect Wallet
          </Button>
        )}
      </ButtonContainer>
    </>
  );
};
