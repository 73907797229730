import { KeyboardEvent, MouseEvent as ReactMouseEvent } from "react";

import { Button } from "@/components/Button";
import {
  Modal,
  ModalButtonContainer,
  ModalContent,
  ModalCopy,
  smallModalStyle,
} from "@/components/Modal";

interface DeleteModalProps {
  handleToggle(
    event?: KeyboardEvent<Element> | ReactMouseEvent<Element, MouseEvent>,
  ): void;
  show: boolean;
}

export const DeleteModal = ({ handleToggle, show }: DeleteModalProps) => {
  const handleSubmit = () => alert("To be implemented...");

  return (
    <Modal
      customStyles={smallModalStyle}
      handleClose={handleToggle}
      isOpen={show}
      title="Delete lot"
    >
      <ModalContent>
        <ModalCopy>
          Are you sure? Once the lot is deleted, it cannot be restored.
        </ModalCopy>

        <ModalButtonContainer>
          <Button onClick={handleSubmit} variant="red">
            Yes, delete lot
          </Button>
        </ModalButtonContainer>
      </ModalContent>
    </Modal>
  );
};
