import styled, { keyframes } from "styled-components";

import { Colors } from "../../../../../../themes/Default";

const animateMessageIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }

  10% {
    opacity: 1;
    transform: translateY(0);
  }

  90% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
`;

export const Info = styled.p`
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0;
`;

export const AnimatedInfo = styled(Info)`
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-name: ${animateMessageIn};
  animation-timing-function: ease-in-out;
  color: ${Colors.Blue};
`;

export const ErrorInfo = styled(AnimatedInfo)`
  color: #b22415;
`;
