import { gql } from "@apollo/client";

export const GET_AUCTIONS = gql`
  query GET_AUCTIONS(
    $cursor: String
    $first: Int = 48
    $orderBy: String
    $query: String
    $state: [String]
  ) {
    auctions(
      after: $cursor
      first: $first
      orderBy: $orderBy
      query: $query
      state: $state
    ) {
      edges {
        node {
          id
          currency
          description
          featuredRank
          lotCount
          lotMinOpeningPrice
          name
          slug
          state
          scheduledStartTime
          twitchChannelName
          youtubeVideoId

          heroImage {
            url
          }
        }
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
