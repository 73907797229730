import styled from "styled-components";

import { Colors } from "@/themes/Default";

export const Buttons = styled.div`
  display: grid;
  grid-gap: 0.75rem;
  margin-top: 1.5rem;
`;

export const Container = styled.div`
  border-bottom: 1px solid ${Colors.Glacier};
  padding: 1.5rem 1rem;
`;
