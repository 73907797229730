import { EventCard, PriceLabel, Price } from "../../../../components/EventCard";
import { BoxBreakState } from "../../../../generated/schema";
import type { CryptoCurrencies } from "../../../../generated/schema";
import { formatCurrency } from "../../../../utils/getCurrency";

interface Props {
  currency: CryptoCurrencies;
  featuredRank?: number | null;
  image?: string | null;
  minPrice?: number | null;
  scheduledStartTime: Date;
  slug: string;
  spotAvailable: number;
  spotTotal: number;
  state: BoxBreakState;
  title: string;
}

export const RemintCard = ({
  currency,
  featuredRank = null,
  image,
  minPrice,
  scheduledStartTime,
  slug,
  spotAvailable,
  spotTotal,
  state,
  title,
}: Props) => {
  const price = minPrice ? formatCurrency(minPrice, currency) : "Coming soon…";
  const isFeatured =
    featuredRank !== null &&
    [
      BoxBreakState.LIVE_OPEN,
      BoxBreakState.LIVE_LOCKED,
      BoxBreakState.PUBLISHED_OPEN,
      BoxBreakState.PUBLISHED_LOCKED,
    ].includes(state);

  return (
    <EventCard
      currency={currency}
      imageUrl={image}
      info={`${spotAvailable}/${spotTotal} spots available`}
      isFeatured={isFeatured}
      link={`/remints/${slug}`}
      scheduledStartTime={scheduledStartTime}
      state={state}
      STATES={BoxBreakState}
      subtitle="Random NFT"
      title={title}
    >
      <EventCard.LowerComponents>
        <PriceLabel>
          Price per spot <Price>{price}</Price>
        </PriceLabel>
      </EventCard.LowerComponents>
    </EventCard>
  );
};
