import { useMoralis } from "react-moralis";

import { WalletProvider } from "@/features/wallet";

export const useMoralisUser = () => {
  const { Moralis, isInitialized } = useMoralis();

  let currentMoralisUserProvider;
  let currentMoralisUser;

  if (isInitialized) {
    currentMoralisUser = Moralis.User.current();

    if (currentMoralisUser?.attributes.solAddress) {
      currentMoralisUserProvider = WalletProvider.PHANTOM;
    } else if (currentMoralisUser?.attributes.ethAddress) {
      currentMoralisUserProvider = WalletProvider.METAMASK;
    }
  }

  return {
    currentMoralisUser,
    currentMoralisUserProvider,
  };
};
