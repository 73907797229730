import styled from "styled-components";

import { TimeLeft } from "@/components/TimeLeft";
import { NewColors } from "@/themes/Default";

import { Capsule } from "../Capsule";

export const Address = styled.span`
  color: ${NewColors.Black};
`;

export const Container = styled(Capsule)`
  grid-area: auction-live-show;
`;

export const InlineLink = styled.a`
  color: ${NewColors.Green};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  outline-offset: 0.5rem;

  :active,
  :focus,
  :hover {
    color: ${NewColors.Green};
    text-decoration: none;
  }
`;

export const Spacer = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;

export const StyledTimeLeft = styled(TimeLeft)`
  font-size: 1rem;
  font-weight: 400;
`;
