import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SOCIAL_LINKS } from "@/settings";

import {
  Container,
  Contents,
  Copyright,
  InternalLink,
  InternalLinks,
  InternalLinkWrapper,
  InternalSpacer,
  SocialLink,
  SocialLinks,
} from "./styles";

const internalLinks = [
  // {
  //   name: "Careers",
  //   path: "/careers",
  // },
  {
    name: "Terms",
    path: "/terms",
  },
  {
    name: "Privacy",
    path: "/privacy-policy",
  },
  {
    name: "FAQs",
    path: "/faq",
  },
];

const socialLinks = [
  {
    name: "Discord",
    icon: faDiscord,
    url: SOCIAL_LINKS.Discord,
  },
  {
    name: "Twitter",
    icon: faTwitter,
    url: SOCIAL_LINKS.Twitter,
  },
];

export const Footer = () => {
  return (
    <Container>
      <Contents>
        <Copyright>Copyright © 2022 Grail.live. All rights reserved</Copyright>

        <InternalLinks>
          {internalLinks.map(({ name, path }) => (
            <InternalLinkWrapper key={name}>
              <InternalSpacer>•</InternalSpacer>
              <InternalLink key={name} to={path}>
                {name}
              </InternalLink>
            </InternalLinkWrapper>
          ))}
        </InternalLinks>

        <SocialLinks>
          {socialLinks.map(({ icon, name, url }) => (
            <SocialLink
              href={url}
              key={name}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={icon} />
            </SocialLink>
          ))}
        </SocialLinks>
      </Contents>
    </Container>
  );
};
