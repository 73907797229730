import { gql } from "@apollo/client";

export const LOT_DETAILS = gql`
  fragment lotDetails on LotNode {
    id
    biddingEndsAfter
    buyersPremiumFee
    buyersPremiumPercentage
    currency
    description
    finalPurchasePrice
    highestBidPrice
    minimumNextBidPrice
    name
    nftCollectionName
    nftExternalUrl
    nftApprovalState
    openingPrice
    sellerWalletAddress
    scheduledStartTime
    state
    smartContractLotId

    auction {
      slug
    }

    bidSet {
      edges {
        node {
          id
          bidPrice
          bidPriceCurrency
          createdAt
          state

          user {
            id
            username
          }
        }
      }
    }

    highestBid {
      id
      bidPrice
      bidPriceCurrency
      createdAt
      state

      user {
        id
        username
      }
    }

    lotphotoSet {
      edges {
        node {
          photoMd
        }
      }
    }

    nftItem {
      tokenAddress
      tokenId
      tokenType
    }
  }
`;
