import { gql } from "@apollo/client";

import { LOT_DETAILS } from "./fragments/LOT";

export const GET_AUCTION = gql`
  ${LOT_DETAILS}

  query GET_AUCTION($id: ID, $slug: String) {
    auction(id: $id, slug: $slug) {
      id
      description
      name
      pusherChannelId
      scheduledStartTime
      slug
      state
      twitchChannelName
      twitchVideoId
      youtubeVideoId

      channel {
        name
        slug
      }

      heroImage {
        url
      }

      lotSet {
        edges {
          node {
            ...lotDetails
          }
        }
      }
    }
  }
`;
