import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { Gradients, NewColors } from "@/themes/Default";

export const Channel = styled(Link)<{ $active?: boolean }>`
  align-items: center;
  background: white;
  border-radius: 0.375rem;
  color: ${NewColors.TextShout};
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 3rem 1fr;
  padding: 0.25rem;

  ${({ $active }) =>
    $active &&
    css`
      background: ${NewColors.Purple};
      color: white;
    `}

  :active,
  :focus,
  :hover {
    background: rgb(132 106 227 / 20%);
    color: ${NewColors.TextShout};
    text-decoration: none;

    ${({ $active }) =>
      $active &&
      css`
        background: ${NewColors.Purple};
        color: white;
      `}
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const ImageContainer = styled.div`
  background: ${Gradients.Channels};
  background-color: ${NewColors.Deadfill};
  border-radius: 0.25rem;
  height: 3rem;
  overflow: hidden;
  width: 3rem;
`;

export const Name = styled.p`
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin: 0;
`;
