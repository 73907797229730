import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import DefaultBackground from "../../images/backgrounds/Gradient Logo Watermark.svg";
import { Breakpoints, CONTENT_WIDTH } from "../../themes/Default";

export const Container = styled.section<{ background?: string }>`
  display: grid;
  overflow: hidden;
  padding: 1.5rem 1rem;
  place-items: center;
  position: relative;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 3rem 3rem 3.5rem;
  }

  &::after {
    background: url("${DefaultBackground}");
    background-size: cover;
    content: "";
    height: 100%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    ${({ background }) =>
      background &&
      css`
        background: url(${background});
      `}
  }
`;

export const Content = styled.div<{ reverse?: boolean }>`
  align-items: center;
  color: white;
  display: grid;
  gap: 0.75rem;
  grid-template-areas:
    "heading"
    "description"
    "link";
  max-width: ${CONTENT_WIDTH};
  position: relative;
  width: 100%;
  z-index: 1;

  ${({ reverse }) =>
    !reverse &&
    css`
      @media (min-width: ${Breakpoints.Large}) {
        grid-template-areas:
          "heading heading"
          "description link";
      }
    `}

  ${({ reverse }) =>
    reverse &&
    css`
      grid-template-areas:
        "link"
        "heading"
        "description";
    `}
`;

export const Description = styled.p`
  grid-area: description;
  line-height: 1.5rem;
  margin: 0;

  @media (min-width: ${Breakpoints.Mobile}) {
    font-size: 1.125rem;
  }
`;

export const Heading = styled.h1`
  -webkit-background-clip: text;
  background-color: white;
  background-image: linear-gradient(
    0deg,
    white 25%,
    #ddd 25%,
    #ddd 30%,
    white 30%,
    white 35%,
    #ddd 35%,
    #ddd 40%,
    white 40%
  );
  background-repeat: repeat;
  background-size: 100%;
  display: inline-block;
  font-family: Barlow, Quiksand, sans-serif;
  font-size: 2rem;
  font-style: italic;
  font-weight: bold;
  grid-area: heading;
  line-height: 2.5rem;
  margin: 0;
  position: relative;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;

  @media (min-width: ${Breakpoints.Mobile}) {
    font-size: 3rem;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  height: 1.25rem;
  width: 1.25rem;
`;

export const LinkContainer = styled.div<{ $reverse?: boolean }>`
  grid-area: link;

  ${({ $reverse }) =>
    !$reverse &&
    css`
      @media (min-width: ${Breakpoints.Large}) {
        text-align: right;
      }
    `}
`;

export const StyledLink = styled(Link)<{ $reverse?: boolean }>`
  color: white;
  display: inline-flex;
  gap: 0.5rem;
  padding: 0.25rem 0;
  place-items: center;

  ${({ $reverse }) =>
    $reverse &&
    css`
      flex-direction: row-reverse;
    `}

  :hover {
    color: inherit;
  }
`;
