import styled, { css } from "styled-components";

import { Borders, NewColors } from "@/themes/Default";

interface PillProps {
  isFinished: boolean;
  isLive: boolean;
}

export const Pill = styled.span<PillProps>`
  background: white;
  border: ${Borders.User};
  border-radius: 1rem;
  color: ${NewColors.Purple};
  display: inline-block;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.25;
  padding: 0.375rem 0.75rem;

  ${({ isLive }) =>
    isLive &&
    css`
      background: ${NewColors.Purple};
      color: white;
    `}

  ${({ isFinished }) =>
    isFinished &&
    css`
      background: #4b4b51;
      color: white;
    `}
`;
