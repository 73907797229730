import type { MouseEvent, ReactNode } from "react";

import { Button, Dot } from "./styles";

interface ButtonProps {
  className?: string;
  children: ReactNode;
  enabled?: boolean;
  onClick(event?: MouseEvent<HTMLButtonElement>): void;
}

interface DotButtonProps {
  className?: string;
  label: string;
  onClick(event?: MouseEvent<HTMLButtonElement>): void;
  selected?: boolean;
}

export const DotButton = ({
  className,
  label,
  selected,
  onClick,
}: DotButtonProps) => (
  <Dot
    aria-label={label}
    className={className}
    onClick={onClick}
    selected={selected}
    type="button"
  />
);

export const NextButton = ({
  className,
  children,
  enabled,
  onClick,
}: ButtonProps) => (
  <Button
    aria-label="Next"
    className={className}
    disabled={!enabled}
    onClick={onClick}
  >
    {children}
  </Button>
);

export const PrevButton = ({
  className,
  children,
  enabled,
  onClick,
}: ButtonProps) => (
  <Button
    aria-label="Previous"
    className={className}
    disabled={!enabled}
    onClick={onClick}
  >
    {children}
  </Button>
);
