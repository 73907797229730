import { useMutation } from "@apollo/client";

import { ImageSelect } from "@/components/FormElements";
import type { ImageChangeEvent } from "@/components/FormElements";
import { LoadingBoundary } from "@/components/LoadingBoundary";
import {
  UploadChannelBannerImage,
  UploadChannelProfileImage,
} from "@/generated/schema";

import { UPLOAD_CHANNEL_BANNER } from "../../api/UPLOAD_CHANNEL_BANNER";
import { UPLOAD_CHANNEL_PROFILE } from "../../api/UPLOAD_CHANNEL_PROFILE";
import { useChannel } from "../../hooks/useChannel";

import { Container } from "./styles";

interface BannerMutation {
  uploadChannelBannerImage: UploadChannelBannerImage;
}

interface ProfileMutation {
  uploadChannelProfileImage: UploadChannelProfileImage;
}

interface Props {
  channelSlug: string;
}

export const ManageProfile = ({ channelSlug }: Props) => {
  const { channel, error, loading, refetch } = useChannel(channelSlug);
  const [uploadChannelBannerImage] = useMutation<BannerMutation>(
    UPLOAD_CHANNEL_BANNER,
  );
  const [uploadChannelProfileImage] = useMutation<ProfileMutation>(
    UPLOAD_CHANNEL_PROFILE,
  );

  const uploadBanner = ({
    image: bannerImage,
    onComplete,
  }: ImageChangeEvent) => {
    uploadChannelBannerImage({
      variables: { bannerImage, channelId: channel?.id },
      onCompleted({ uploadChannelBannerImage: { errors } }) {
        const hasErrors = !!errors && !!errors.length;
        onComplete(hasErrors);

        if (!hasErrors) refetch();
      },
    });
  };

  const uploadProfile = ({
    image: profileImage,
    onComplete,
  }: ImageChangeEvent) => {
    uploadChannelProfileImage({
      variables: { channelId: channel?.id, profileImage },
      onCompleted({ uploadChannelProfileImage: { errors } }) {
        const hasErrors = !!errors && !!errors.length;
        onComplete(hasErrors);

        if (!hasErrors) refetch();
      },
    });
  };

  return (
    <LoadingBoundary error={error} loading={loading}>
      <Container>
        <ImageSelect
          description="Square images work best here. 512 x 512 is ideal."
          errorMessage="IMG_ERROR_MESSAGE"
          img={{
            height: 1,
            maxWidth: 8,
            src: channel?.profileImageUrl ?? "",
            width: 1,
          }}
          label="Channel profile picture"
          name="profilePicture"
          onChange={uploadProfile}
          successMessage="Profile picture has been updated!"
        />

        <ImageSelect
          description="Large, wide images work best - we suggest 1400x350."
          errorMessage="IMG_ERROR_MESSAGE"
          img={{
            height: 128,
            src: channel?.bannerImageUrl ?? "",
            width: 512,
          }}
          label="Profile banner"
          name="profileBanner"
          onChange={uploadBanner}
          successMessage="Profile banner has been updated!"
        />
      </Container>
    </LoadingBoundary>
  );
};
