import { useState } from "react";
import type { HTMLInputTypeAttribute } from "react";

import type { Change } from "@/hooks/useForm";

import {
  Container,
  Copy,
  Error,
  Input as StyledInput,
  InputWrapper,
  Label,
} from "../../styles";

interface Props {
  defaultValue?: string;
  description?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  handleInput(e: Change<HTMLInputElement>): void;
  label: string;
  minLength?: number;
  maxLength?: number;
  name: string;
  placeholder?: string;
  type: HTMLInputTypeAttribute;
}

export const Input = ({
  defaultValue,
  description,
  disabled = false,
  error = false,
  errorMessage,
  handleInput,
  label,
  minLength = 0,
  maxLength,
  name,
  placeholder = "",
  type,
}: Props) => {
  const [beenEdited, setBeenEdited] = useState(false);

  const handleEntry = (e: Change<HTMLInputElement>) => {
    if (e.currentTarget.value.length >= minLength) setBeenEdited(true);

    handleInput(e);
  };

  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>

      {description && <Copy>{description}</Copy>}

      <InputWrapper>
        <StyledInput
          defaultValue={defaultValue}
          disabled={disabled}
          error={beenEdited && error}
          id={name}
          name={name}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          onInput={handleEntry}
          type={type}
        />

        {beenEdited && error && <Error>⚠️ {errorMessage}</Error>}
      </InputWrapper>
    </Container>
  );
};
