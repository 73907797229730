import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

import { Button } from "@/components/Button";
import { InfoNotice } from "@/components/InfoNotice";
import { ConnectWalletModal } from "@/features/wallet";

import { Buttons, Container } from "./styles";

export const Login = () => {
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

  return (
    <>
      <ConnectWalletModal show={show} setShow={setShow} />

      <Container>
        <InfoNotice
          icon={faUser}
          message={[
            "Connect your wallet to create and manage your Grail account. By connecting your wallet, you agree to our Terms of Service and our Privacy Policy.",
          ]}
          state="neutral"
          title="Welcome to Grail!"
        />

        <Buttons>
          <Button $fullWidth variant="purple" onClick={toggleModal}>
            Connect wallet
          </Button>
        </Buttons>
      </Container>
    </>
  );
};
