import { Price, PriceLabel } from "@/components/EventCard";
import { AuctionState } from "@/generated/schema";
import type { AuctionCurrency } from "@/generated/schema";
import { pluralize } from "@/utils/content";
import { formatCurrency } from "@/utils/getCurrency";

import { VideoPanel } from "../../../events";

interface Props {
  currency: AuctionCurrency;
  description?: string;
  lotCount?: number | null;
  openingPrice?: number | null;
  slug: string;
  title: string;
  twitchChannelName?: string;
  youtubeVideoId?: string;
}

export const AuctionVideoPanel = ({
  currency,
  description,
  lotCount = 0,
  openingPrice,
  slug,
  title,
  twitchChannelName,
  youtubeVideoId,
}: Props) => {
  const price = openingPrice
    ? formatCurrency(openingPrice, currency)
    : "Coming soon…";

  return (
    <VideoPanel
      currency={currency}
      description={description}
      info={`Includes ${pluralize("lot", lotCount)}`}
      link={`/auctions/${slug}`}
      state={AuctionState.LIVE}
      STATES={AuctionState}
      subtitle="Live NFT Auction"
      title={title}
      twitchChannelName={twitchChannelName}
      youtubeVideoId={youtubeVideoId}
    >
      <PriceLabel>
        Opening bids from <Price>{price}</Price>
      </PriceLabel>
    </VideoPanel>
  );
};
