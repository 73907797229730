import reactMarkdown from "react-markdown";
import styled from "styled-components";

export const StyledMarkdown = styled(reactMarkdown)`
  p {
    line-height: 1.5rem;
    margin: 0;
  }

  p + p {
    margin-top: 1rem;
  }
`;
