import styled, { css } from "styled-components";

import { Colors } from "../../themes/Default";

export const Button = styled.button`
  background: transparent;
  border: none;
  display: inline-grid;
  margin: 0;
  padding: 0;

  :disabled {
    opacity: 0.6;
  }
`;

export const Dot = styled.button<{ selected?: boolean }>`
  background: #dadfe9;
  border: 0;
  border-radius: 50%;
  height: 0.5rem;
  margin: 0;
  padding: 0;
  width: 0.5rem;

  ${({ selected }) =>
    selected &&
    css`
      background: ${Colors.GreyBlue};
    `}
`;
