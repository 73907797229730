import { useQuery } from "@apollo/client";

import { GET_ACTIVE_COUNT } from "../api/GET_ACTIVE_COUNT";

interface QueryResponse {
  auctionsLive: {
    totalCount: number | null;
  };
  auctionsPublished: {
    totalCount: number | null;
  };
  auctionsPast: {
    totalCount: number | null;
  };
}

export const useHasActiveEvents = () => {
  const { data } = useQuery<QueryResponse>(GET_ACTIVE_COUNT);

  const hasLiveAuctions = !!data?.auctionsLive?.totalCount;
  const hasPublishedAuctions = !!data?.auctionsPublished?.totalCount;
  const hasPastAuctions = !!data?.auctionsPast?.totalCount;

  return {
    hasAuctions: hasLiveAuctions || hasPublishedAuctions,
    hasLiveAuctions,
    hasLiveEvents: hasLiveAuctions,
    hasPastAuctions,
  };
};
