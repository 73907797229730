import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { Gradients, NewColors } from "@/themes/Default";

export const Avatar = styled.img`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

export const Container = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr;
  position: relative;
`;

export const ImageContainer = styled.div`
  background: ${Gradients.Channels};
  background-color: ${NewColors.Deadfill};
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  width: 100%;

  ::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

export const Manage = styled.div`
  border: 0.25rem solid transparent;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;
  outline-offset: 0.25rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  :focus,
  :hover {
    border-color: ${NewColors.Purple};
  }
`;

export const ManageButton = styled.div`
  background: ${NewColors.Purple};
  border: none;
  border-radius: 50%;
  display: none;
  height: 2rem;
  place-content: center;
  position: absolute;
  right: 0.25rem;
  top: 0.5rem;
  width: 2rem;

  ${Manage}:focus &,
  ${Manage}:hover & {
    display: grid;
  }
`;

export const ManageIcon = styled(FontAwesomeIcon)`
  color: white;
  height: 1.25rem;
  width: 1.25rem;
`;

export const Media = styled.div`
  position: relative;
`;

export const Name = styled(Link)<{ $active?: boolean }>`
  color: ${NewColors.TextShout};
  line-height: 1.5rem;
  margin: 0;
  opacity: 0.4;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    color: ${NewColors.TextShout};

    ${({ $active }) =>
      $active &&
      css`
        opacity: 1;
      `}
  }

  ::after {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  ${({ $active }) =>
    $active &&
    css`
      opacity: 1;
    `}
`;

export const User = styled.div`
  font-weight: bold;
  overflow: hidden;
  text-align: center;
`;

export const WalletAddress = styled.p`
  color: ${NewColors.Green};
  line-height: 1.25rem;
  margin: 0;
`;
