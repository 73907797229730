import { NFTList } from "../NFTList";

import { Container, Content, Control, Controls, Wrapper } from "./styles";

interface Props {
  walletAddress: string;
}

export const ProfileNavigation = ({ walletAddress }: Props) => {
  return (
    <Container>
      <Wrapper>
        <Controls>
          <Control>Items</Control>
        </Controls>

        <Content>
          <NFTList walletAddress={walletAddress} />
        </Content>
      </Wrapper>
    </Container>
  );
};
