import { useLazyQuery, useQuery } from "@apollo/client";
import type { LazyQueryHookOptions } from "@apollo/client";
import { useEffect } from "react";

import { GET_ACCOUNT_DETAILS } from "@/api/queries/GET_ACCOUNT_DETAILS";
import { IS_LOGGED_IN } from "@/api/queries/IS_LOGGED_IN";
import type { UserType } from "@/generated/schema";

interface AccountQueryResponse {
  myAccount: UserType;
}

interface LoginQueryResponse {
  isLoggedIn: boolean;
}

export const useFetchAccountData = (options?: LazyQueryHookOptions) => {
  const { data: loginData } = useQuery<LoginQueryResponse>(IS_LOGGED_IN);
  const [
    getAccountDetails,
    { data: accountData, error: accountError, stopPolling },
  ] = useLazyQuery<AccountQueryResponse>(GET_ACCOUNT_DETAILS, options);

  const isLoggedIn = loginData && loginData.isLoggedIn;
  const discordUser =
    accountData?.myAccount?.discorduser?.discordDiscriminator &&
    accountData?.myAccount?.discorduser?.discordUsername
      ? `${accountData?.myAccount?.discorduser?.discordUsername}#${accountData?.myAccount?.discorduser?.discordDiscriminator}`
      : "";

  const channels =
    accountData?.myAccount.teamMemberships?.edges.map(
      (teammember) => teammember?.node?.channel,
    ) ?? [];

  useEffect(() => {
    if (isLoggedIn) getAccountDetails();
  }, [getAccountDetails, isLoggedIn]);

  return {
    accountData: {
      balance: accountData?.myAccount?.balance,
      channels,
      discordUser,
      email: accountData?.myAccount?.userprofile?.emailAddress ?? "",
      isStaff: accountData?.myAccount?.isStaff,
      username: accountData?.myAccount?.username,
    },
    error: accountError,
    getAccountDetails,
    isLoggedIn,
    loginData,
    stopPolling,
  };
};
