import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Children, ReactNode } from "react";

import { IconLinkButton } from "@/components/Button";
import { LoadingBoundary } from "@/components/LoadingBoundary";
import { Section } from "@/components/Section";

import { Carousel } from "../Carousel";

import { Contents, Description, Details, Heading, ViewAll } from "./styles";

interface Props {
  children: ReactNode;
  description: string;
  error?: boolean;
  featured?: boolean;
  loading?: boolean;
  link?: string;
  title: string;
}

export const CarouselSection = ({
  children,
  description,
  error,
  featured = false,
  loading,
  link,
  title,
}: Props) => {
  const hasNoChildren = Children.toArray(children).length === 0;
  const shouldNotRender = !error && !loading && hasNoChildren;

  if (shouldNotRender) return null;

  return (
    <Section>
      <Details>
        <Heading>{title}</Heading>
        {description && <Description>{description}</Description>}

        {link && (
          <ViewAll>
            <IconLinkButton icon={faArrowRight} to={link} variant="white">
              View all
            </IconLinkButton>
          </ViewAll>
        )}
      </Details>

      <Contents>
        <LoadingBoundary error={error} loading={loading}>
          <Carousel featured={featured} link={link}>
            {children}
          </Carousel>
        </LoadingBoundary>
      </Contents>
    </Section>
  );
};
