import styled, { css } from "styled-components";

import background from "@/images/backgrounds/section-bg.png";
import { Borders } from "@/themes/Default";

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: center;
`;

export const Container = styled.div`
  align-items: center;
  background-image: linear-gradient(135.54deg, #062b5a 0%, #187e89 100%);
  color: white;
  padding: 1.5rem;
  position: relative;

  ::after {
    background-image: url(${background});
    background-size: cover;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.1;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
`;

export const Contents = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;
`;

export const Copy = styled.p`
  margin: 0;
  opacity: 0.8;
  text-align: center;
`;

export const Fee = styled.span`
  font-weight: normal;
`;

export const Instructions = styled.div`
  max-width: 21.5rem;
  width: 100%;
`;

export const Label = styled.p`
  font-weight: bold;
  margin: 0;
`;

export const PaymentContainer = styled.div`
  margin-top: 1rem;
`;

export const Price = styled.div<{ large?: boolean }>`
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  ${({ large }) =>
    large &&
    css`
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 2rem;
    `}
`;

export const Pricing = styled.div`
  border-bottom: ${Borders.Live};
  display: grid;
  gap: 0.25rem;
  padding-bottom: 0.75rem;
  width: 100%;
`;

export const QRContainer = styled.div`
  display: grid;
  gap: 0.75rem;
  margin-top: 1rem;
  place-items: center;
`;

export const QRCodeWrapper = styled.div`
  background: white;
  padding: 0.25rem;
`;
