import { useQuery } from "@apollo/client";

import { IS_LOGGED_IN } from "@/api/queries/IS_LOGGED_IN";

interface LoginQueryResponse {
  isLoggedIn: boolean;
}

export const useIsLoggedIn = () => {
  const { data: loginData } = useQuery<LoginQueryResponse>(IS_LOGGED_IN);
  const isLoggedIn = (loginData && loginData.isLoggedIn) || false;

  return {
    isLoggedIn,
  };
};
