import { Helmet } from "react-helmet";
import { Navigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { LoadingBoundary } from "@/components/LoadingBoundary";
import { Breakpoints, CONTENT_WIDTH, NewColors } from "@/themes/Default";

import { AuctionLiveShow } from "../components/AuctionLiveShow";
import { AuctionLots } from "../components/AuctionLots";
import { AuctionSettings } from "../components/AuctionSettings";
import { AuctionStatus } from "../components/AuctionStatus";
import { AuctionSummary } from "../components/AuctionSummary";
import { useChannel } from "../hooks/useChannel";
import { useDraftAuction } from "../hooks/useDraftAuction";

const Container = styled.div`
  display: grid;
  padding: 1rem 1.5rem 5rem;
  place-items: center;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 2rem 3rem 10rem;
  }
`;

const Title = styled.h1`
  color: ${NewColors.Black};
  font-size: 2rem;
  font-weight: bold;
  grid-area: title;
  line-height: 2.5rem;
  margin: 0;
`;

const Wrapper = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-areas:
    "title"
    "auction-status"
    "auction-summary"
    "auction-settings"
    "auction-live-show"
    "auction-lots";
  grid-template-columns: 1fr;
  max-width: ${CONTENT_WIDTH};
  width: 100%;

  @media (min-width: 74rem) {
    grid-template-areas:
      "title title"
      "auction-status auction-status"
      "auction-summary auction-settings"
      "auction-summary auction-lots"
      "auction-live-show auction-lots"
      ". auction-lots";
    grid-template-columns: 1fr 1fr;
  }
`;

export const ManageAuction = () => {
  const { auctionId, channelSlug } = useParams();

  const { loading: channelLoading } = useChannel(channelSlug!);
  const { auction, loading: auctionLoading } = useDraftAuction(auctionId!);

  return (
    <LoadingBoundary loading={channelLoading || auctionLoading}>
      {auction ? (
        <>
          <Helmet>
            <title>Manage auction - {auction?.name}</title>
          </Helmet>

          <Container>
            <Wrapper>
              <Title>Manage Auction</Title>

              <AuctionStatus auctionId={auctionId!} />
              <AuctionSummary auctionId={auctionId!} />
              <AuctionSettings />
              <AuctionLiveShow auctionId={auctionId!} />
              <AuctionLots />
            </Wrapper>
          </Container>
        </>
      ) : (
        <Navigate replace to="/" />
      )}
    </LoadingBoundary>
  );
};
