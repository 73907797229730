import styled from "styled-components";

import { ModalButtonContainer } from "@/components/Modal";
import { Breakpoints } from "@/themes/Default";

import { Capsule, Copy } from "../Capsule";

export const ButtonContainer = styled(ModalButtonContainer)`
  grid-template-columns: 14rem;
`;

export const Container = styled(Capsule)`
  grid-area: auction-status;

  @media (min-width: ${Breakpoints.Mobile}) {
    grid-template-columns: 1fr auto;
  }
`;

export const Controls = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr;

  @media (min-width: ${Breakpoints.Mobile}) {
    grid-template-columns: 12rem 12rem;
  }
`;

export const State = styled(Copy)`
  text-transform: capitalize;
`;
