import styled from "styled-components";

import { ResponsiveImage } from "../../../../components/ResponsiveImage";
import { Breakpoints, NewColors } from "../../../../themes/Default";

export const Children = styled.div`
  grid-area: children;
`;

export const Container = styled.div`
  display: grid;
  grid-gap: 1rem 1.5rem;
  grid-template-areas:
    "image"
    "heading"
    "children";

  @media (min-width: ${Breakpoints.Mobile}) {
    grid-template: auto 1fr / 11.23rem 1fr;
    grid-template-areas:
      "image heading"
      "image children";
  }
`;

export const Heading = styled.h3`
  grid-area: heading;
  margin: 0;
`;

export const Image = styled(ResponsiveImage)`
  border-radius: 0.5rem;
  overflow: hidden;
`;

export const ImageContainer = styled.div`
  border-radius: 0.5rem;
  grid-area: image;
  justify-self: center;
  max-width: 12rem;
  width: 100%;
`;

export const Name = styled.span`
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  margin-top: 0.25rem;
`;

export const Project = styled.span`
  color: ${NewColors.TextSpeak};
  display: block;
  font-size: 1rem;
`;
