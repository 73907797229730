import { useEffect, useState } from "react";

import { useWallet } from "../../hooks/useWallet";
import { WalletProvider } from "../../types";
import { getWalletByProvider } from "../../utils";

import { WalletDetails } from "./components/WalletDetails";

interface Props {
  provider: WalletProvider;
}

export const Wallet = ({ provider }: Props) => {
  const { chain } = getWalletByProvider(provider);

  const [error, setError] = useState("");
  const { disconnect, balances, errorMessage, walletAddress } =
    useWallet(chain);

  useEffect(() => setError(errorMessage), [errorMessage]);

  return (
    <WalletDetails
      error={error}
      setError={setError}
      walletAddress={walletAddress}
      deactivate={disconnect}
      balances={balances}
    />
  );
};
