import styled, { css } from "styled-components";

import { IconAnchorButton } from "@/components/Button";
import { InfoNotice } from "@/components/InfoNotice";
import { Breakpoints, Borders } from "@/themes/Default";

interface BidDetailProps {
  isUserBid?: boolean;
}

export const Bid = styled.p`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: auto auto;
  justify-content: space-between;
  margin: 0;
`;

export const BidDetail = styled.span<BidDetailProps>`
  font-size: 1rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    font-size: 1.125rem;
  }

  ${({ isUserBid }) =>
    isUserBid &&
    css`
      font-weight: bold;
    `}

  & + & {
    font-weight: bold;
  }
`;

export const Description = styled.p`
  font-size: 0.875rem;
  grid-area: copy;
  margin: 0;

  @media (min-width: ${Breakpoints.Mobile}) {
    font-size: 1rem;
  }
`;

export const ExternalLink = styled(IconAnchorButton)`
  grid-area: link;
  margin-top: 1rem;
`;

export const Heading = styled.h4`
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;

  @media (min-width: ${Breakpoints.Mobile}) {
    margin: 0 0 0.5rem;
  }
`;

export const History = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
`;

export const Loading = styled.div`
  display: grid;
  gap: 1.5rem;
  padding: 1.5rem;
  place-items: center;
`;

export const LoadingText = styled.p`
  font-weight: bold;
  margin: 0;
`;

export const NoBids = styled.p`
  margin: 0;
`;

export const StyledInfoNotice = styled(InfoNotice)`
  padding: 1.5rem;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  padding: 1.5rem 1rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    grid-row-gap: 0.5rem;
    padding: 1.5rem;
  }

  & + & {
    border-top: ${Borders.Live};
  }
`;
