import type { ButtonHTMLAttributes, ReactNode } from "react";

import { Button, Props as ButtonProps } from "@/components/Button";

interface SubmitButtonProps {
  children: ReactNode;
  submitting: boolean;
  variant: ButtonProps["variant"];
}

export const SubmitButton = ({
  children,
  submitting,
  variant,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & SubmitButtonProps) => (
  <Button variant={variant} {...props}>
    {submitting ? "Submitting…" : children}
  </Button>
);
