import { gql } from "@apollo/client";

export const AUCTION_CREATE = gql`
  mutation AUCTION_CREATE($channelId: ID!, $name: String!) {
    auctionCreate(channelId: $channelId, name: $name) {
      errors {
        message
      }
    }
  }
`;
