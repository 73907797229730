import styled, { css } from "styled-components";

import { Borders, NewColors } from "@/themes/Default";

const InputStyling = css`
  border: ${Borders.User};
  border-radius: 0.5rem;
  color: ${NewColors.TextShout};
  font-weight: bold;
  line-height: 1.25rem;
  padding: 0.75rem;
  width: 100%;

  :disabled {
    background-color: ${NewColors.Deadfill};
  }

  ::placeholder {
    color: ${NewColors.TextWhisper};
  }
`;

export const Container = styled.div`
  color: ${NewColors.TextSpeak};
  font-family: Quicksand, sans-serif;
`;

export const Copy = styled.p`
  margin: 0.5rem 0 0;
`;

export const Error = styled.p`
  color: ${NewColors.Negative};
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0;
`;

export const Input = styled.input<{ error?: boolean }>`
  ${InputStyling}

  ${({ error }) =>
    error &&
    css`
      border-color: ${NewColors.Negative};
    `}
`;

export const InputWrapper = styled.div`
  display: grid;
  gap: 1rem;
  margin-top: 0.5rem;
  position: relative;
`;

export const Label = styled.label`
  color: ${NewColors.TextShout};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 0;
`;

export const Success = styled.p`
  color: ${NewColors.Primary};
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0;
`;

export const TextArea = styled.textarea<{ error?: boolean }>`
  ${InputStyling}

  ${({ error }) =>
    error &&
    css`
      border-color: ${NewColors.Negative};
    `}
`;

export const Uploading = styled.div`
  background: ${NewColors.GlacierShadow};
  bottom: 0;
  display: grid;
  left: 0;
  place-items: center;
  position: absolute;
  right: 0;
  top: 0;
`;
