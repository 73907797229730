import { useState } from "react";
import type { FormEvent } from "react";

import { Button } from "@/components/Button";
import { Error, Input, Select } from "@/components/FormElements";
import {
  Modal,
  ModalContent,
  ModalCopy,
  smallModalStyle,
} from "@/components/Modal";

import { useChannel } from "../../../../hooks/useChannel";

import { ButtonContainer } from "./styles";

interface Props {
  channelSlug: string;
  handleClose(): void;
  isOpen: boolean;
}

export const AddModal = ({ channelSlug, handleClose, isOpen }: Props) => {
  const { addTeamMember } = useChannel(channelSlug);
  const [error, setError] = useState("");
  const [failedToSubmit, setFailedToSubmit] = useState(false);
  const [invalidWalletAddress, setInvalidWalletAddress] = useState(true);
  const [role, setRole] = useState("admin");
  const [submitting, setSubmitting] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");

  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const isInvalid = !/^0x[a-fA-F0-9]{40}$/.test(value) || value === "";

    setWalletAddress(value);
    setInvalidWalletAddress(isInvalid);
  };

  const handleSelect = (e: FormEvent<HTMLSelectElement>) => {
    setRole(e.currentTarget.value);
  };

  const handleSubmit = () => {
    setFailedToSubmit(invalidWalletAddress);

    if (!invalidWalletAddress) {
      setError("");
      setSubmitting(true);

      addTeamMember({
        variables: { role, walletAddress },
        onCompleted: ({ addTeamMemberToChannel: { errors } }) => {
          const hasErrors = !!errors?.length;

          setSubmitting(!hasErrors);

          if (hasErrors) {
            setError(
              errors[0].message ?? "Something went wrong, please try again!",
            );
          } else {
            handleClose();
          }
        },
        onError: (error) => {
          setError("Something went wrong, please try again!");
          setSubmitting(false);
        },
      });
    }
  };

  return (
    <Modal
      customStyles={smallModalStyle}
      handleClose={handleClose}
      isOpen={isOpen}
      title="Add team member"
    >
      <ModalContent>
        <ModalCopy>
          Enter a valid MetaMask wallet address to add it to this channel’s
          team. Connecting via that wallet will grant access to this channel’s
          management page.
        </ModalCopy>

        <Input
          defaultValue={walletAddress}
          error={invalidWalletAddress && failedToSubmit}
          errorMessage="Wallet address is invalid"
          handleInput={handleInput}
          label="MetaMask wallet address"
          name="walletAddress"
          type="text"
        />

        <Select
          defaultValue={role}
          description="Admins can create and manage auctions and update the channel profile. Owners are additionally able to add and manage team members."
          handleChange={handleSelect}
          label="Role"
          name="role"
        >
          <option value="admin">Admin</option>
          <option value="owner">Owner</option>
        </Select>

        <ButtonContainer>
          <Button disabled={submitting} onClick={handleSubmit} variant="green">
            {submitting ? "Adding…" : "Add team member"}
          </Button>
        </ButtonContainer>

        {error && <Error>❌ {error}.</Error>}
      </ModalContent>
    </Modal>
  );
};
