import { useEffect } from "react";
import { useMoralis } from "react-moralis";

export const useEnableMoralis = () => {
  const { Moralis, isWeb3Enabled } = useMoralis();

  useEffect(() => {
    const enable = async () => {
      try {
        await Moralis.enableWeb3();
      } catch {
        // Empty
      }
    };

    if (!isWeb3Enabled) enable();
  }, [isWeb3Enabled, Moralis]);
};
