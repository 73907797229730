import { useState } from "react";
import type { FormEvent } from "react";

type InputType = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
export type Change<T extends InputType> = FormEvent<T>;

export const useForm = <T>(initialState: T) => {
  const [formState, setFormState] = useState({ ...initialState });

  const handleDateChange = ({ date, name }: { date: Date; name: string }) =>
    setFormState({
      ...formState,
      [name]: date,
    });

  const handleInputChange = <U extends InputType>(e: Change<U>) =>
    setFormState({
      ...formState,
      [e.currentTarget.name]: e.currentTarget.value,
    });

  return {
    formState,
    handleDateChange,
    handleInputChange,
    setFormState,
  };
};
