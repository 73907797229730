import type { ReactNode } from "react";

import { TwitchEmbed } from "@/components/TwitchEmbed";
import { YouTubeEmbed } from "@/components/YouTubeEmbed";
import type {
  AuctionCurrency,
  CryptoCurrencies,
  EventEventType,
} from "@/generated/schema";

import {
  Card,
  Container,
  Description,
  Heading,
  Info,
  LowerContent,
  StyledLink,
  StyledTimeBadge,
  Subtitle,
  UpperContent,
  Video,
} from "./styles";

interface Props {
  children?: ReactNode;
  currency?: AuctionCurrency | CryptoCurrencies;
  description?: string;
  event?: EventEventType;
  info?: string;
  link?: string;
  subtitle?: string;
  state: string;
  STATES: { [key: string]: string };
  title: string;
  twitchChannelName?: string;
  twitchVideoEmbedId?: string;
  youtubeVideoId?: string;
}

// TODO: Error state?
// TODO: Loading state?

export const VideoPanel = ({
  children,
  currency,
  description,
  event,
  info,
  link = "#",
  state,
  STATES,
  subtitle,
  title,
  twitchChannelName,
  youtubeVideoId,
}: Props) => (
  <Container>
    <Video>
      {twitchChannelName ? (
        <TwitchEmbed
          autoplay={true}
          channel={twitchChannelName}
          mute={true}
          showChat={false}
        />
      ) : (
        youtubeVideoId && (
          <YouTubeEmbed
            autoplay={true}
            mute={true}
            showChat={false}
            videoId={youtubeVideoId}
          />
        )
      )}
    </Video>

    <Card>
      <UpperContent>
        <div>
          {subtitle && (
            <Subtitle>
              {subtitle}
              {currency && (
                <>
                  {" "}
                  <span>•</span> {currency}
                </>
              )}
            </Subtitle>
          )}

          <Heading>
            <StyledLink to={link}>{title}</StyledLink>
          </Heading>

          {description && <Description>{description}</Description>}

          <StyledTimeBadge
            event={event}
            state={state}
            STATES={STATES}
            scheduledStartTime={new Date()}
          />
        </div>

        {info && <Info>{info}</Info>}
      </UpperContent>

      {children && <LowerContent>{children}</LowerContent>}
    </Card>
  </Container>
);
