import { useMutation } from "@apollo/client";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

import { Modal } from "@/components/Modal";
import { DisconnectDiscordUser } from "@/generated/schema";
import { SOCIAL_LINKS } from "@/settings";

import { DISCONNECT_DISCORD_USER } from "../../api/DISCONNECT_DISCORD_USER";
import { DiscordLogos } from "../DiscordLogos";

import {
  customModalStyles,
  Connect,
  Container,
  Control,
  Disconnect,
  Error,
  InfoButton,
  Input,
  InputWrapper,
  Label,
  Logo,
  ModalCopy,
  ModalContents,
  Name,
  DisconnectConfirmation,
  DiscordLink,
} from "./styles";

interface Props {
  username?: string;
}

interface MutationResponse {
  disconnectDiscordUser: DisconnectDiscordUser;
}

export const DiscordIntegration = ({ username }: Props) => {
  const [disconnecting, setDisconnecting] = useState(false);
  const [discordUsername, setDiscordUsername] = useState(username);
  const [errors, setErrors] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showInstructionModal, setShowInstructionModal] = useState(false);

  useEffect(() => {
    if (username) setDiscordUsername(username);
  }, [username]);

  const [disconnectDiscordUser] = useMutation<MutationResponse>(
    DISCONNECT_DISCORD_USER,
    {
      onCompleted: ({ disconnectDiscordUser: { errors } }) => {
        const hasErrors = !!errors && !!errors.length;

        setDisconnecting(false);
        setErrors(hasErrors);
        setShowConfirmationModal(hasErrors);

        if (!hasErrors) setDiscordUsername("");
      },
      onError: () => {
        setErrors(true);
        setDisconnecting(false);
      },
    },
  );

  const handleDisconnect = () => {
    setDisconnecting(true);
    disconnectDiscordUser();
  };

  return (
    <>
      {showConfirmationModal && (
        <Modal
          title="Disconnect Your Discord Account"
          isOpen={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          customStyles={customModalStyles}
        >
          <ModalContents>
            <ModalCopy>
              Once disconnected, you will no longer be able to use Discord to
              interact with your Grail wallet (e.g. placing bids directly from a
              Discord channel).
            </ModalCopy>

            <ModalCopy>
              Should you wish to reconnect it at a later date, repeat the
              verification process with the Grail Bot inside a Discord channel.
            </ModalCopy>

            {errors && (
              <ModalCopy>
                <Error>Something went wrong, try again.</Error>
              </ModalCopy>
            )}

            <DisconnectConfirmation
              disabled={disconnecting}
              onClick={handleDisconnect}
            >
              {disconnecting ? "Disconnecting…" : "Disconnect Account"}
            </DisconnectConfirmation>
          </ModalContents>
        </Modal>
      )}

      {showInstructionModal && (
        <Modal
          title="Connect Your Discord Account"
          isOpen={showInstructionModal}
          handleClose={() => setShowInstructionModal(false)}
          customStyles={customModalStyles}
        >
          <ModalContents>
            <DiscordLogos />
            <ModalCopy>Bid on Grail auctions, directly from Discord.</ModalCopy>
            <ModalCopy>
              Our Grail Bot keeps Discord channels informed of new auction
              events and incoming bids. By connecting your Discord user to your
              Grail wallet, you can place new bids of your own without ever
              leaving the chat.
            </ModalCopy>
            <ModalCopy>
              It just takes a few seconds to setup, and you will only need to
              connect once to be able to bid from any channel running Grail Bot.
              To get started, drop into our Discord and send a /verify slash
              command to Grail Bot.
            </ModalCopy>
            <DiscordLink
              href={SOCIAL_LINKS.Discord}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Logo icon={faDiscord} /> Join and verify in Discord
            </DiscordLink>
          </ModalContents>
        </Modal>
      )}

      <Container>
        <Label>
          <Logo icon={faDiscord} />
          <Name>Discord</Name>
        </Label>

        <Control>
          <InputWrapper>
            <Input
              defaultValue={discordUsername ?? ""}
              disabled={true}
              placeholder="Not connected"
              type="text"
            />

            {discordUsername ? (
              <Disconnect onClick={() => setShowConfirmationModal(true)}>
                Disconnect
              </Disconnect>
            ) : (
              <Connect onClick={() => setShowInstructionModal(true)}>
                Connect
              </Connect>
            )}
          </InputWrapper>

          <InfoButton
            aria-label="Show Information"
            onClick={() => setShowInstructionModal(true)}
          >
            <FontAwesomeIcon icon={faInfo} />
          </InfoButton>
        </Control>
      </Container>
    </>
  );
};
