import { useState } from "react";

import type { Error } from "../../../generated/schema";

import { useLot } from "./useLot";

export const usePlaceBid = (id: string) => {
  const { createBid, lot } = useLot(id);
  const [bidAccepted, setBidAccepted] = useState(false);
  const [bidMessageTimeout, setBidMessageTimeout] = useState(false);
  const [bid, setBid] = useState("");
  const [errors, setErrors] = useState<Error[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [validBid, setValidBid] = useState(false);

  const handleSubmit = async () => {
    const parsedBid = parseFloat(bid);

    setSubmitting(true);

    try {
      const { data } = await createBid(parsedBid);
      const hasErrors = !!data?.bidCreate.errors?.length;

      if (hasErrors) {
        setBidAccepted(false);
        setErrors(data?.bidCreate?.errors || []);
      } else {
        setBidAccepted(true);
        setErrors([]);
      }
    } catch {
      setErrors([
        {
          field: "field",
          message: "could not place bid",
          __typename: "Error",
        },
      ]);
      setBidAccepted(false);
    }

    setBidMessageTimeout(true);
    setBid("");
    setSubmitting(false);
    setValidBid(false);

    setTimeout(() => {
      setBidAccepted(false);
      setBidMessageTimeout(false);
    }, 3000);
  };

  return {
    bid,
    bidAccepted,
    bidMessageTimeout,
    errors,
    handleSubmit,
    lot,
    setBid,
    setValidBid,
    submitting,
    validBid,
  };
};
