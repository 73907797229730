import { Navigate, Route, Routes } from "react-router-dom";

import { MinimumLayout } from "@/components/MinimumLayout";

import { Nft } from "./Nft";

export const NftsRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate replace to="/" />} />

    <Route
      path="/:id"
      element={
        <MinimumLayout>
          <Nft />
        </MinimumLayout>
      }
    />
  </Routes>
);
