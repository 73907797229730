import { Link } from "react-router-dom";
import styled from "styled-components";

import { TimeBadge } from "@/components/TimeBadge";
import { Borders, Breakpoints, Gradients, NewColors } from "@/themes/Default";

export const Content = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 1.5rem 0.75rem 0.75rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 1.5rem 1rem 1rem;
  }
`;

export const Container = styled.div`
  background: white;
  border: ${Borders.User};
  border-radius: 0.5rem;
  box-shadow: 0 0 4px 0 rgb(5 21 52 / 8%);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: all 200ms ease-in-out;

  &:hover,
  &:focus,
  &:active {
    border-color: ${NewColors.Purple};
    outline: 0;
    transform: translateY(-2px);
  }
`;

export const Description = styled.p`
  -webkit-box-orient: vertical;
  /* stylelint-disable */
  display: -webkit-box;
  /* stylelint-enable */
  font-size: 0.875rem;
  -webkit-line-clamp: 3;
  margin: 0.5rem 0 0;
  overflow: hidden;
`;

export const Details = styled.div`
  flex-grow: 1;
`;

export const Featured = styled.span`
  background: ${Gradients.Nft};
  border-bottom: ${Borders.Nft};
  border-radius: 0 0 0.5rem;
  border-right: ${Borders.Nft};
  box-shadow: 0 2px 8px 0 rgb(18 25 92 / 25%);
  color: white;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1rem;
  padding: 0.375rem 0.75rem;
  position: absolute;
  text-transform: uppercase;
  top: 0;
`;

export const Heading = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const Info = styled.p`
  color: ${NewColors.Purple};
  font-size: 0.875rem;
  margin: 1.125rem 0 0;
`;

export const LowerContent = styled.div`
  border-top: ${Borders.Nft};
  flex: 0;
  margin-top: 0.75rem;
  padding-top: 0.75rem;
`;

export const Price = styled.span`
  color: ${NewColors.Green};
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
`;

export const PriceLabel = styled.p`
  color: ${NewColors.TextSpeak};
  font-size: 0.875rem;
  margin: 0;
`;

export const Subtitle = styled.p`
  font-size: 0.875rem;
  margin: 0 0 0.5rem;

  span {
    padding: 0 0.25rem;
  }
`;

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;

  ::after {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  :hover {
    color: ${NewColors.Purple};
  }
`;

export const StyledTimeBadge = styled(TimeBadge)`
  align-self: flex-start;
  margin-bottom: 1rem;
  z-index: 1;
`;

export const UpperContent = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  margin-top: -2.5rem;
  min-height: 10rem;
`;
