import { useMemo } from "react";
import type { Dispatch, SetStateAction } from "react";

import { ModalCopy } from "@/components/Modal";
import { NFTCard } from "@/features/users";

import { NFTCardWrapper } from "../NFTCardWrapper";

import {
  Continue,
  ContinueContainer,
  CopyContainer,
  NFTContainer,
  NFTGrid,
  NFTScrollList,
  Selected,
  SelectedName,
} from "./styles";

interface SelectNFTProps {
  handleContinue(): void;
  selectedId: string;
  setSelectedId: Dispatch<SetStateAction<string>>;
  TEMP_ITEMS: {
    id: string;
    name: string;
  }[];
}

export const SelectNFT = ({
  handleContinue,
  selectedId,
  setSelectedId,
  TEMP_ITEMS,
}: SelectNFTProps) => {
  const onClickCard = (id: string) => {
    const newValue = id === selectedId ? "" : id;
    setSelectedId(newValue);
  };

  // MIGHT NEED TO PULL THIS OUT
  const selectedItem = useMemo(
    () => TEMP_ITEMS.find((item) => item.id === selectedId),
    [selectedId, TEMP_ITEMS],
  );

  return (
    <>
      <CopyContainer>
        <ModalCopy>
          Select which NFT will be included in this auction lot. You can
          customise how it is presented at the next step.
        </ModalCopy>
      </CopyContainer>

      <NFTContainer>
        <NFTScrollList>
          <NFTGrid>
            {TEMP_ITEMS.map((item, idx) => (
              <NFTCardWrapper
                key={idx}
                onClick={() => onClickCard(item.id)}
                selected={item.id === selectedId} //TEMP
              >
                <NFTCard
                  image={
                    "https://img.grail-dev.live/auctions/lot/photo/901E2D4DAF4DDC73E3D5873220BA3250/4aafb18dca0fbe0fbb73bb6ba968ca44.gif?tr=h-1024%2Cw-1024%2Cc-at_max%2Cq-90"
                  }
                  name={item.name}
                  projectName={"Invisible Friends"}
                  projectLogo={
                    "https://img.grail-dev.live/auctions/lot/photo/901E2D4DAF4DDC73E3D5873220BA3250/4aafb18dca0fbe0fbb73bb6ba968ca44.gif?tr=h-1024%2Cw-1024%2Cc-at_max%2Cq-90"
                  }
                />
              </NFTCardWrapper>
            ))}
          </NFTGrid>
        </NFTScrollList>
      </NFTContainer>

      <ContinueContainer>
        {selectedItem && (
          <Selected>
            Selected <SelectedName>{selectedItem.name}</SelectedName>
          </Selected>
        )}

        <Continue
          disabled={!selectedItem}
          onClick={handleContinue}
          variant="green"
        >
          Continue
        </Continue>
      </ContinueContainer>
    </>
  );
};
