import { withSentryReactRouterV6Routing } from "@sentry/react";
import { Navigate, Route, Routes } from "react-router-dom";

import { MinimumLayout } from "@/components/MinimumLayout";
import { AccountRoutes } from "@/features/account";
import { ChannelsRoutes } from "@/features/channels";
import { EventsRoutes } from "@/features/events";
import { AuctionsRoutes } from "@/features/nftAuctions";
import { NftsRoutes } from "@/features/nfts";
import { UsersRoutes } from "@/features/users";
import { useMetamask } from "@/hooks/useMetamask";

import { Faq } from "./Faq";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { RequestAuction } from "./RequestAuction";
import { SubmitNFTs } from "./SubmitNFTs";
import { Support } from "./Support";
import { TermsOfService } from "./TermsOfService";

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const Pages = () => {
  useMetamask();

  return (
    <SentryRoutes>
      <Route path="account/*" element={<AccountRoutes />} />
      <Route path="auctions/*" element={<AuctionsRoutes />} />
      <Route path="channels/*" element={<ChannelsRoutes />} />
      <Route path="events/*" element={<EventsRoutes />} />
      <Route path="nfts/*" element={<NftsRoutes />} />
      <Route path="users/*" element={<UsersRoutes />} />

      <Route
        path="/terms"
        element={
          <MinimumLayout>
            <TermsOfService />
          </MinimumLayout>
        }
      />

      <Route
        path="/privacy-policy"
        element={
          <MinimumLayout>
            <PrivacyPolicy />
          </MinimumLayout>
        }
      />

      <Route
        path="/request-an-auction"
        element={
          <MinimumLayout>
            <RequestAuction />
          </MinimumLayout>
        }
      />

      <Route
        path="/submit-nfts"
        element={
          <MinimumLayout>
            <SubmitNFTs />
          </MinimumLayout>
        }
      />

      <Route
        path="/faq"
        element={
          <MinimumLayout>
            <Faq />
          </MinimumLayout>
        }
      />

      <Route
        path="/support"
        element={
          <MinimumLayout>
            <Support />
          </MinimumLayout>
        }
      />

      <Route path="*" element={<Navigate replace to="/events" />} />
    </SentryRoutes>
  );
};

export default Pages;
