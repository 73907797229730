import { useState } from "react";

import { ImageSelect } from "@/components/FormElements";

import { useDraftAuction } from "../../hooks/useDraftAuction";
import {
  ContentSection,
  Copy,
  InlineButton,
  InlineLink,
  Label,
  Placeholder,
} from "../Capsule";

import { EditModal } from "./components/EditModal";
import { Address, Container, Controls } from "./styles";
interface AuctionSummaryProps {
  auctionId: string;
}

export const AuctionSummary = ({ auctionId }: AuctionSummaryProps) => {
  const { auction, isDraft, isEditable } = useDraftAuction(auctionId);

  const baseAddress = `https://grail.live/auctions/`;

  const [copied, setCopied] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleToggleModal = () => setShowModal(!showModal);
  const handleCopy = async () => {
    await navigator.clipboard.writeText(`${baseAddress}${auction?.slug}`);

    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <>
      {showModal && (
        <EditModal
          auctionId={auctionId}
          description={auction?.description}
          handleToggle={handleToggleModal}
          show={showModal}
          name={auction?.name!}
        />
      )}

      <Container
        edit={isEditable ? handleToggleModal : undefined}
        heading="Event summary"
      >
        <ContentSection>
          <ImageSelect
            disabled={!isEditable}
            img={{
              height: 17,
              src: auction?.image,
              width: 32,
            }}
            label="Hero image"
            name="heroImage"
            onChange={() => alert("To be implemented")}
          />
        </ContentSection>

        <ContentSection>
          <Label>Title</Label>
          <Copy>{auction?.name}</Copy>
        </ContentSection>

        <ContentSection>
          <Label>Description</Label>
          {auction?.description ? (
            <Copy>{auction.description}</Copy>
          ) : (
            <Placeholder>Not set</Placeholder>
          )}
        </ContentSection>

        <ContentSection>
          <Controls>
            <Label>Auction URL</Label>

            {!isDraft && (
              <Controls>
                <InlineButton onClick={handleCopy}>
                  {copied ? "Copied!" : "Copy"}
                </InlineButton>

                <InlineLink to={`/auctions/${auction?.slug}`}>View</InlineLink>
              </Controls>
            )}
          </Controls>

          <Placeholder>
            {baseAddress}
            <Address>{auction?.slug}</Address>
          </Placeholder>
        </ContentSection>
      </Container>
    </>
  );
};
