import { useEffect, useState } from "react";

import {
  Error,
  Input,
  SubmitButton,
  Success,
  TextArea,
} from "@/components/FormElements";
import {
  Modal,
  ModalButtonContainer,
  ModalContent,
  smallModalStyle,
} from "@/components/Modal";
import { useForm } from "@/hooks/useForm";

import { useDraftAuction } from "../../../../hooks/useDraftAuction";

import { Tip } from "./styles";

interface EditModalProps {
  auctionId: string;
  description?: string;
  handleToggle(): void;
  show: boolean;
  name: string;
}

interface FormState {
  description?: string;
  name: string;
}

export const EditModal = ({
  auctionId,
  description = "",
  handleToggle,
  show,
  name,
}: EditModalProps) => {
  const { auctionDraftEdit, refetchAuction } = useDraftAuction(auctionId);
  const { formState, handleInputChange } = useForm<FormState>({
    description,
    name,
  });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [error, setError]);

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => setSuccess(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [success, setSuccess]);

  const [draftEdit, { loading: submitting }] = auctionDraftEdit;

  const handleSubmit = () => {
    if (!formState.name) return;

    draftEdit({
      onCompleted: ({ auctionDraftEdit: { errors } }) => {
        const hasErrors = !!errors?.length;

        setError(hasErrors);
        setSuccess(!hasErrors);

        if (!hasErrors) refetchAuction();
      },
      onError: () => setError(true),
      variables: { ...formState },
    });
  };

  return (
    <Modal
      customStyles={smallModalStyle}
      handleClose={handleToggle}
      isOpen={show}
      title="Edit event summary"
    >
      <ModalContent>
        <div>
          <Input
            defaultValue={name}
            error={!formState.name}
            errorMessage="Name is required"
            handleInput={handleInputChange}
            label="Event name"
            name="name"
            type="text"
          />

          <Tip>
            TIP: It can be a good idea to establish a consistent naming
            convention for your channel, suiting the frequency and style of
            content you create.
          </Tip>
        </div>

        <TextArea
          defaultValue={description}
          description="Give a brief overview of the event and what people should expect from your show."
          handleInput={handleInputChange}
          label="Description"
          name="description"
        />

        <ModalButtonContainer>
          <SubmitButton
            disabled={!formState.name || submitting}
            onClick={handleSubmit}
            submitting={submitting}
            variant="green"
          >
            Save changes
          </SubmitButton>
        </ModalButtonContainer>

        {error && <Error>❌ Something went wrong, please try again!</Error>}
        {success && <Success>✅ Auction has been updated</Success>}
      </ModalContent>
    </Modal>
  );
};
