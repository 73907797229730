import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { Page } from "../components/Contentful/Page";
import { ContentWrapper } from "../components/ContentWrapper";
import { logAmplitudeEvent } from "../utils/analytics";
import { SUPPORT_EVENTS } from "../utils/analytics/events";
import { markdown } from "../utils/contentful";

export const PrivacyPolicy = () => {
  useEffect(
    () => logAmplitudeEvent(SUPPORT_EVENTS.PRIVACY_POLICY.PAGE_VIEWED),
    [],
  );

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>

      <ContentWrapper>
        <Page slug="privacy-policy">
          {(page) => (
            <>
              <h1>Privacy Policy</h1>
              {page.body && markdown(page.body)}
            </>
          )}
        </Page>
      </ContentWrapper>
    </>
  );
};
