import { Children } from "react";
import type { ReactNode } from "react";

import { ResponsiveImage } from "@/components/ResponsiveImage";
import type {
  AuctionCurrency,
  CryptoCurrencies,
  EventEventType,
} from "@/generated/schema";

import {
  Container,
  Content,
  Details,
  Featured,
  Heading,
  ImageContainer,
  Info,
  LowerContent,
  Subtitle,
  StyledLink,
  StyledTimeBadge,
  UpperContent,
  Description,
} from "./styles";

interface ChildProps {
  children: ReactNode;
}

interface Props {
  children?: ReactNode;
  currency?: AuctionCurrency | CryptoCurrencies;
  description?: string;
  event?: EventEventType;
  featuredLabel?: string;
  imageUrl?: string | null | undefined;
  info?: string | ReactNode;
  isFeatured?: boolean;
  link?: string;
  scheduledStartTime: Date | null;
  state: string;
  STATES: { [key: string]: string };
  subtitle?: string;
  title: string;
}

export const EventCard = ({
  children,
  currency,
  description,
  event,
  featuredLabel = "Featured Event",
  imageUrl,
  info,
  isFeatured = false,
  link = "#",
  scheduledStartTime,
  state,
  STATES,
  subtitle,
  title,
}: Props) => {
  const getChildComponents = (displayName: string) =>
    Children.map(children, (child) => {
      if (!child) return null;

      return (child as any).type.displayName.includes(displayName)
        ? child
        : null;
    });

  const heroComponents = getChildComponents("HeroComponents");
  const lowerComponents = getChildComponents("LowerComponents");

  return (
    <Container>
      <ImageContainer>
        <ResponsiveImage alt={""} height={200} src={imageUrl} width={342} />

        {isFeatured && <Featured>{featuredLabel}</Featured>}
        {heroComponents}
      </ImageContainer>

      <Content>
        <UpperContent>
          <StyledTimeBadge
            event={event}
            state={state}
            STATES={STATES}
            scheduledStartTime={scheduledStartTime}
          />

          {subtitle && (
            <Subtitle>
              {subtitle}
              {currency && (
                <>
                  {" "}
                  <span>•</span> {currency}
                </>
              )}
            </Subtitle>
          )}

          <Details>
            <Heading>
              <StyledLink to={link}>{title}</StyledLink>
            </Heading>

            {description && <Description>{description}</Description>}
          </Details>

          {info && <Info>{info}</Info>}
        </UpperContent>

        {!!lowerComponents?.length && (
          <LowerContent>{lowerComponents}</LowerContent>
        )}
      </Content>
    </Container>
  );
};

const HeroComponents = ({ children }: ChildProps) => <>{children}</>;
const LowerComponents = ({ children }: ChildProps) => <>{children}</>;

HeroComponents.displayName = "HeroComponents";
LowerComponents.displayName = "LowerComponents";

EventCard.HeroComponents = HeroComponents;
EventCard.LowerComponents = LowerComponents;
