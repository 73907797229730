import styled from "styled-components";

import { Borders, Breakpoints, NewColors } from "@/themes/Default";

export const Container = styled.div`
  background: white;
  border: ${Borders.User};
  border-radius: 1rem;
  box-shadow: 0 8px 16px 0 rgb(74 75 79 / 20%);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: max-content;
  padding: 1rem;
  width: 100%;

  @media (min-width: ${Breakpoints.Mobile}) {
    max-width: 36rem;
    padding: 2rem;
  }
`;

export const Contents = styled.div`
  display: grid;
  gap: 1.5rem;
`;

export const Heading = styled.h1`
  color: ${NewColors.TextShout};
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.375rem;
  margin: 0;
`;
