import { gql } from "@apollo/client";

export const UPLOAD_CHANNEL_PROFILE = gql`
  mutation UPLOAD_CHANNEL_PROFILE($channelId: ID!, $profileImage: Upload!) {
    uploadChannelProfileImage(
      channelId: $channelId
      profileImage: $profileImage
    ) {
      errors {
        field
        message
      }
    }
  }
`;
