import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";

import { Borders, Breakpoints, Colors } from "../../../../../../themes/Default";

export const Approve = styled.div`
  display: flex;
  gap: 0.5rem;
  place-content: center;
`;

export const Buttons = styled.div<{ row?: boolean }>`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.75rem;
  justify-content: flex-end;
  width: 100%;

  @media (min-width: 22.5rem) {
    flex-direction: row;
  }

  ${({ row }) =>
    !row &&
    css`
      @media (min-width: ${Breakpoints.Large}) {
        flex-direction: column;
      }
    `}
`;

export const Container = styled.div<{ standard?: boolean }>`
  align-content: space-between;
  background-color: #f8fafd;
  border-top: ${Borders.Live};
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
  padding: 1rem;

  ${({ standard }) =>
    standard &&
    css`
      grid-template-rows: auto auto 1fr;
    `}

  @media (min-width: ${Breakpoints.Large}) {
    border-left: ${Borders.Live};
    border-top: 0;
    padding: 1.5rem;
  }
`;

export const Emoji = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  border-radius: 50%;
  display: grid;
  font-size: 2rem;
  height: 4.5rem;
  margin-bottom: 1rem;
  place-items: center;
  width: 4.5rem;
`;

export const Heading = styled.h5`
  color: ${Colors.Waikawa};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin: 0;
  text-transform: uppercase;
`;

export const Icon = styled(FontAwesomeIcon)`
  height: 1.25rem;
  width: 1.25rem;
`;

export const Info = styled.div<{ success?: boolean }>`
  background-color: rgb(201 216 241 / 25%);
  border: ${Borders.Live};
  border-radius: 0.5rem;
  color: ${Colors.Astronaut};
  line-height: 1.5rem;
  padding: 1.25rem;
  text-align: center;

  ${({ success }) =>
    success &&
    css`
      background-color: #f6f8f0;
      border-color: #d7d88a;
    `}
`;

export const InfoCopy = styled.p`
  margin: 0.5rem 0 0;
`;

export const InfoHeading = styled.p`
  font-size: 1.125rem;
  font-weight: bold;
  margin: 0;
`;

export const Won = styled.div`
  display: grid;
  padding: 0.5rem 1rem;
  place-items: center;
  text-align: center;
`;

export const WonContainer = styled.div`
  display: grid;
  flex-grow: 1;
  place-items: center;
`;
