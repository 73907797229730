import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { Colors, NewColors } from "@/themes/Default";

export const Container = styled.div`
  background: white;
  border: 1px solid ${Colors.Glacier};
  border-radius: 1rem;
  color: ${NewColors.TextSpeak};
  line-height: 1.25rem;
  overflow: hidden;
`;

export const Content = styled.div`
  padding: 1rem;
  text-align: center;
`;

export const Copy = styled.p`
  font-size: 0.875rem;
  margin: 0.25rem 0 1rem;
`;

export const Headline = styled.p`
  font-weight: bold;
  margin: 0;
`;

export const Title = styled.h3`
  background: #fafbfe;
  border-bottom: 1px solid ${Colors.Glacier};
  color: ${NewColors.TextWhisper};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin: 0;
  padding: 0.75rem 1rem;
  text-align: left;
  text-transform: uppercase;
`;

export const ErrorContainer = styled.div`
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15rem;
`;

export const Error = styled.div`
  background: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  color: #721c24;
  line-height: 1.25rem;
  padding: 2rem 1rem;
  position: relative;
`;

export const Icon = styled(FontAwesomeIcon)`
  height: 0.7rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  width: 0.7rem;
`;

export const CloseButton = styled.button`
  border: none;
  padding: 0;
`;

export const ConnectedIcon = styled(FontAwesomeIcon)`
  color: ${NewColors.Purple};
`;

export const List = styled.div`
  background: white;
  border: 1px solid ${Colors.Glacier};
  border-radius: 0.5rem;
  color: ${NewColors.Purple};
  line-height: 1.25rem;
  padding: 0.5rem;
  position: absolute;
  transform: translate3d(0.5rem, 0.3rem, 0);
  width: 15rem;
  z-index: 999;
`;

export const ListButton = styled.button`
  align-items: center;
  background-color: #fff;
  border: 0;
  border-radius: 0.5rem;
  color: ${NewColors.TextSpeak};
  display: flex;
  gap: 0.625rem;
  justify-content: flex-start;
  padding: 0.75rem 1.25rem;
  text-align: left;
  width: 100%;

  ${ConnectedIcon} {
    color: ${NewColors.Purple};
    height: 1.25rem;
    width: 1.25rem;
  }

  :hover {
    background-color: ${Colors.Glacier};
    color: ${NewColors.Purple};
  }
`;

export const ListButtonText = styled.span``;

export const ManageIcon = styled(ConnectedIcon)`
  margin-bottom: -0.1rem;
  margin-left: 0.5rem;
`;

export const Balance = styled.div`
  align-items: start;
  display: flex;
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: space-between;
  line-height: 2rem;
  margin: 0;

  & + & {
    margin-top: 0.5rem;
  }
`;

export const WalletAddress = styled.p`
  color: ${Colors.Astronaut};
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
  opacity: 0.8;
  text-align: center;
  width: 100%;
`;

export const Image = styled.img`
  height: 1.5rem;
  margin-bottom: 0.25rem;
  margin-right: 0.5rem;
  width: 1.5rem;
`;
