import type { ReactNode } from "react";

import { Container, Heading, HeadingContainer, InlineButton } from "./styles";

interface Props {
  children: ReactNode;
  className?: string;
  edit?(): void;
  heading?: string;
}

export const Capsule = ({ children, className, edit, heading }: Props) => (
  <Container className={className}>
    {heading && (
      <HeadingContainer>
        <Heading>{heading}</Heading>
        {edit && <InlineButton onClick={edit}>Edit</InlineButton>}
      </HeadingContainer>
    )}

    {children}
  </Container>
);
