import {
  Container,
  Content,
  Name,
  ProjectDetails,
  ProjectLogo,
  ProjectName,
  StyledResponsiveImage,
} from "./styles";

interface NFTCardProps {
  image: string;
  name: string;
  projectName: string;
  projectLogo?: string;
}

export const NFTCard = ({
  image,
  name,
  projectName,
  projectLogo,
}: NFTCardProps) => (
  <Container>
    <StyledResponsiveImage alt="" height={1} src={image} width={1} />

    <Content>
      <ProjectDetails>
        {projectLogo && <ProjectLogo alt="" src={projectLogo} />}
        <ProjectName>{projectName}</ProjectName>
      </ProjectDetails>

      <Name>{name}</Name>
    </Content>
  </Container>
);
