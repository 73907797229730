import type { OperationVariables } from "@apollo/client";

import { EventGrid } from "@/features/events";

import { useAuctions } from "../../hooks/useAuctions";
import { AuctionCard } from "../AuctionCard";

interface Props {
  options?: OperationVariables;
}

export const AuctionGrid = ({ options }: Props) => {
  const { auctions, error, fetchMore, hasMore, loading } = useAuctions(options);

  return (
    <EventGrid
      error={error}
      fetchMore={fetchMore}
      hasMore={hasMore}
      loading={loading}
    >
      {auctions?.map((auction) => (
        <AuctionCard
          key={auction?.node?.id}
          currency={auction?.node?.currency!}
          description={auction?.node?.description}
          image={auction?.node?.heroImage?.url}
          lotCount={auction?.node?.lotCount}
          openingPrice={auction?.node?.lotMinOpeningPrice}
          scheduledStartTime={auction?.node?.scheduledStartTime}
          slug={auction?.node?.slug!}
          state={auction?.node?.state!}
          title={auction?.node?.name!}
        />
      ))}
    </EventGrid>
  );
};
