import { useEffect } from "react";

import { logAmplitudeEvent } from "@/utils/analytics";
import { MANAGE_CHANNEL_EVENTS } from "@/utils/analytics/events";

export const RequestAuction = () => {
  useEffect(() => {
    logAmplitudeEvent(
      MANAGE_CHANNEL_EVENTS.REQUEST_AUCTION.EXTERNAL_FORM_VIEWED,
    );
    window.location.replace("https://47ikz0sjomc.typeform.com/to/xP6tolWN");
  });

  return null;
};
