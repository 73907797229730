import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { Modal } from "@/components/Modal";

import { useConnect } from "../../hooks/useConnect";
import { WalletProvider } from "../../types";
import { getWalletByProvider, Wallets } from "../../utils";
import { MissingWalletNotice } from "../MissingWalletNotice";

import {
  ConnectButton,
  customModalStyles,
  Image,
  Icon,
  ModalContents,
} from "./styles";

interface Props {
  provider: WalletProvider;
  show: boolean;
  setShow: (value: boolean) => void;
}

export const Connect = ({ provider, show, setShow }: Props) => {
  const { noWalletDetected, handleConnect } = useConnect(provider);
  const { provider: walletName } = getWalletByProvider(provider);

  const walletIcon = Wallets[provider].walletIcon;

  return (
    <>
      {noWalletDetected && (
        <Modal
          title="Something went wrong..."
          isOpen={show}
          handleClose={() => setShow(false)}
          customStyles={customModalStyles}
        >
          <ModalContents>
            <MissingWalletNotice
              auctionProvider={provider}
              connect={handleConnect}
            />
          </ModalContents>
        </Modal>
      )}

      <ConnectButton onClick={handleConnect}>
        <span>
          <Image src={walletIcon} />
          {walletName}
        </span>
        <Icon icon={faArrowRight} aria-hidden={true} size="1x" />
      </ConnectButton>
    </>
  );
};
