import { gql } from "@apollo/client";

export const WALLET_AUTH = gql`
  mutation WALLET_AUTH(
    $cryptoWalletId: String!
    $logOutUser: Boolean
    $moralisUserId: String!
    $signature: String!
    $moralisToken: String!
  ) {
    walletAuth(
      cryptoWalletId: $cryptoWalletId
      logOutUser: $logOutUser
      moralisUserId: $moralisUserId
      signature: $signature
      moralisToken: $moralisToken
    ) {
      token
      refreshExpiresIn
      exp
      errors {
        field
        message
      }
    }
  }
`;
