import { faXmark } from "@fortawesome/free-solid-svg-icons";
import type {
  ReactNode,
  KeyboardEvent,
  MouseEvent as ReactMouseEvent,
} from "react";
import ReactModal, { Styles } from "react-modal";

import { CloseButton, CloseIcon, defaultStyles, Header, Title } from "./styles";

interface Props {
  children: ReactNode;
  closeable?: boolean;
  customStyles?: Styles;
  handleClose?: (
    event?: KeyboardEvent<Element> | ReactMouseEvent<Element, MouseEvent>,
  ) => void;
  isOpen: boolean;
  title: string;
}

export const Modal = ({
  children,
  closeable = true,
  customStyles,
  handleClose,
  isOpen = false,
  title,
}: Props) => {
  const modalStyles: Styles = {
    content: { ...defaultStyles.content, ...customStyles?.content },
    /* stylelint-disable */
    overlay: { ...defaultStyles.overlay, ...customStyles?.overlay },
    /* stylelint-enable */
  };

  return (
    <ReactModal
      appElement={document.body}
      contentLabel={title}
      isOpen={isOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={closeable}
      shouldCloseOnEsc={closeable}
      style={modalStyles}
    >
      <Header>
        <Title>{title}</Title>

        {handleClose && (
          <CloseButton onClick={handleClose} aria-label="close">
            <CloseIcon icon={faXmark} />
          </CloseButton>
        )}
      </Header>

      {children}
    </ReactModal>
  );
};
