import {
  ApolloQueryResult,
  OperationVariables,
  useApolloClient,
  useQuery,
} from "@apollo/client";
import { useEffect, useMemo, useState } from "react";

import { AuctionCurrency, AuctionState, LotState } from "@/generated/schema";
import type { AuctionNode, LotNodeEdge } from "@/generated/schema";

import { GET_AUCTION } from "../api/GET_AUCTION";
import { GET_LOT } from "../api/GET_LOT";

interface QueryResponse {
  auction: AuctionNode;
}

export interface Auction {
  id: string | undefined;
  channel: {
    name: string;
    slug: string;
  };
  description: string;
  lotCount: number;
  lotIds: {
    bidding: string[];
    concluded: string[];
    upcoming: string[];
  };
  name: string | undefined;
  image: string;
  pusherChannel: string | null | undefined;
  scheduledStartTime: Date;
  slug: string | undefined;
  state: AuctionState;
  twitchChannelName: string | undefined;
  twitchVideoId: string | undefined;
  youtubeVideoId: string | undefined;
}

export interface AuctionAnalytics {
  "Auction ID": string | undefined;
  "Auction Currency": AuctionCurrency | undefined;
  "Auction Name": string | undefined;
  "Auction Slug": string | undefined;
  "Auction State": AuctionState | undefined;
}

interface useAuctionProps {
  id?: string | undefined;
  slug?: string | undefined;
}

export const useAuction = ({
  id = "",
  slug = "",
}: useAuctionProps): {
  auction: Auction;
  error: boolean;
  loading: boolean;
  auctionAnalytics: AuctionAnalytics;
  refetchAuction(
    variables?: Partial<OperationVariables> | undefined,
  ): Promise<ApolloQueryResult<QueryResponse>>;
} => {
  const client = useApolloClient();
  const { data, error, loading, refetch } = useQuery<QueryResponse>(
    GET_AUCTION,
    {
      variables: { id, slug },
    },
  );
  const [bidding, setBidding] = useState<string[]>([]);
  const [concluded, setConcluded] = useState<string[]>([]);
  const [upcoming, setUpcoming] = useState<string[]>([]);

  const auction = data?.auction;
  const lots = useMemo(() => auction?.lotSet.edges ?? [], [auction]);

  const sortLots = (
    a: LotNodeEdge,
    b: LotNodeEdge,
    direction: "asc" | "desc",
  ) => {
    if (!a?.node?.biddingEndsAfter) return 1;

    const timeA = new Date(a?.node?.biddingEndsAfter).getTime() || -1;
    const timeB = new Date(b?.node?.biddingEndsAfter).getTime() || -1;

    if (direction === "asc") return timeA - timeB;
    return timeB - timeA;
  };

  useEffect(() => {
    if (!lots.length) return;

    const { BIDDING, UPCOMING } = LotState;
    const biddingLots: LotNodeEdge[] = [];
    const concludedLots: LotNodeEdge[] = [];
    const upcomingLots: LotNodeEdge[] = [];

    lots.forEach((lot) => {
      if (!lot?.node) return;

      // Manually update the cache so that future GET_LOT calls use cache first.
      client.writeQuery({
        query: GET_LOT,
        data: { lot: lot?.node },
        variables: { id: lot?.node?.id! },
      });

      if ([BIDDING].includes(lot?.node?.state)) return biddingLots.push(lot);
      if ([UPCOMING].includes(lot?.node?.state)) return upcomingLots.push(lot);

      return concludedLots.push(lot);
    });

    const biddingIds = biddingLots
      .sort((a, b) => sortLots(a, b, "asc"))
      .map((lot) => lot.node?.id!);
    const concludedIds = concludedLots
      .sort((a, b) => sortLots(a, b, "desc"))
      .map((lot) => lot.node?.id!);
    const upcomingIds = upcomingLots
      .sort((a, b) => sortLots(a, b, "asc"))
      .map((lot) => lot.node?.id!);

    setBidding(biddingIds);
    setConcluded(concludedIds);
    setUpcoming(upcomingIds);
  }, [client, lots]);

  return {
    auction: {
      id: auction?.id,
      channel: {
        name: auction?.channel.name ?? "",
        slug: auction?.channel.slug ?? "",
      },
      description: auction?.description ?? "",
      lotCount: lots.length,
      lotIds: { bidding, concluded, upcoming },
      name: auction?.name ?? "",
      image: auction?.heroImage?.url ?? "",
      pusherChannel: auction?.pusherChannelId,
      scheduledStartTime: auction?.scheduledStartTime,
      slug: auction?.slug,
      state: auction?.state || AuctionState.ENDED,
      twitchChannelName: auction?.twitchChannelName,
      twitchVideoId: auction?.twitchVideoId,
      youtubeVideoId: auction?.youtubeVideoId,
    },
    auctionAnalytics: {
      "Auction ID": auction?.id,
      "Auction Currency": auction?.currency,
      "Auction Name": auction?.name,
      "Auction Slug": auction?.slug,
      "Auction State": auction?.state,
    },
    error: !!error || (!auction && !loading),
    loading,
    refetchAuction: refetch,
  };
};
