import styled from "styled-components";

import { CONTENT_WIDTH } from "../../../../themes/Default";

export const ButtonWrapper = styled.div`
  display: grid;
  justify-content: center;
`;

export const Container = styled.div`
  display: grid;
  gap: 1.5rem;
  max-width: ${CONTENT_WIDTH};
`;

export const Grid = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
`;
