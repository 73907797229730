import { OperationVariables } from "@apollo/client";

import { CarouselSection } from "@/features/events";

import { useAuctions } from "../../hooks/useAuctions";
import { AuctionCard } from "../AuctionCard";

interface Props {
  description: string;
  link?: string;
  options: OperationVariables;
  title: string;
}

export const AuctionCarousel = ({
  description,
  link,
  options,
  title,
}: Props) => {
  const { auctions, loading, error } = useAuctions({
    ...options,
    variables: {
      first: 12,
      orderBy: "scheduled_start_time_asc,id",
      ...options.variables,
    },
  });

  return (
    <CarouselSection
      description={description}
      error={error}
      loading={loading}
      link={link}
      title={title}
    >
      {auctions?.map((auction) => (
        <AuctionCard
          key={auction?.node?.id}
          currency={auction?.node?.currency!}
          description={auction?.node?.description}
          image={auction?.node?.heroImage?.url}
          lotCount={auction?.node?.lotCount}
          openingPrice={auction?.node?.lotMinOpeningPrice}
          scheduledStartTime={auction?.node?.scheduledStartTime}
          slug={auction?.node?.slug!}
          state={auction?.node?.state!}
          title={auction?.node?.name!}
        />
      ))}
    </CarouselSection>
  );
};
