import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";

import { Borders, NewColors } from "../../../../themes/Default";

export const Collection = styled.span`
  display: block;
  font-size: 0.875rem;
`;

export const Details = styled.div`
  border-top: ${Borders.Live};
  padding: 1rem;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${NewColors.Purple};
  height: 1.25rem;
  width: 1.25rem;
`;

export const ExternalLink = styled.a`
  align-items: center;
  background-color: #f8fafd;
  border-top: ${Borders.Live};
  color: ${NewColors.Purple};
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  line-height: 1.5rem;
  outline-offset: -1px;
  padding: 1rem;
  position: relative;
  z-index: 1;

  :focus,
  :hover {
    background: #ebf1f9;
    text-decoration: none;
  }

  ::after {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const Heading = styled.h3`
  margin: 0;
`;

export const Name = styled.span`
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 0.5rem;
`;

export const Container = styled.div`
  background: white;
  border: ${Borders.Live};
  border-radius: 0.5rem;
  box-shadow: 0 0 4px 0 rgb(5 21 52 / 8%);
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  position: relative;

  :focus,
  :hover {
    border-color: #6086ce;
    outline: none;
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;
