import styled from "styled-components";

export const Collection = styled.span`
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const Copy = styled.p`
  color: inherit;
  margin: 0;

  & + & {
    margin-top: 0.75rem;
  }

  a {
    color: inherit;
    font-weight: bold;
  }
`;

export const Heading = styled.h3`
  margin: 0;
`;

export const Image = styled.img`
  border-radius: 0.5rem;
  height: 4rem;
  width: 4rem;
`;

export const Info = styled.div`
  padding: 1.5rem;
`;

export const LotBanner = styled.div`
  align-items: flex-end;
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
`;

export const Name = styled.span`
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  margin-top: 0.25rem;
`;
