import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { Alert } from "../../../../components/Alert";
import { Markdown } from "../../../../components/Markdown";
import { TimeBadge } from "../../../../components/TimeBadge";
import { Colors } from "../../../../themes/Default";

export const Container = styled.div`
  color: ${Colors.GreyBlue};
  display: grid;
  gap: 1.5rem;

  @media (min-width: 70rem) {
    gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Description = styled(Markdown)`
  margin-top: 1.5rem;
`;

export const Event = styled.p`
  color: ${Colors.GreyBlue};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1rem;
  margin: 0;
`;

export const Format = styled.span`
  color: ${Colors.Waikawa};
  display: block;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`;

export const FormatHeader = styled.h3`
  color: inherit;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 2.25rem 0 0;
`;

export const FormatDescription = styled.p`
  line-height: 1.5rem;
  margin: 0.5rem 0 0;
`;

export const Header = styled.h1`
  color: inherit;
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.5rem;
  margin: 1.25rem 0 0;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${Colors.Blue};
  height: 1rem;
  margin-right: 0.25rem;
  width: 1rem;
`;

export const StyledAlert = styled(Alert)`
  margin-top: 1.5rem;
`;

export const Time = styled(TimeBadge)`
  margin: 1rem;
  position: absolute;
  z-index: 3;
`;

export const Wrapper = styled.div``;
