import useEmblaCarousel from "embla-carousel-react";
import type { EmblaOptionsType } from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";

export const useCarousel = (options?: EmblaOptionsType) => {
  const [emblaRef, embla] = useEmblaCarousel({
    ...options,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla],
  );

  const onSelect = useCallback(() => {
    if (!embla) return;

    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  const onScroll = useCallback(() => {
    if (!embla) return;

    const progress = Math.max(0, Math.min(1, embla.scrollProgress()));

    setScrollProgress(progress * 100);
  }, [embla, setScrollProgress]);

  useEffect(() => {
    if (!embla) return;

    onSelect();
    setScrollSnaps(embla.scrollSnapList());

    embla.on("resize", onScroll);
    embla.on("resize", onSelect);
    embla.on("scroll", onScroll);
    embla.on("select", onSelect);
  }, [embla, onScroll, setScrollSnaps, onSelect]);

  useEffect(() => {
    if (!embla) return;

    const handleArrowLeft = ({ key, target }: KeyboardEvent) => {
      if (key !== "ArrowLeft") return;

      const targetInside = embla.containerNode().contains(target as Node);

      if (targetInside && prevBtnEnabled) scrollPrev();
    };

    const handleArrowRight = ({ key, target }: KeyboardEvent) => {
      if (key !== "ArrowRight") return;

      const targetInside = embla.containerNode().contains(target as Node);

      if (targetInside && nextBtnEnabled) scrollNext();
    };

    document.addEventListener("keyup", handleArrowLeft);
    document.addEventListener("keyup", handleArrowRight);

    return () => {
      document.removeEventListener("keyup", handleArrowLeft);
      document.removeEventListener("keyup", handleArrowRight);
    };
  }, [embla, prevBtnEnabled, nextBtnEnabled, scrollNext, scrollPrev]);

  return {
    embla,
    emblaRef,
    nextBtnEnabled,
    prevBtnEnabled,
    onSelect,
    scrollNext,
    scrollPrev,
    scrollProgress,
    scrollTo,
    selectedIndex,
    setNextBtnEnabled,
    setPrevBtnEnabled,
    setScrollSnaps,
    scrollSnaps,
  };
};
