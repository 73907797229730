// Settings for the Bidding Deposit wallet
export const DEPOSIT_WALLET_SETTINGS = {
  chainId: process.env.REACT_APP_ETH_NETWORK_ID as unknown as number,
  depositValue: process.env.REACT_APP_ETH_DEPOSITS_VALUE as unknown as number,
  receiver: process.env.REACT_APP_ETH_DEPOSITS_WALLET as unknown as string,
};

export const ETH_CHAINS: { [key: string]: { name: string; network: string } } =
  {
    1: {
      name: "Mainnet",
      network: "0x1",
    },
    5: {
      name: "Goerli",
      network: "0x5",
    },
  };

export const SALES_WALLET_SETTINGS = {
  chainId: process.env.REACT_APP_ETH_NETWORK_ID as unknown as number,
  receiver: process.env.REACT_APP_ETH_SALES_WALLET as unknown as string,
};

export const SOCIAL_LINKS = {
  Discord: "https://discord.gg/7Hk9eMyrcz",
  Twitter: "https://twitter.com/GrailLive",
};
