import { gql } from "@apollo/client";

export const GET_NFT = gql`
  query GET_NFT_DETAILS($id: ID!) {
    nftItem(id: $id) {
      id
      name
      tokenAddress
      importedImageUri
      collection {
        name
      }
      nftitemtraitSet {
        edges {
          node {
            id
            name
            value
          }
        }
      }
    }
  }
`;
