import { gql } from "@apollo/client";

import { LOT_DETAILS } from "./fragments/LOT";

export const GET_LOTS = gql`
  ${LOT_DETAILS}

  query GET_LOTS($state: [String]) {
    lots(state: $state) {
      edges {
        node {
          ...lotDetails
        }
      }
    }
  }
`;
