import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";

import { NFTItemTokenType } from "@/generated/schema";

import { ERC1155 } from "../abis/erc1155";
import { ERC721 } from "../abis/erc721";

interface Props {
  tokenType: NFTItemTokenType | undefined;
  collectionAddress: string | undefined;
  tokenId: string | undefined;
}

export const useApproveTransfer = ({
  tokenType,
  collectionAddress,
  tokenId,
}: Props) => {
  const { REACT_APP_SMART_CONTRACT_ETH_ADDRESS: smartContractAddress } =
    process.env;

  const { account } = useMoralis();

  const tokenIsERC721 = tokenType === NFTItemTokenType.ERC721;
  const abi = tokenIsERC721 ? ERC721 : ERC1155;
  const functionName = tokenIsERC721 ? "approve" : "setApprovalForAll";
  const params = tokenIsERC721
    ? {
        to: smartContractAddress,
        tokenId,
      }
    : {
        operator: smartContractAddress,
        approved: true,
      };

  const { data, error, fetch, isFetching } = useWeb3ExecuteFunction({
    abi,
    contractAddress: collectionAddress,
    functionName,
    params: {
      ...params,
      user: account,
    },
  });

  return {
    data,
    error,
    fetch,
    isFetching,
  };
};
