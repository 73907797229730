import { useEffect, useState } from "react";

import { getContentful } from "../../utils/contentful";

interface Props {
  slug: string;
  children(page: PageType): void;
}

interface PageType {
  body?: string;
  content?: string;
  slug?: string;
  title: string;
}

const QUERY = (slug: string) => `
  query {
    pageCollection(where: { slug: "${slug}" }) {
      items {
        title
        slug
        body
      }
    }
  }
`;

const LOADING: PageType = {
  title: "Loading …",
  content: "",
};

const NOT_FOUND: PageType = {
  title: "Page not found",
  content: "The page you are looking for could not be found at this time.",
};

export const Page = ({ slug, children }: Props) => {
  const [page, setPage] = useState(LOADING);

  useEffect(() => {
    async function fetchData() {
      const { data } = await getContentful(QUERY(slug));
      const hasItems = data?.pageCollection?.items.length > 0;

      if (hasItems) return setPage(data.pageCollection.items[0]);
      return setPage(NOT_FOUND);
    }

    fetchData();
  }, [slug]);

  return <>{children(page)}</>;
};
