import { gql } from "@apollo/client";

export const VERIFY_DISCORD_TOKEN = gql`
  mutation VERIFY_DISCORD_TOKEN($token: String!) {
    verifyDiscordToken(token: $token) {
      errors {
        message
      }
    }
  }
`;
