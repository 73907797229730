import moment from "moment";

import { useTimeRemaining } from "@/hooks/useTimeRemaining";

import { Date } from "./styles";

interface Props {
  className?: string;
  endTime: Date | null | undefined;
  formattedDate?: boolean;
}

export const TimeLeft = ({ className, endTime, formattedDate }: Props) => {
  const { noTimeLeft, days, minutes, seconds, hours } =
    useTimeRemaining(endTime);

  if (noTimeLeft) return <>-</>;

  const formattedEnd = moment(endTime).format("MMM Do - h:mm A");
  const paddedMinutes = String(minutes).padStart(hours ? 2 : 1, "0");
  const paddedSeconds = String(seconds).padStart(2, "0");
  const showMinutes = minutes > 0 && hours >= 0 && days === 0;
  const showSeconds = minutes < 5 && hours === 0 && days === 0;

  return (
    <span className={className}>
      {days > 0 && `${days}d `}
      {hours > 0 && `${hours}h `}
      {showMinutes && `${paddedMinutes}m `}
      {showSeconds && `${paddedSeconds}s `}

      {formattedDate && <Date>({formattedEnd})</Date>}
    </span>
  );
};
