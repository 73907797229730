import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import type { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react";
import type { LinkProps } from "react-router-dom";

import { Anchor, Btn, IconAnchor, Icon, IconLink, Link } from "./styles";
export interface Props {
  $fullWidth?: boolean;
  variant?:
    | "default"
    | "full"
    | "purple"
    | "green"
    | "light-red"
    | "red"
    | "white";
}

export interface IconProps extends Props {
  icon: IconProp;
}

export const AnchorButton = ({
  children,
  className,
  ...props
}: AnchorHTMLAttributes<HTMLAnchorElement> & Props) => (
  <Anchor className={className} {...props}>
    {children}
  </Anchor>
);

export const IconAnchorButton = ({
  children,
  className,
  icon,
  ...props
}: AnchorHTMLAttributes<HTMLAnchorElement> & IconProps) => (
  <IconAnchor className={className} {...props}>
    {children}

    <Icon icon={icon} />
  </IconAnchor>
);

export const Button = ({
  children,
  className,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & Props) => (
  <Btn className={className} {...props}>
    {children}
  </Btn>
);

export const LinkButton = ({
  className,
  children,
  to,
  ...props
}: LinkProps & Props) => (
  <Link className={className} to={to} {...props}>
    {children}
  </Link>
);

export const IconLinkButton = ({
  children,
  className,
  icon,
  to,
  ...props
}: LinkProps & IconProps) => (
  <IconLink className={className} to={to} {...props}>
    {children}

    <Icon icon={icon} />
  </IconLink>
);
