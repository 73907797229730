import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Styles } from "react-modal";
import styled from "styled-components";

import { Borders, NewColors } from "@/themes/Default";

export const customModalStyles: Styles = {
  content: {
    borderRadius: "0.75rem",
    maxWidth: "37.5rem",
    maxHeight: "80%",
    overflow: "auto",
  },
};

const Button = styled.button`
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  color: white;
  display: inline-block;
  font-weight: 700;
  padding: 0.75rem;
  text-align: center;

  :active,
  :focus,
  :hover {
    transition: 200ms ease-in-out;
  }
`;

export const Connect = styled(Button)`
  background-color: ${NewColors.Primary};
  border: 1px solid ${NewColors.Primary};

  :active,
  :focus,
  :hover {
    background-color: ${NewColors.PrimaryDarkened};
    border-color: ${NewColors.PrimaryDarkened};
  }
`;

export const Container = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "label"
    "control";
  justify-content: space-between;

  @media (min-width: 35rem) {
    grid-template-areas: "label control";
  }
`;

export const Control = styled.div`
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-area: control;
  grid-template-columns: 1fr auto;
`;

export const Disconnect = styled(Button)`
  background-color: ${NewColors.Negative};
  border: 1px solid ${NewColors.Negative};

  :active,
  :focus,
  :hover {
    background-color: ${NewColors.NegativeDarkened};
    border-color: ${NewColors.NegativeDarkened};
  }
`;

export const DisconnectConfirmation = styled(Disconnect)`
  border-radius: 0.5rem;
`;

export const Error = styled.span`
  color: ${NewColors.Negative};
`;

export const InfoButton = styled.button`
  background: transparent;
  border: 2px solid ${NewColors.Primary};
  border-radius: 50%;
  color: ${NewColors.Primary};
  display: grid;
  height: 1.75rem;
  place-content: center;
  width: 1.75rem;

  :active,
  :focus,
  :hover {
    background-color: ${NewColors.PrimaryOverlay};
  }
`;

export const Input = styled.input`
  border: ${Borders.User};
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  color: ${NewColors.TextShout};
  font-weight: bold;
  line-height: 1.25rem;
  outline: 0.25rem;
  padding: 0.75rem;
  width: 100%;

  :disabled {
    background-color: ${NewColors.Deadfill};
  }

  ::placeholder {
    color: ${NewColors.TextWhisper};
  }
`;

export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 7rem;
`;

export const Label = styled.div`
  align-items: center;
  color: ${NewColors.TextSpeak};
  display: flex;
  gap: 0.5rem;
  grid-area: label;
`;

export const Logo = styled(FontAwesomeIcon)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const ModalCopy = styled.p`
  margin: 0;
`;

export const ModalContents = styled.div`
  display: grid;
  gap: 1rem;
  padding: 1.5rem;
`;

export const Name = styled.p`
  font-weight: bold;
  line-height: 1.25rem;
  margin: 0;
`;

export const DiscordLink = styled.a`
  align-items: center;
  background-color: ${NewColors.DiscordBlurple};
  border-radius: 0.5rem;
  color: white;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
  padding: 1rem;

  :active,
  :focus,
  :hover {
    background-color: ${NewColors.DiscordBlurpleDarkened};
    box-shadow: 0 2px 0 ${NewColors.DiscordBlurpleShadow};
    color: white;
    text-decoration: none;
    transform: translateY(-2px);
    transition: 200ms ease-in-out;
  }
`;
