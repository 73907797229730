import moment from "moment";

import { EventEventType } from "../../generated/schema";

import { Pill } from "./styles";

interface Props {
  className?: string;
  event?: EventEventType | null;
  scheduledStartTime: Date | null;
  state: string;
  STATES: {
    [key: string]: string;
  };
}

export const TimeBadge = ({
  className,
  event,
  state,
  scheduledStartTime,
  STATES,
}: Props) => {
  const isBreakWhenFilled =
    !scheduledStartTime && event === EventEventType.BREAK;
  let isLive = false;
  let isFinished = false;
  let startTimeLabel;

  switch (state) {
    case STATES["PUBLISHED"]:
    case STATES["PUBLISHED_OPEN"]:
    case STATES["PUBLISHED_LOCKED"]:
      if (scheduledStartTime) {
        startTimeLabel = `${moment(scheduledStartTime).format(
          "MMM Do - h:mm A",
        )}`;
      } else {
        startTimeLabel = isBreakWhenFilled
          ? "Breaks when filled…"
          : "Coming soon…";
      }
      break;
    case STATES["LIVE"]:
    case STATES["LIVE_OPEN"]:
    case STATES["LIVE_LOCKED"]:
      startTimeLabel = "🔥 LIVE NOW!";
      isLive = true;
      break;
    case STATES["ENDED"]:
    case STATES["SETTLED"]:
      startTimeLabel = scheduledStartTime
        ? `Completed ${moment(scheduledStartTime).format("MMM Do")}`
        : "Completed";
      isFinished = true;
      break;
    case STATES["CANCELED"]:
      startTimeLabel = "Canceled";
      isFinished = true;
      break;
    default:
      startTimeLabel = "Coming soon…";
      break;
  }

  return (
    <Pill className={className} isFinished={isFinished} isLive={isLive}>
      {startTimeLabel}
    </Pill>
  );
};
