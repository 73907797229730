import { Container, Content, Copy, Heading } from "./styles";

interface Props {
  copy: string;
  heading?: string;
}

export const EmptyTab = ({ copy, heading = "Nothing to see here…" }: Props) => (
  <Container>
    <Content>
      <Heading>{heading}</Heading>
      <Copy>{copy}</Copy>
    </Content>
  </Container>
);
