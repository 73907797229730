import { useState } from "react";

import { Modal, smallModalStyle } from "@/components/Modal";

import { AddLot } from "../AddLot";
import { SelectNFT } from "../SelectNFT";

const TEMP_ITEMS = [
  {
    id: "1",
    name: "Temp Item #1",
  },
  {
    id: "2",
    name: "Temp Item #2",
  },
  {
    id: "3",
    name: "Temp Item #3",
  },
  {
    id: "4",
    name: "Temp Item #4",
  },
];

interface AddModalProps {
  handleToggle(): void;
  show: boolean;
}

type FormStage = "SELECT" | "SUBMIT";

export const AddModal = ({ handleToggle, show }: AddModalProps) => {
  const [formStage, setFormStage] = useState<FormStage>("SELECT");
  const [selectedId, setSelectedId] = useState("");

  const handleContinue = () => setFormStage("SUBMIT");
  const handleGoBack = () => setFormStage("SELECT");

  if (formStage === "SUBMIT") {
    return (
      <Modal
        customStyles={{
          ...smallModalStyle,
          content: {
            ...smallModalStyle.content,
            maxHeight: "95vh",
          },
        }}
        handleClose={handleToggle}
        isOpen={show}
        title="Add lot"
      >
        <AddLot handleGoBack={handleGoBack} />
      </Modal>
    );
  }

  return (
    <Modal
      customStyles={{
        ...smallModalStyle,
        content: {
          ...smallModalStyle.content,
          maxHeight: "95vh",
          maxWidth: "69rem",
        },
      }}
      handleClose={handleToggle}
      isOpen={show}
      title="Add lot"
    >
      <SelectNFT
        handleContinue={handleContinue}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        TEMP_ITEMS={TEMP_ITEMS}
      />
    </Modal>
  );
};
