import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { NewColors } from "@/themes/Default";

export const Block = styled.div`
  display: grid;
  gap: 1rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  width: 100%;
`;

export const Heading = styled.h2`
  color: rgb(31 31 35 / 50%);
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin: 0;
  text-transform: uppercase;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: #f9fbff;
  height: 0.9rem;
  width: 0.9rem;
`;

export const IconContainer = styled.div`
  background: rgb(31 31 35 / 50%);
  border-radius: 50%;
  display: grid;
  height: 1.75rem;
  place-items: center;
  width: 1.75rem;
`;

export const Link = styled(NavLink)<{ $active: boolean }>`
  color: rgb(31 31 35 / 50%);
  display: grid;
  gap: 0.5rem;
  grid-template-columns: auto 1fr;

  :active,
  :focus,
  :hover {
    color: ${NewColors.Purple};
    text-decoration: none;

    ${IconContainer} {
      background: ${NewColors.Purple};
    }
  }

  ${({ $active }) =>
    $active &&
    css`
      color: ${NewColors.Purple};

      ${IconContainer} {
        background: ${NewColors.Purple};
      }
    `}
`;

export const Links = styled.div`
  display: grid;
  gap: 1.25rem;
`;

export const LinkText = styled.span`
  font-weight: bold;
  line-height: 1.5rem;
`;
