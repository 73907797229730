import { gql } from "@apollo/client";

export const GET_REMINTS = gql`
  query GET_REMINTS(
    $cursor: String
    $first: Int = 48
    $featured: Boolean
    $query: String
    $orderBy: String
    $spotsAvailable: Boolean
    $state: [String]
  ) {
    remints(
      after: $cursor
      first: $first
      featured: $featured
      orderBy: $orderBy
      query: $query
      spotsAvailable: $spotsAvailable
      state: $state
    ) {
      edges {
        node {
          id
          featuredRank
          name
          scheduledStartTime
          slug
          spotsAvailableCount
          spotsTotalCount
          spotsCurrency
          spotsMinPrice
          state
          twitchChannelName
          youtubeVideoId

          boxbreakphotoSet(first: 1) {
            edges {
              node {
                photoMd
              }
            }
          }
        }
      }

      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
