import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import { render } from "react-dom";
import { Helmet } from "react-helmet";
import { MoralisProvider } from "react-moralis";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { NetworkChecker } from "@/components/NetworkChecker";
import { ScrollToTop } from "@/components/ScrollToTop";
import { getMoralisEnvData } from "@/features/wallet";
import { GlobalStyles } from "@/themes/GlobalStyles";
import { apolloClient } from "@/utils/apolloConfig";
import { initFacebookPixel, logFacebookPageView } from "@/utils/facebook";
import { initGTM } from "@/utils/gtm";

import App from "./App";
import "./index.scss";

Sentry.init({
  dsn: "https://3ed521350b19428e91e5db20c62d0529@o402342.ingest.sentry.io/5263354",

  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1.0,
});

const Root = () => {
  const { serverUrl, appId } = getMoralisEnvData();

  initGTM();
  // Init FB pixel and log every pageview (additional events logged elsewhere)
  initFacebookPixel();
  logFacebookPageView();

  return (
    <BrowserRouter>
      <Helmet
        defaultTitle="Grail - Live NFT auctions"
        titleTemplate="%s | Grail"
      />
      <GlobalStyles />
      <ApolloProvider client={apolloClient}>
        <MoralisProvider serverUrl={serverUrl} appId={appId}>
          <ScrollToTop />
          <CookiesProvider>
            <NetworkChecker />
            <App />
          </CookiesProvider>
        </MoralisProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
};

render(<Root />, document.getElementById("root"));
