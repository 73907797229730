import { useMutation } from "@apollo/client";
import { useState } from "react";

import { Error, Input, SubmitButton, Success } from "@/components/FormElements";
import {
  Modal,
  ModalButtonContainer,
  ModalContent,
  smallModalStyle,
} from "@/components/Modal";
import { AuctionCreate } from "@/generated/schema";
import { useForm } from "@/hooks/useForm";

import { AUCTION_CREATE } from "../../api/AUCTION_CREATE";
import { useChannel } from "../../hooks/useChannel";

interface CreateAuctionModalProps {
  channelSlug: string;
  handleClose(): void;
  show: boolean;
}

interface MutationResponse {
  auctionCreate: AuctionCreate;
}

export const CreateAuctionModal = ({
  channelSlug,
  handleClose,
  show,
}: CreateAuctionModalProps) => {
  const { channel, refetch } = useChannel(channelSlug);

  const { formState, handleInputChange } = useForm({
    title: "",
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [auctionCreate, { loading: submitting }] =
    useMutation<MutationResponse>(AUCTION_CREATE, {
      onError: () => setError(true),
      onCompleted: ({ auctionCreate: { errors } }) => {
        const hasErrors = !!errors?.length;
        setError(hasErrors);

        if (!hasErrors) {
          refetch();
          setSuccess(true);
        }
      },
      variables: {
        channelId: channel?.id,
        name: formState.title,
      },
    });

  const handleSubmit = () => {
    if (formState.title) auctionCreate();
  };

  return (
    <>
      {show && (
        <Modal
          customStyles={smallModalStyle}
          handleClose={handleClose}
          isOpen={show}
          title="Create new auction"
        >
          <ModalContent>
            <Input
              defaultValue={formState.title}
              disabled={submitting || success}
              error={!formState.title}
              errorMessage="Title is required"
              handleInput={handleInputChange}
              label="Auction title"
              name="title"
              type="text"
            />

            <ModalButtonContainer>
              <SubmitButton
                disabled={!formState.title || submitting || success}
                onClick={handleSubmit}
                submitting={submitting}
                variant="green"
              >
                Continue
              </SubmitButton>
            </ModalButtonContainer>

            {error && <Error>❌ Something went wrong, please try again!</Error>}
            {success && <Success>✅ Draft auction has been created</Success>}
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
