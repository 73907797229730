import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { SOCIAL_LINKS } from "@/settings";

import { Borders, Breakpoints, Colors } from "../themes/Default";
import { logAmplitudeEvent } from "../utils/analytics";
import { SUPPORT_EVENTS } from "../utils/analytics/events";

const btn = css`
  background: ${Colors.Havelock}
    linear-gradient(180deg, #699cda, ${Colors.Havelock}) repeat-x;
  border: 1px solid ${Colors.Havelock};
  border-radius: 50rem;
  color: #fff;
  display: inline-flex;
  font-size: 1.25rem;
  gap: 1rem;
  line-height: 1.5;
  max-width: fit-content;
  padding: 0.5rem 1rem;
  place-items: center;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

  :focus,
  :hover {
    background: #3176cb linear-gradient(180deg, #508ad2, #3176cb) repeat-x;
    border-color: #2f70c0;
    color: #fff;
  }
`;

const ButtonAnchor = styled.a`
  ${btn}
`;

const ButtonLink = styled(Link)`
  ${btn}
`;

const Container = styled.div`
  background: white;
  border: ${Borders.Standalone};
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgb(0 0 32 / 10%);
  height: 100%;
  margin: 0 auto;
  max-width: 48rem;
  padding: 1.25rem;

  @media (min-width: ${Breakpoints.Large}) {
    padding: 2.75rem;
  }
`;

const Header = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

const Links = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 1rem;

  @media (min-width: 36.5rem) {
    flex-direction: row;
  }
`;

const Section = styled.div`
  & + & {
    margin-top: 3rem;
  }
`;

const Title = styled.h1`
  border-bottom: ${Borders.Standalone};
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
`;

const Wrapper = styled.section`
  height: 100%;

  @media (min-width: ${Breakpoints.Large}) {
    padding: 3rem 0;
  }
`;

export const Support = () => {
  useEffect(() => logAmplitudeEvent(SUPPORT_EVENTS.SUPPORT.PAGE_VIEWED));

  return (
    <>
      <Helmet>
        <title>Support</title>
      </Helmet>

      <Wrapper>
        <Container>
          <Title>Support</Title>

          <Section>
            <Header>Frequently asked questions</Header>

            <p>
              Check out our frequently asked questions, which cover the most
              common topics we&#39;re asked about.
            </p>

            <ButtonLink to="/faq">View our FAQs</ButtonLink>
          </Section>

          <Section>
            <Header>Contact us</Header>
            <p>
              If you didn&#39;t find the answer you needed, let us know how we
              can help! You can contact us via Discord, Twitter or email and
              we&#39;ll get back to you as soon as we can.
            </p>

            <Links>
              <ButtonAnchor href={SOCIAL_LINKS.Discord}>
                <FontAwesomeIcon icon={faDiscord} size="lg" />
                Discord
              </ButtonAnchor>

              <ButtonAnchor href={SOCIAL_LINKS.Twitter}>
                <FontAwesomeIcon icon={faTwitter} size="lg" />
                Twitter
              </ButtonAnchor>

              <ButtonAnchor href="mailto:support@grail.live ">
                <FontAwesomeIcon icon={faEnvelope} size="lg" />
                support@grail.live
              </ButtonAnchor>
            </Links>
          </Section>
        </Container>
      </Wrapper>
    </>
  );
};
