import styled from "styled-components";

import { Colors } from "../../themes/Default";

export const Contents = styled.div`
  margin-top: 1.75rem;
`;

export const Description = styled.p`
  color: #294273;
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin: 0.5rem 0 0;
`;

export const Heading = styled.h2`
  color: ${Colors.Blue};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  margin: 0;
`;

export const HeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;
