import styled from "styled-components";

import { Section } from "@/components/Section";
import { Breakpoints } from "@/themes/Default";

export const Children = styled.div`
  margin-top: 1.5rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    margin-top: 2.5rem;
  }
`;

export const Container = styled(Section)`
  background: radial-gradient(#8976d2 0%, #1c114f 100%);
  color: white;
`;

export const Description = styled.p`
  color: #d3dbe9;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 0.5rem 0 0;
`;

export const Heading = styled.h2`
  font-weight: bold;
  margin: 0;
`;

export const Subtitle = styled.span`
  color: white;
  display: block;
  font-size: 2rem;
  line-height: 2.5rem;
  margin-top: 0.5rem;
`;

export const Title = styled.span`
  color: #a4b1ca;
  display: block;
  font-size: 1rem;
  line-height: 1.25rem;
  text-transform: uppercase;
`;
