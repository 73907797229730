import { useMutation } from "@apollo/client";
import { captureException } from "@sentry/react";
import { useState } from "react";

import { Button } from "@/components/Button";
import { useCompleteSale } from "@/features/smartContract";

import { UPDATE_LOT_STATE_TO_COMPLETING_SALE } from "../../../../api/UPDATE_LOT_STATE_TO_COMPLETING_SALE";
import { useUpdateCache } from "../../../../hooks/useUpdateCache";

import {
  ButtonContainer,
  Container,
  Contents,
  Copy,
  Fee,
  Instructions,
  Label,
  PaymentContainer,
  Price,
  Pricing,
} from "./styles";

interface Props {
  buyersPremiumFee: number;
  buyersPremiumPercentage: number;
  finalPurchasePrice: number;
  lotId: string;
  smartContractLotId: number;
  hasSaleCompleted: boolean;
  purchasePrice: number;
}

type MoralisError = Error & { code: string };

export const PaymentDetails = ({
  buyersPremiumFee,
  buyersPremiumPercentage,
  finalPurchasePrice,
  lotId,
  smartContractLotId,
  hasSaleCompleted,
  purchasePrice,
}: Props) => {
  const { fetch } = useCompleteSale({
    smartContractLotId,
    value: finalPurchasePrice,
  });
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const { refetchLot } = useUpdateCache();

  const [updateLotStateToCompletingSale] = useMutation(
    UPDATE_LOT_STATE_TO_COMPLETING_SALE,
    {
      variables: { lotId },
      onCompleted({ updateLotStateToCompletingSale: { errors } }) {
        if (error && errors.length > 0) {
          setError(errors[0].message ?? "Sorry, something went wrong.");
          setSubmitting(false);
        } else {
          refetchLot();
        }
      },
    },
  );

  const handleClick = () => {
    setSubmitting(true);

    fetch({
      onError: (err) => {
        if ((err as MoralisError).code === "INSUFFICIENT_FUNDS") {
          setError(
            "Sorry, you currently don't have enough funds in your wallet.",
          );
        } else {
          setError("Sorry, something went wrong.");
        }

        setSubmitting(false);
        captureException(err, (scope) => {
          scope.setTransactionName("PaymentDetails - handleClick - onError");
          return scope;
        });
      },
      onSuccess: () => {
        updateLotStateToCompletingSale();
      },
    });
  };

  return (
    <Container>
      <Contents>
        <Pricing>
          <Price>
            <Label>Hammer price:</Label>
            <span>{purchasePrice} ETH</span>
          </Price>
          <Price>
            <Label>
              Buyer&apos;s premium <Fee>({buyersPremiumPercentage * 100}%)</Fee>
              :
            </Label>
            <span>{buyersPremiumFee} ETH</span>
          </Price>
          <Price large>
            <Label>Total payable:</Label>
            <span>{finalPurchasePrice} ETH</span>
          </Price>
        </Pricing>

        <Instructions>
          {hasSaleCompleted ? (
            <PaymentContainer>
              <Copy>
                Thanks for your payment! Enjoy your NFT and contact support if
                you have any questions.
              </Copy>
            </PaymentContainer>
          ) : (
            <>
              <PaymentContainer>
                <ButtonContainer>
                  {error && <Copy>{error}</Copy>}

                  <Button
                    disabled={submitting}
                    onClick={handleClick}
                    variant="purple"
                  >
                    Complete sale in MetaMask
                  </Button>
                </ButtonContainer>
              </PaymentContainer>
            </>
          )}
        </Instructions>
      </Contents>
    </Container>
  );
};
