import { useState } from "react";

import { Button } from "@/components/Button";
import {
  Modal,
  ModalContent,
  ModalCopy,
  smallModalStyle,
} from "@/components/Modal";

import { useDraftAuction } from "../../hooks/useDraftAuction";
import { ContentSection, Label } from "../Capsule";

import { DeleteDraftModal } from "./components/DeleteDraftModal";
import { ButtonContainer, Container, Controls, State } from "./styles";

// TODO: ADD IN MUTATIONS

interface Props {
  auctionId: string;
}

export const AuctionStatus = ({ auctionId }: Props) => {
  const { auction, canBePublished, isDraft, isPublished } =
    useDraftAuction(auctionId);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showGoLiveModal, setShowGoLiveModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);

  const toggleCancelModal = () => setShowCancelModal(!showCancelModal);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const toggleGoLiveModal = () => setShowGoLiveModal(!showGoLiveModal);
  const togglePublishModal = () => setShowPublishModal(!showPublishModal);

  return (
    <>
      {showCancelModal && (
        <Modal
          customStyles={smallModalStyle}
          handleClose={toggleCancelModal}
          isOpen={showCancelModal}
          title="Cancel auction"
        >
          <ModalContent>
            <ModalCopy>
              Are you sure you wish to cancel this auction? Once canceled it
              cannot be restored.
            </ModalCopy>

            <ButtonContainer>
              <Button onClick={() => alert("To be implemented")} variant="red">
                Yes, cancel auction
              </Button>
            </ButtonContainer>
          </ModalContent>
        </Modal>
      )}

      <DeleteDraftModal
        auctionId={auctionId}
        handleToggle={toggleDeleteModal}
        show={showDeleteModal}
        channelSlug={auction?.channel.slug!}
      />

      {showGoLiveModal && (
        <Modal
          customStyles={smallModalStyle}
          handleClose={toggleGoLiveModal}
          isOpen={showGoLiveModal}
          title="Start live show"
        >
          <ModalContent>
            <ModalCopy>
              Once you start the show, we’ll feature the auction in the LIVE
              section of the site, and embed the live stream from your
              associated streaming service as configured. Be sure your stream is
              up and running before you continue!
            </ModalCopy>

            <ButtonContainer>
              <Button
                onClick={() => alert("To be implemented")}
                variant="green"
              >
                Go live!
              </Button>
            </ButtonContainer>
          </ModalContent>
        </Modal>
      )}

      {showPublishModal && (
        <Modal
          customStyles={smallModalStyle}
          handleClose={togglePublishModal}
          isOpen={showPublishModal}
          title="Publish auction"
        >
          <ModalContent>
            {canBePublished ? (
              <>
                <ModalCopy>Ready to publish this auction?</ModalCopy>
                <ModalCopy>
                  Once you’ve published it, you will not be able to edit all
                  aspects of the auction. Be sure you’re happy with the event
                  details, live show configured, and lots included before you
                  continue!
                </ModalCopy>

                <ButtonContainer>
                  <Button
                    onClick={() => alert("To be implemented")}
                    variant="green"
                  >
                    Yup, publish my auction!
                  </Button>
                </ButtonContainer>
              </>
            ) : (
              <>
                <ModalCopy>
                  Sorry, this auction isn’t ready to publish yet!
                </ModalCopy>
                <ModalCopy>
                  You need to fully configure the event details, live show, and
                  add at least one lot before you can publish it.
                </ModalCopy>

                <ButtonContainer>
                  <Button
                    onClick={() => alert("To be implemented")}
                    variant="purple"
                  >
                    Got it! Continue editing
                  </Button>
                </ButtonContainer>
              </>
            )}
          </ModalContent>
        </Modal>
      )}

      <Container>
        <ContentSection>
          <Label>Event status</Label>
          <State>{auction?.state.toLowerCase()}</State>
        </ContentSection>

        {isDraft && (
          <Controls>
            <Button onClick={toggleDeleteModal} variant="light-red">
              Delete draft
            </Button>

            <Button onClick={togglePublishModal} variant="green">
              Publish auction
            </Button>
          </Controls>
        )}

        {isPublished && (
          <Controls>
            <Button onClick={toggleCancelModal} variant="light-red">
              Cancel auction
            </Button>

            <Button onClick={toggleGoLiveModal} variant="green">
              Start live show
            </Button>
          </Controls>
        )}
      </Container>
    </>
  );
};
