import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Styles } from "react-modal";
import styled from "styled-components";

import { Colors } from "@/themes/Default";

export const Icon = styled(FontAwesomeIcon)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const ConnectButton = styled.button`
  align-items: center;
  background: rgb(235 241 251 / 40%);
  border: none;
  color: ${Colors.GreyBlue};
  display: flex;
  font-size: 1.125rem;
  font-weight: bold;
  justify-content: space-between;
  line-height: 1.5rem;
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;

  :hover {
    background-color: ${Colors.Glacier};
  }
`;

export const customModalStyles: Styles = {
  content: {
    borderRadius: "0.75rem",
    maxWidth: "37.5rem",
    maxHeight: "28rem",
    overflow: "hidden",
  },
};

export const Image = styled.img`
  height: 2.5rem;
  margin-right: 0.75rem;
  width: 2.5rem;
`;

export const ModalContents = styled.div`
  padding: 1.5rem;
`;
