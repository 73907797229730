import type { OperationVariables } from "@apollo/client";
import { faGavel } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { logAmplitudeEvent } from "@/utils/analytics";
import { EVENTS_EVENTS } from "@/utils/analytics/events";

import { AuctionGrid } from "../../../nftAuctions";
import { RemintGrid } from "../../../remints";

import { Button, Buttons, Controls, Icon, Listings, Wrapper } from "./styles";

type Event = "auctions" | "remints";

interface Props {
  basePath: string;
  auctionOptions?: OperationVariables;
  remintOptions?: OperationVariables;
}

export const EventListings = ({
  auctionOptions,
  basePath,
  remintOptions,
}: Props) => {
  const navigate = useNavigate();
  const { event } = useParams();

  const defaultTab =
    event === "auctions" || event === "remints" ? event : "auctions";

  const [activeTab, setActiveTab] = useState<Event>(defaultTab);

  const auctionActive = activeTab === "auctions";
  const remintActive = activeTab === "remints";

  const updateActiveTab = (selected: Event) => {
    setActiveTab(selected);
    navigate(`${basePath}/${selected}`);
  };

  useEffect(() => {
    logAmplitudeEvent(EVENTS_EVENTS.PAST_EVENTS.PAGE_VIEWED, {
      "Event Type": activeTab,
    });
  }, [activeTab]);

  return (
    <>
      <Controls>
        <Wrapper>
          <Buttons>
            <Button
              active={auctionActive}
              onClick={() => updateActiveTab("auctions")}
            >
              <Icon icon={faGavel} /> Auctions
            </Button>
          </Buttons>
        </Wrapper>
      </Controls>

      <Listings>
        <Wrapper>
          <div hidden={!auctionActive}>
            <AuctionGrid options={auctionOptions} />
          </div>

          <div hidden={!remintActive}>
            <RemintGrid options={remintOptions} />
          </div>
        </Wrapper>
      </Listings>
    </>
  );
};
