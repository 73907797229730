import { Link } from "react-router-dom";
import styled from "styled-components";

import { Borders, NewColors } from "@/themes/Default";

export const Container = styled.div`
  background-color: white;
  border: ${Borders.User};
  border-radius: 1rem;
  box-shadow: 0 8px 16px 0 rgb(74 75 79 / 20%);
  display: grid;
  gap: 1.5rem;
  height: min-content;
  padding: 2rem;
  width: 100%;
`;

export const Heading = styled.h2`
  color: ${NewColors.Purple};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  margin: 0;
`;

export const HeadingContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;

export const InlineButton = styled.button`
  background: transparent;
  border: none;
  color: ${NewColors.Green};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  outline-offset: 0.5rem;
  padding: 0;
`;

export const InlineLink = styled(Link)`
  color: ${NewColors.Green};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  outline-offset: 0.5rem;

  :active,
  :focus,
  :hover {
    color: ${NewColors.Green};
    text-decoration: none;
  }
`;

// EXTERNAL SHARED - TIDY
export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: bold;
  gap: 0.5rem;
  line-height: 1rem;
`;

export const Label = styled.h3`
  color: ${NewColors.Black};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 0;
`;

export const Placeholder = styled.p`
  color: ${NewColors.TextWhisper};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin: 0;
`;

export const Copy = styled.p`
  color: ${NewColors.TextSpeak};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin: 0;
`;
