import { SpotPriceCurrency } from "../generated/schema";

export const formatCurrency = (
  amount: number | null | undefined,
  currency: SpotPriceCurrency | string | null | undefined,
) => {
  if (typeof amount === "undefined" || amount === null) return "";

  const isDollar = currency === SpotPriceCurrency.USD || !currency;

  if (isDollar) {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  }

  const amountCrypto = amount.toLocaleString("en", {
    maximumFractionDigits: 5,
    minimumFractionDigits: 0,
  });

  return `${amountCrypto} ${currency}`;
};
