import styled from "styled-components";

import { Button } from "@/components/Button";

import { Borders, Breakpoints, Colors } from "../../../../themes/Default";
import { Info } from "../PlaceBid/components/Feedback/styles";

export const Bid = styled.div`
  display: grid;
  gap: 0.75rem;
`;

export const BidEntry = styled.form`
  display: grid;
  gap: 0.75rem;
  grid-template-areas:
    "label label"
    "input submit";
  grid-template-columns: 1fr 6.875rem;
`;

export const BidLabel = styled.label`
  color: ${Colors.Blue};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 0;
`;

export const BidLabelContainer = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  grid-area: label;
  justify-content: space-between;

  @media (min-width: ${Breakpoints.Mobile}) {
    flex-direction: row;
  }
`;

export const FeeNotice = styled(Info)`
  line-height: 1.25rem;
  margin: 0;
`;

export const Currency = styled.span`
  align-self: center;
  color: ${Colors.Blue};
  font-weight: bold;
  left: 1rem;
  line-height: 1.5rem;
  position: absolute;
`;

export const Input = styled.input`
  border: ${Borders.Live};
  border-radius: 0.5rem;
  padding-left: 3.75rem;
  width: 100%;
`;

export const InputWrapper = styled.div`
  display: grid;
  grid-area: input;
  position: relative;
`;

export const Submit = styled(Button)`
  grid-area: submit;
`;

export const Wrapper = styled.div`
  padding: 1rem;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 1.5rem;
  }

  & + & {
    background: #f7f9fd;
    border-top: ${Borders.Live};
  }
`;
