import { gql } from "@apollo/client";

export const ADD_TEAM_MEMBER = gql`
  mutation ADD_TEAM_MEMBER(
    $channelId: ID!
    $role: String!
    $walletAddress: String!
  ) {
    addTeamMemberToChannel(
      channelId: $channelId
      role: $role
      walletAddress: $walletAddress
    ) {
      errors {
        field
        message
      }
    }
  }
`;
