import type { ReactNode } from "react";
import styled from "styled-components";

import { Borders, Breakpoints, Colors } from "../themes/Default";

interface Props {
  children: ReactNode;
}

const Wrapper = styled.section`
  height: 100%;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 3rem 0;
  }
`;

const Container = styled.div`
  background: white;
  border: ${Borders.Standalone};
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgb(0 0 32 / 10%);
  height: 100%;
  margin: 0 auto;
  max-width: 48rem;

  @media (min-width: ${Breakpoints.Large}) {
    padding: 1.5rem;
  }
`;

const CMSContent = styled.div`
  line-height: 1.5rem;
  padding: 1.25rem;

  h1 {
    border-bottom: ${Borders.Standalone};
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 2rem;
    padding-bottom: 0.5rem;

    :first-child {
      margin-top: 0;
    }
  }

  h2,
  h3,
  h4 {
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 2rem;
    padding: 0;
  }

  p {
    margin-bottom: 1rem;
    padding: 0;
  }

  ol,
  ul {
    margin: 0.5rem 1rem 1rem;
    padding: 0 1rem;
  }

  li {
    margin: 0.5rem 0;
    padding: 0 0 0 0.5rem;

    li {
      padding: 0;
    }

    :last-child {
      padding-bottom: 0;
    }
  }

  li > ol,
  li > ul {
    padding-bottom: 0;
  }

  strong {
    color: ${Colors.Astronaut};
  }

  table {
    border: ${Borders.Standalone};
    box-shadow: 0 0.25rem 0.5rem rgb(0 0 32 / 10%);
    width: 100%;

    th {
      background: ${Colors.Havelock};
      color: white;
    }

    tr {
      td,
      th {
        border: ${Borders.Standalone};
        padding: 0.75rem;
        vertical-align: top;
      }
    }
  }

  .footnote-backref {
    display: none;
  }
`;

export const ContentWrapper = ({ children }: Props) => (
  <Wrapper>
    <Container>
      <CMSContent>{children}</CMSContent>
    </Container>
  </Wrapper>
);
