import { useQuery } from "@apollo/client";
import type { OperationVariables } from "@apollo/client";

import type { AuctionNodeConnection } from "@/generated/schema";

import { GET_AUCTIONS } from "../api/GET_AUCTIONS";

interface QueryResponse {
  auctions: AuctionNodeConnection;
}

export const useAuctions = (options?: OperationVariables) => {
  const { data, error, fetchMore, loading } = useQuery<QueryResponse>(
    GET_AUCTIONS,
    {
      ...options,
      notifyOnNetworkStatusChange: true,
    },
  );

  const auctions = data?.auctions.edges ?? [];
  const cursor = data?.auctions.pageInfo.endCursor;
  const hasMore = data?.auctions.pageInfo.hasNextPage ?? false;

  return {
    auctions,
    error: !!error,
    fetchMore: () => fetchMore({ variables: { cursor } }),
    hasMore,
    loading,
  };
};
