import { useEffect, useState } from "react";

export const useBidExtension = ({
  acceptingBids,
  endTime,
}: {
  acceptingBids: boolean;
  endTime: Date | null;
}) => {
  const [endTimeChanged, setEndTimeChanged] = useState(false);
  const [timeExtendingBid, setTimeExtendingBid] = useState(false);
  const [, setCachedEndTime] = useState(endTime);

  useEffect(
    () =>
      setCachedEndTime((prevState) => {
        if (prevState && prevState !== null && prevState !== endTime) {
          setEndTimeChanged(true);
        }

        return endTime;
      }),
    [endTime],
  );

  useEffect(() => {
    if (!acceptingBids) return;

    if (endTimeChanged) {
      setTimeExtendingBid(true);
      setEndTimeChanged(false);

      setTimeout(() => setTimeExtendingBid(false), 3000);
    }
  }, [acceptingBids, endTimeChanged]);

  return {
    timeExtendingBid,
  };
};
