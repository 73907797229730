import moment from "moment";
import { useState } from "react";

import { useDraftAuction } from "../../hooks/useDraftAuction";
import {
  ContentSection,
  Copy,
  InlineButton,
  Label,
  Placeholder,
} from "../Capsule";

import { EditModal } from "./components/EditModal";
import {
  Address,
  Container,
  InlineLink,
  Spacer,
  StyledTimeLeft,
} from "./styles";

interface AuctionLiveShowProps {
  auctionId: string;
}

export const AuctionLiveShow = ({ auctionId }: AuctionLiveShowProps) => {
  const { auction, platform } = useDraftAuction(auctionId);
  const [showModal, setShowModal] = useState(false);
  const [copied, setCopied] = useState(false);

  const startTime = auction?.scheduledStartTime
    ? new Date(auction?.scheduledStartTime)
    : undefined;
  const formattedDate = moment(startTime).format("h:mm A, MMMM Do, YYYY");
  const isTwitch = platform === "Twitch";
  const isYouTube = platform === "YouTube";
  const modalState = {
    platform,
    startTime,
    twitchChannelName: auction?.twitchChannelName,
    twitchVideoId: auction?.twitchVideoId,
    youtubeVideoId: auction?.youtubeVideoId,
  };

  const handleToggle = () => setShowModal(!showModal);
  const handleCopy = async (value: string) => {
    await navigator.clipboard.writeText(value);

    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <>
      {showModal && (
        <EditModal
          auctionId={auctionId}
          handleToggle={handleToggle}
          show={showModal}
          state={modalState}
        />
      )}

      <Container edit={handleToggle} heading="Live show">
        <ContentSection>
          <Label>Scheduled start time</Label>
          {auction?.scheduledStartTime ? (
            <Spacer>
              <Copy>{formattedDate}</Copy>
              <StyledTimeLeft endTime={startTime} />
            </Spacer>
          ) : (
            <Placeholder>Not set</Placeholder>
          )}
        </ContentSection>

        <ContentSection>
          <Label>Streaming platform</Label>
          {platform ? (
            <Copy>{platform}</Copy>
          ) : (
            <Placeholder>Not set</Placeholder>
          )}
        </ContentSection>

        {isTwitch && (
          <>
            <ContentSection>
              <Label>Twitch channel name</Label>
              {auction?.twitchChannelName ? (
                <Spacer>
                  <Placeholder>
                    https://twitch.tv/
                    <Address>{auction?.twitchChannelName}</Address>
                  </Placeholder>

                  <Spacer>
                    <InlineButton
                      onClick={() =>
                        handleCopy(
                          `https://twitch.tv/${auction.twitchChannelName}`,
                        )
                      }
                    >
                      {copied ? "Copied!" : "Copy"}
                    </InlineButton>

                    <InlineLink
                      href={`https://twitch.tv/${auction.twitchChannelName}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      View
                    </InlineLink>
                  </Spacer>
                </Spacer>
              ) : (
                <Placeholder>Not set</Placeholder>
              )}
            </ContentSection>

            {auction?.twitchVideoId && (
              <ContentSection>
                <Label>Twitch VOD ID</Label>
                <Spacer>
                  <Placeholder>
                    https://twitch.tv/videos/
                    <Address>{auction?.twitchVideoId}</Address>
                  </Placeholder>

                  <Spacer>
                    <InlineButton
                      onClick={() =>
                        handleCopy(
                          `https://twitch.tv/videos/${auction.twitchVideoId}`,
                        )
                      }
                    >
                      {copied ? "Copied!" : "Copy"}
                    </InlineButton>

                    <InlineLink
                      href={`https://twitch.tv/videos/${auction.twitchVideoId}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      View
                    </InlineLink>
                  </Spacer>
                </Spacer>
              </ContentSection>
            )}
          </>
        )}

        {isYouTube && (
          <ContentSection>
            <Label>YouTube video ID</Label>
            {auction?.youtubeVideoId ? (
              <Spacer>
                <Placeholder>
                  https://youtube.com/watch?v=
                  <Address>{auction?.youtubeVideoId}</Address>
                </Placeholder>

                <Spacer>
                  <InlineButton
                    onClick={() =>
                      handleCopy(
                        `https://youtube.com/watch?v=${auction.youtubeVideoId}`,
                      )
                    }
                  >
                    {copied ? "Copied!" : "Copy"}
                  </InlineButton>

                  <InlineLink
                    href={`https://youtube.com/${auction.youtubeVideoId}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    View
                  </InlineLink>
                </Spacer>
              </Spacer>
            ) : (
              <Placeholder>Not set</Placeholder>
            )}
          </ContentSection>
        )}
      </Container>
    </>
  );
};
