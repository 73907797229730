import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { Page } from "../components/Contentful/Page";
import { ContentWrapper } from "../components/ContentWrapper";
import { logAmplitudeEvent } from "../utils/analytics";
import { SUPPORT_EVENTS } from "../utils/analytics/events";
import { markdown } from "../utils/contentful";

export const TermsOfService = () => {
  useEffect(
    () => logAmplitudeEvent(SUPPORT_EVENTS.TERMS_OF_SERVICE.PAGE_VIEWED),
    [],
  );

  return (
    <>
      <Helmet>
        <title>Terms of Service</title>
      </Helmet>

      <ContentWrapper>
        <Page slug="terms-of-service">
          {(page) => (
            <>
              <h1>Terms of Service</h1>
              {page.body && markdown(page.body)}
            </>
          )}
        </Page>
      </ContentWrapper>
    </>
  );
};
