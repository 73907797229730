import styled, { css } from "styled-components";

import { Gradients } from "../../themes/Default";

interface Props {
  cHeight: number;
  background?: string;
  cWidth: number;
}

export const Container = styled.div<Props>`
  background: ${Gradients.Placeholder};
  position: relative;

  ${({ background }) =>
    background &&
    css`
      background: url("${background}") center / cover no-repeat,
        ${Gradients.Placeholder};
    `}

  &::before {
    content: "";
    display: block;

    ${(props) =>
      css`
        padding-top: ${(props.cHeight / props.cWidth) * 100}%;
      `}
  }
`;

export const Image = styled.img`
  height: 100%;
  left: 0;
  line-height: 1;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`;
