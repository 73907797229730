import { Modal } from "@/components/Modal";
import { Connect, WalletProvider } from "@/features/wallet";

import { Container, customStyles } from "./styles";

export interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
}

export const ConnectWalletModal = ({ show, setShow }: Props) => (
  <>
    {show && (
      <Modal
        title="Connect wallet"
        isOpen={show}
        handleClose={() => setShow(false)}
        customStyles={customStyles}
      >
        <Container>
          <Connect
            provider={WalletProvider.METAMASK}
            show={show}
            setShow={setShow}
          />
        </Container>
      </Modal>
    )}
  </>
);
