import { gql } from "@apollo/client";

export const DISCONNECT_DISCORD_USER = gql`
  mutation DISCONNECT_DISCORD_USER {
    disconnectDiscordUser {
      errors {
        field
      }
    }
  }
`;
