import styled, { css } from "styled-components";

import { Borders, NewColors } from "@/themes/Default";

export const Select = styled.select<{ optionSelected?: boolean }>`
  /* stylelint-disable */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* stylelint-enable */
  appearance: none;
  background: white;
  border: ${Borders.User};
  border-radius: 0.5rem;
  color: ${NewColors.TextWhisper};
  font-weight: bold;
  line-height: 1.25rem;
  padding: 0.75rem 3rem 0.75rem 0.75rem;
  width: 100%;

  ${({ optionSelected }) =>
    optionSelected &&
    css`
      color: ${NewColors.TextShout};
    `}

  :disabled {
    background-color: ${NewColors.Deadfill};
  }
`;

export const SelectWrapper = styled.div`
  margin-top: 1rem;
  position: relative;

  ::after {
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid ${NewColors.TextSpeak};
    content: "";
    height: 0;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
  }
`;
