import styled from "styled-components";

import { NewColors } from "@/themes/Default";

import { Capsule } from "../Capsule";

export const Address = styled.span`
  color: ${NewColors.Black};
`;

export const Container = styled(Capsule)`
  grid-area: auction-summary;
`;

export const Controls = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;
