import styled, { css } from "styled-components";

import { ResponsiveImage } from "@/components/ResponsiveImage";
import { NewColors } from "@/themes/Default";

export const Image = styled(ResponsiveImage)`
  background: ${NewColors.Deadfill};
  border: 2px dashed rgb(31 31 35 / 20%);
`;

export const ImageContainer = styled.div<{
  disabled?: boolean;
  maxWidth?: number;
}>`
  border-radius: 0.5rem;
  cursor: pointer;
  outline-offset: 0.25rem;
  overflow: hidden;
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}rem;
    `}
`;

export const ImageCopy = styled.p`
  bottom: 0;
  color: ${NewColors.Primary};
  display: grid;
  font-size: 0.875rem;
  font-weight: bold;
  left: 0;
  margin: 0;
  place-items: center;
  position: absolute;
  right: 0;
  top: 0;
`;
