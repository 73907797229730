import { useApolloClient } from "@apollo/client";

import { useEnableMoralis } from "@/hooks/useEnableMoralis";
import Pages from "@/pages";
import { isLoggedInVar } from "@/utils/apolloConfig";
import { hasValidRefreshToken } from "@/utils/auth";

const App = () => {
  const client = useApolloClient();
  const writeInitialData = async () => {
    isLoggedInVar(hasValidRefreshToken());
  };
  writeInitialData();
  client.onClearStore(writeInitialData);

  useEnableMoralis();

  return <Pages />;
};

export default App;
