import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

import { CollapsibleSection } from "@/components/CollapsibleSection";
import { LoadingBoundary } from "@/components/LoadingBoundary";

import { useNft } from "../../hooks/useNft";

import {
  PropertyName,
  PropertyValue,
  ExternalLink,
  Heading,
  Image,
  ImageContainer,
  NftName,
  ProjectName,
  Properties,
  Section,
  Value,
} from "./styles";

interface Props {
  id: string | undefined;
}

export const NftDetails = ({ id }: Props) => {
  const { nftDetails, error, loading } = useNft(id);

  return (
    <LoadingBoundary error={error} loading={loading}>
      <Section hasImage={true}>
        <ImageContainer>
          <Image
            alt={`${nftDetails?.collection?.name} ${nftDetails?.name}`}
            height={1}
            src={nftDetails?.importedImageUri}
            width={1}
          />
        </ImageContainer>

        <Heading>
          <ProjectName>{nftDetails?.collection?.name}</ProjectName>
          <NftName>{nftDetails?.name}</NftName>
        </Heading>

        <ExternalLink icon={faUpRightFromSquare} variant="white">
          View token
        </ExternalLink>
      </Section>
      <Section hasImage={false}>
        <CollapsibleSection opened={false} title="Properties">
          <Properties>
            {nftDetails?.nftitemtraitSet?.edges?.map((trait) => (
              <Value key={trait?.node?.id}>
                <PropertyName>{trait?.node?.name.toUpperCase()}</PropertyName>
                <PropertyValue>{trait?.node?.value}</PropertyValue>
              </Value>
            ))}
          </Properties>
        </CollapsibleSection>
      </Section>
    </LoadingBoundary>
  );
};
