import { useMutation } from "@apollo/client";

import type { BidCreate, LotCurrency } from "@/generated/schema";
import { logAmplitudeEvent } from "@/utils/analytics";
import { AUCTIONS_EVENTS } from "@/utils/analytics/events";

import { CREATE_BID } from "../api/CREATE_BID";

import { useUpdateCache } from "./useUpdateCache";

interface MutationRequest {
  bidCreate: BidCreate;
}

export const useCreateBid = (
  lotId: string,
  bidCurrency: LotCurrency | undefined,
) => {
  const { addBid } = useUpdateCache();

  const [createBid] = useMutation<MutationRequest>(CREATE_BID, {
    variables: { lotId, bidCurrency },
    onError: () => {
      throw new Error("failed");
    },
    onQueryUpdated: () => {},
    onCompleted: ({ bidCreate: { bid, errors, lot } }) => {
      // TODO: Avoid duplication of useLot lotAnalytics
      const lotAnalytics = {
        "Lot ID": lot?.id,
        "Lot Name": lot?.name,
      };
      const bidAnalytics = {
        "Bid Price": bid?.bidPrice,
      };
      const responseAnalytics = {
        "Error Fields": errors?.map((e) => e?.field),
        "Error Messages": errors?.map((e) => e?.message),
      };

      const eventProperties = {
        ...lotAnalytics,
        ...bidAnalytics,
        ...responseAnalytics,
      };

      if (errors && errors.length > 0) {
        logAmplitudeEvent(AUCTIONS_EVENTS.BID.FAILED, eventProperties);
      } else {
        logAmplitudeEvent(AUCTIONS_EVENTS.BID.SUCCEEDED, eventProperties);

        const newBidProperties = {
          id: bid?.id,
          bidPriceCurrency: bidCurrency,
          bidPrice: bid?.bidPrice,
          createdAt: bid?.createdAt,
          state: bid?.state,
          user: {
            id: bid?.user.id,
            username: bid?.user.username,
          },
        };

        addBid({
          bid: {
            ...newBidProperties,
            lot: {
              id: lot?.id,
              biddingEndsAfter: lot?.biddingEndsAfter,
              bidPriceIncrement: lot?.bidPriceIncrement,
              currency: bidCurrency,
              highestBidPrice: lot?.highestBidPrice,
              minimumNextBidPrice: lot?.minimumNextBidPrice,
              name: lot?.name,
              order: lot?.order,

              highestBid: {
                ...newBidProperties,
              },
            },
          },
        });
      }
    },
  });

  return { createBid };
};
