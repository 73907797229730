import { useCallback, useEffect, useRef } from "react";

import { Container, Embed } from "./styles";

interface Props {
  autoplay?: boolean;
  channel?: string;
  id?: string;
  mute?: boolean;
  showChat?: boolean;
  vodId?: string;
}

let scriptElement: HTMLScriptElement | null = null;

const loadScript = () => {
  scriptElement = document.createElement("script");
  scriptElement.setAttribute("type", "text/javascript");
  scriptElement.setAttribute("src", "https://embed.twitch.tv/embed/v1.js");
  document.body.appendChild(scriptElement);
};

export const TwitchEmbed = ({
  autoplay = false,
  channel,
  id = "twitch-embed",
  mute = false,
  showChat = true,
  vodId,
}: Props) => {
  const embedRef = useRef<HTMLDivElement>(null);

  const createPlayer = useCallback(() => {
    const { Twitch } = window as any;
    if (embedRef && embedRef.current) embedRef.current.innerHTML = "";

    const embed = new Twitch.Embed(id, {
      autoplay,
      height: "100%",
      layout: showChat ? "video-with-chat" : "video",
      width: "100%",
      ...(channel && { channel }),
      ...(vodId && { video: vodId }),
    });

    embed.addEventListener(Twitch.Embed.VIDEO_READY, () => {
      const player = embed.getPlayer();

      player.setMuted(mute);
      player.play();
    });
  }, [autoplay, channel, id, mute, vodId, showChat]);

  useEffect(() => {
    if (!scriptElement) loadScript();

    if ((window as any).Twitch && (window as any).Twitch.Embed) {
      return createPlayer();
    }

    scriptElement?.addEventListener("load", () => createPlayer());
  }, [createPlayer]);

  const title = channel ? "Twitch channel name" : "Twitch video player";

  return (
    <Container showChat={showChat && !vodId}>
      <Embed id={id} title={title} ref={embedRef} />
    </Container>
  );
};
