import { EventCard, Price, PriceLabel } from "@/components/EventCard";
import { AuctionState } from "@/generated/schema";
import type { AuctionCurrency } from "@/generated/schema";
import { pluralize } from "@/utils/content";
import { formatCurrency } from "@/utils/getCurrency";

interface Props {
  currency: AuctionCurrency;
  description?: string;
  featuredRank?: number | null;
  image?: string | null;
  link?: string;
  lotCount?: number | null;
  openingPrice?: number | null;
  scheduledStartTime: Date;
  slug: string;
  state: AuctionState;
  title: string;
}

export const AuctionCard = ({
  currency,
  description,
  featuredRank = null,
  image,
  link,
  lotCount = 0,
  openingPrice,
  scheduledStartTime,
  slug,
  state,
  title,
}: Props) => {
  const price = openingPrice
    ? formatCurrency(openingPrice, currency)
    : "Coming soon…";
  const isFeatured =
    featuredRank !== null &&
    [AuctionState.LIVE, AuctionState.PUBLISHED].includes(state);
  const hasEnded = ![AuctionState.LIVE, AuctionState.PUBLISHED].includes(state);

  const infoLabel = (
    <>
      {hasEnded ? "Included" : "Includes"}{" "}
      <strong>{pluralize("lot", lotCount)}</strong>
    </>
  );

  return (
    <EventCard
      description={description}
      imageUrl={image}
      info={infoLabel}
      isFeatured={isFeatured}
      link={link ?? `/auctions/${slug}`}
      scheduledStartTime={scheduledStartTime}
      state={state}
      STATES={AuctionState}
      title={title}
    >
      <EventCard.LowerComponents>
        <PriceLabel>
          Opening bids from <Price>{price}</Price>
        </PriceLabel>
      </EventCard.LowerComponents>
    </EventCard>
  );
};
