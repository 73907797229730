import type { ReactNode } from "react";

import { useLot } from "../../hooks/useLot";

import {
  Children,
  Container,
  Heading,
  Image,
  ImageContainer,
  Name,
  Project,
} from "./styles";

interface Props {
  children?: ReactNode;
  id: string;
}

export const LotHeader = ({ children, id }: Props) => {
  const { lot } = useLot(id);

  return (
    <Container>
      <ImageContainer>
        <Image
          alt={`${lot.collection} ${lot.name}`}
          height={1}
          src={lot.image}
          width={1}
        />
      </ImageContainer>

      <Heading>
        <Project>{lot.collection}</Project>
        <Name>{lot.name}</Name>
      </Heading>

      {children && <Children>{children}</Children>}
    </Container>
  );
};
