import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import type { KeyboardEvent } from "react";

import { formatWalletAddress } from "@/utils/formatWalletAddress";

import { RemoveModal } from "./components/RemoveModal";
import {
  Avatar,
  Container,
  ImageContainer,
  Name,
  Manage,
  ManageButton,
  ManageIcon,
  Media,
  User,
  WalletAddress,
} from "./styles";

interface Props {
  avatar?: string;
  channelSlug: string;
  name?: string;
  manage?: boolean;
  teamMemberId: string;
  walletAddress: string;
}

export const TeamMember = ({
  avatar,
  channelSlug,
  manage,
  name,
  teamMemberId,
  walletAddress,
}: Props) => {
  const formattedAddress = formatWalletAddress({ address: walletAddress });
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);

  const handleKeydown = ({ key }: KeyboardEvent) => {
    if (key === "Enter") openModal();
  };

  return (
    <>
      {showModal && (
        <RemoveModal
          channelSlug={channelSlug}
          handleClose={() => setShowModal(false)}
          isOpen={showModal}
          teamMemberId={teamMemberId}
          walletAddress={formattedAddress}
        />
      )}

      <Container>
        <Media>
          <ImageContainer>
            {avatar && <Avatar alt="" src={avatar} />}
          </ImageContainer>

          {manage && (
            <Manage
              aria-label="Remove Member"
              onClick={openModal}
              onKeyDown={handleKeydown}
              tabIndex={0}
            >
              <ManageButton>
                <ManageIcon icon={faXmark} />
              </ManageButton>
            </Manage>
          )}
        </Media>

        <User>
          <Name to={`/users/${walletAddress}`} $active={!!name}>
            {name ? name : "Unnamed"}
          </Name>
          <WalletAddress>{formattedAddress}</WalletAddress>
        </User>
      </Container>
    </>
  );
};
