import { gql } from "@apollo/client";

export const AUCTION_DELETE = gql`
  mutation AUCTION_DELETE($auctionId: ID!) {
    auctionDraftDelete(auctionId: $auctionId) {
      errors {
        message
      }
    }
  }
`;
