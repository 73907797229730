import { useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate, useParams } from "react-router-dom";

import { Button } from "@/components/Button";
import { LoadingBoundary } from "@/components/LoadingBoundary";

import { AuctionsNavigation } from "../components/AuctionsNavigation";
import { CreateAuctionModal } from "../components/CreateAuctionModal";
import { Copy, Layout } from "../components/Layout";
import { useChannel } from "../hooks/useChannel";

export const Auctions = () => {
  const { channelSlug } = useParams();

  const { channel, hasAccess, loading } = useChannel(channelSlug ?? "");
  const [showModal, setShowModal] = useState(false);

  const handleToggle = () => setShowModal(!showModal);

  return (
    <LoadingBoundary loading={loading}>
      {hasAccess && channelSlug ? (
        <>
          <Helmet>
            <title>{channel?.name} - Auctions</title>
          </Helmet>

          {showModal && (
            <CreateAuctionModal
              channelSlug={channelSlug}
              handleClose={handleToggle}
              show={showModal}
            />
          )}

          <Layout heading="Auctions">
            <Copy>
              Create and manage your own livestreamed Grail auctions. You can
              schedule these in advance, and create drafts to get everything set
              before you go live.
            </Copy>

            <div>
              <Button onClick={handleToggle} variant="green">
                Create new auction
              </Button>
            </div>

            <AuctionsNavigation channelSlug={channelSlug} />
          </Layout>
        </>
      ) : (
        <Navigate replace to="/" />
      )}
    </LoadingBoundary>
  );
};
