import { useEffect } from "react";
import type { Dispatch, RefObject, SetStateAction } from "react";

export const useKeyboardNavigation = (
  ref: RefObject<HTMLElement>,
  setState: Dispatch<SetStateAction<boolean>>,
) => {
  useEffect(() => {
    const handleEscape = ({ key }: KeyboardEvent) => {
      if (key === "Escape") setState(false);
    };

    const handleTab = ({ key, target }: KeyboardEvent) => {
      if (key !== "Tab") return;

      const targetOutside = !ref?.current?.contains(target as Node);

      if (targetOutside) setState(false);
    };

    const handleOutsideClick = ({ target }: MouseEvent) => {
      const clickedOutside = !ref?.current?.contains(target as Node);

      if (clickedOutside) setState(false);
    };

    document.addEventListener("keydown", handleEscape);
    document.addEventListener("keyup", handleTab);
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("keyup", handleTab);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });
};
