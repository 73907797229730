import styled from "styled-components";

import { useFetchAccountData } from "@/hooks/useFetchAccountData";
import { Breakpoints } from "@/themes/Default";

import { EditProfile } from "../components/EditProfile";

const Wrapper = styled.div`
  display: flex;
  place-content: center;

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: 2rem;
  }
`;

export const Edit = () => {
  const {
    accountData: { email, username, discordUser },
  } = useFetchAccountData();

  return (
    <Wrapper>
      <EditProfile
        discordUser={discordUser}
        emailAddress={email}
        walletAddress={username!}
      />
    </Wrapper>
  );
};
