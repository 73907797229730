import type { FormEvent, HTMLInputTypeAttribute } from "react";

import { Error, Input as StyledInput, Label } from "../../styles";

import { Container, ErrorWrapper, InputWrapper, LabelWrapper } from "./styles";

interface Props {
  defaultValue?: string;
  description?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  handleInput(e: FormEvent<HTMLInputElement>): void;
  label: string;
  maxLength?: number;
  name: string;
  placeholder?: string;
  type: HTMLInputTypeAttribute;
}

export const InlineInput = ({
  defaultValue,
  disabled = false,
  error = false,
  errorMessage,
  handleInput,
  label,
  maxLength,
  name,
  placeholder = "",
  type,
}: Props) => {
  return (
    <Container>
      <LabelWrapper>
        <Label htmlFor={name}>{label}</Label>
      </LabelWrapper>

      <InputWrapper>
        <StyledInput
          defaultValue={defaultValue}
          disabled={disabled}
          error={error}
          id={name}
          name={name}
          maxLength={maxLength}
          placeholder={placeholder}
          onInput={handleInput}
          type={type}
        />
      </InputWrapper>

      {error && (
        <ErrorWrapper>
          <Error>⚠️ {errorMessage}</Error>
        </ErrorWrapper>
      )}
    </Container>
  );
};
