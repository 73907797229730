import MoralisType from "moralis";

import { CryptoCurrencies } from "@/generated/schema";
import EthIcon from "@/images/currencies/eth-icon.svg";
import SolIcon from "@/images/currencies/solana.png";

import MetaMaskIcon from "../assets/metamask-icon.svg";
import PhantomIcon from "../assets/phantom-icon.svg";
import { Currency, WalletProvider } from "../types";

interface WalletDetails {
  chain: CryptoCurrencies;
  externalUrl: string;
  provider: WalletProvider;
  walletIcon: string;
}

interface CurrencyDetails {
  currency: Currency;
  icon: string;
}

export const authenticateByChain = async (
  authenticate: (
    options?: MoralisType.AuthenticationOptions,
  ) => Promise<MoralisType.User | undefined>,
  chain: CryptoCurrencies,
) => {
  await authenticate({
    ...(chain === CryptoCurrencies.ETH && { provider: "metamask" }),
    ...(chain === CryptoCurrencies.SOL && { type: "sol" }),
    signingMessage:
      "Sign this message to to take part in NFT events and customize your profile",
  });
};

export const getMoralisEnvData = (chain?: CryptoCurrencies) => {
  const ethNetwork = process.env.REACT_APP_MORALIS_NETWORK_ETH;
  const solNetwork = process.env.REACT_APP_MORALIS_NETWORK_SOL;

  return {
    appId: process.env.REACT_APP_MORALIS_APP_ID ?? "",
    serverUrl: process.env.REACT_APP_MORALIS_SERVER_URL ?? "",
    ...(chain && {
      network: chain === CryptoCurrencies.SOL ? solNetwork : ethNetwork,
    }),
  };
};

export const getSolanaProvider = () => {
  if ("solana" in window) {
    const provider = (window as any).solana;
    if (provider.isPhantom) return WalletProvider.PHANTOM;
  }
};

export const getEthereumProvider = () => {
  if ("ethereum" in window) {
    const provider = (window as any).ethereum;
    if (!provider.providers && provider.isMetaMask)
      return WalletProvider.METAMASK;

    const metamaskInProvidersList = provider.providers.find(
      ({ isMetaMask }: any) => isMetaMask,
    );

    if (metamaskInProvidersList) {
      provider.setSelectedProvider(metamaskInProvidersList);
      return WalletProvider.METAMASK;
    }
  }
};

export const getAuthDataByChain = (
  chain: CryptoCurrencies,
  user: MoralisType.User | null,
) => {
  switch (chain) {
    case CryptoCurrencies.SOL:
      return {
        token: user?.attributes?.authData?.moralisSol?.data!,
        signature: user?.attributes?.authData?.moralisSol?.signature!,
      };
    case CryptoCurrencies.ETH:
      return {
        token: user?.attributes?.authData?.moralisEth?.data,
        signature: user?.attributes?.authData?.moralisEth?.signature,
      };
  }
};

export const getProviderByChain = (chain: CryptoCurrencies) => {
  switch (chain) {
    case CryptoCurrencies.SOL:
      return getSolanaProvider();
    case CryptoCurrencies.ETH:
      return getEthereumProvider();
  }
};

export const getWalletAddressByChain = (
  chain: CryptoCurrencies,
  user: MoralisType.User | null,
): string => {
  return chain === CryptoCurrencies.SOL
    ? user?.attributes?.solAddress!
    : user?.attributes?.ethAddress!;
};

export const getWalletByProvider = (provider: WalletProvider) =>
  Wallets[provider];

export const Wallets: { [key in WalletProvider]: WalletDetails } = {
  MetaMask: {
    chain: CryptoCurrencies.ETH,
    externalUrl: "https://metamask.io/",
    provider: WalletProvider.METAMASK,
    walletIcon: MetaMaskIcon,
  },
  Phantom: {
    chain: CryptoCurrencies.SOL,
    externalUrl: "https://phantom.app",
    provider: WalletProvider.PHANTOM,
    walletIcon: PhantomIcon,
  },
};

export const Currencies: { [key in Currency]: CurrencyDetails } = {
  ETH: {
    currency: Currency.ETH,
    icon: EthIcon,
  },
  SOL: {
    currency: Currency.SOL,
    icon: SolIcon,
  },
};

export const displayBalance = (balance: string, currency: Currency) => {
  return {
    balance: balance.slice(0, 6),
    currency,
  };
};
