import styled from "styled-components";

import { NewColors } from "@/themes/Default";

export const Buttons = styled.div`
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 12rem;
  justify-content: space-between;
`;

export const GoBack = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${NewColors.TextShout};
  display: flex;
  font-size: 1rem;
  gap: 0.75rem;
  line-height: 1.5rem;
  padding: 0;
`;
