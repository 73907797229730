import { Price, PriceLabel } from "@/components/EventCard";
import { BoxBreakState, CryptoCurrencies } from "@/generated/schema";
import { formatCurrency } from "@/utils/getCurrency";

import { VideoPanel } from "../../../events";

interface Props {
  currency: CryptoCurrencies;
  minPrice?: number | null;
  slug: string;
  spotAvailable: number;
  spotTotal: number;
  title: string;
  twitchChannelName?: string;
  youtubeVideoId?: string;
}

export const RemintVideoPanel = ({
  currency,
  minPrice,
  slug,
  spotAvailable,
  spotTotal,
  title,
  twitchChannelName,
  youtubeVideoId,
}: Props) => {
  const price = minPrice ? formatCurrency(minPrice, currency) : "Coming soon…";

  return (
    <VideoPanel
      currency={currency}
      info={`${spotAvailable}/${spotTotal} spots available`}
      link={`/remints/${slug}`}
      state={BoxBreakState.LIVE_OPEN}
      STATES={BoxBreakState}
      title={title}
      twitchChannelName={twitchChannelName}
      youtubeVideoId={youtubeVideoId}
    >
      <PriceLabel>
        Price per spot <Price>{price}</Price>
      </PriceLabel>
    </VideoPanel>
  );
};
