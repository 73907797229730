import { useQuery } from "@apollo/client";

import { NFTItemNodeConnection } from "@/generated/schema";

import { GET_USERS_NFTS } from "../api/GET_USERS_NFTS";

interface QueryResponse {
  importNftItemsForOwnerAddress: NFTItemNodeConnection;
}

export const useUserNFTs = (ownerAddress: string) => {
  const { data, error, loading } = useQuery<QueryResponse>(GET_USERS_NFTS, {
    variables: { ownerAddress },
  });

  return {
    error: !!error,
    loading,
    nftItems: data?.importNftItemsForOwnerAddress.edges,
  };
};
