import { useEffect, useState } from "react";

import {
  DateTimePicker,
  Error,
  Input,
  Select,
  SubmitButton,
  Success,
} from "@/components/FormElements";
import {
  Modal,
  ModalButtonContainer,
  ModalContent,
  smallModalStyle,
} from "@/components/Modal";
import { useForm } from "@/hooks/useForm";

import { useDraftAuction } from "../../../../hooks/useDraftAuction";

interface EditModalState {
  platform: "" | "Twitch" | "YouTube";
  startTime: Date | undefined;
  twitchChannelName: string | undefined;
  twitchVideoId: string | undefined;
  youtubeVideoId: string | undefined;
}

interface EditModalProps {
  auctionId: string;
  handleToggle(): void;
  show: boolean;
  state: EditModalState;
}

export const EditModal = ({
  auctionId,
  handleToggle,
  show,
  state,
}: EditModalProps) => {
  const { auctionDraftEdit, refetchAuction } = useDraftAuction(auctionId);
  const { formState, handleDateChange, handleInputChange } =
    useForm<EditModalState>({ ...state });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [error, setError]);

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => setSuccess(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [success, setSuccess]);

  const [editDraft, { loading: submitting }] = auctionDraftEdit;

  const isTwitch = formState.platform === "Twitch";
  const isYouTube = formState.platform === "YouTube";

  const canSubmit =
    !!(isTwitch && formState.twitchChannelName) ||
    (isYouTube && formState.youtubeVideoId?.length === 11) ||
    (!isTwitch && !isYouTube);

  const handleSubmit = () => {
    editDraft({
      onCompleted: ({ auctionDraftEdit: { errors } }) => {
        const hasErrors = !!errors?.length;

        setError(hasErrors);
        setSuccess(!hasErrors);

        if (!hasErrors) refetchAuction();
      },
      variables: {
        scheduledStartTime: formState.startTime,
        twitchChannelName: isTwitch ? formState.twitchChannelName : "",
        twitchVideoId: isTwitch ? formState.twitchVideoId : "",
        youtubeVideoId: isYouTube ? formState.youtubeVideoId : "",
      },
    });
  };

  return (
    <Modal
      customStyles={smallModalStyle}
      handleClose={handleToggle}
      isOpen={show}
      title="Edit live show"
    >
      <ModalContent>
        <DateTimePicker
          defaultValue={formState.startTime}
          description="Let people know when your live show is scheduled to begin. This is just a guide; you will need to manually begin your show, and kick off the stream itself on your chosen streaming platform."
          label="Scheduled start time"
          minDate={new Date()}
          name="startTime"
          onChange={handleDateChange}
        />

        <Select
          defaultValue={formState.platform}
          description="Where you intend to go live with your stream."
          handleChange={handleInputChange}
          label="Streaming platform"
          name="platform"
        >
          <option value="Twitch">Twitch</option>
          <option value="YouTube">YouTube</option>
        </Select>

        {isTwitch && (
          <Input
            defaultValue={formState.twitchChannelName}
            description="Enter the name of your channel on Twitch, and we will embed the live stream from it once you mark your auction show as LIVE."
            error={isTwitch && !formState.twitchChannelName}
            errorMessage="Channel Name is required"
            handleInput={handleInputChange}
            label="Twitch channel name"
            name="twitchChannelName"
            type="text"
          />
        )}

        {isTwitch && (
          <Input
            defaultValue={formState.twitchVideoId}
            description="The video ID for playing back the Twitch stream after it has streamed (digits only)."
            handleInput={handleInputChange}
            label="Twitch video ID"
            name="twitchVideoId"
            type="text"
          />
        )}

        {isYouTube && (
          <Input
            defaultValue={formState.youtubeVideoId}
            description="Enter the 11 character video ID for your scheduled YouTube stream. You can find this at the end of the YouTube URL for you video."
            error={isYouTube && formState.youtubeVideoId?.length !== 11}
            errorMessage="Video ID is required and must be 11 characters"
            handleInput={handleInputChange}
            label="YouTube video ID"
            name="youtubeVideoId"
            minLength={11}
            maxLength={11}
            type="text"
          />
        )}

        <ModalButtonContainer>
          <SubmitButton
            disabled={!canSubmit || submitting}
            onClick={handleSubmit}
            submitting={submitting}
            variant="green"
          >
            Save changes
          </SubmitButton>
        </ModalButtonContainer>

        {error && <Error>❌ Something went wrong, please try again!</Error>}
        {success && <Success>✅ Auction has been updated</Success>}
      </ModalContent>
    </Modal>
  );
};
