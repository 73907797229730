import moment from "moment";

import { EventEventType } from "../../generated/schema";
import type { AuctionState, BoxBreakState } from "../../generated/schema";
import { TwitchEmbed } from "../TwitchEmbed";
import { YouTubeEmbed } from "../YouTubeEmbed";

import {
  Container,
  LiveContainer,
  LiveCopy,
  LiveHeading,
  NoReplay,
  VideoContainer,
  WaitingContainer,
  WaitingCopy,
  WaitingHeading,
  Wrapper,
} from "./styles";

type EventState = AuctionState | BoxBreakState | undefined;

interface EmbedSettings {
  eventState: EventState;
  youtubeVideoId: string | undefined;
  twitchChannelName: string | undefined;
  twitchVideoId?: string | undefined;
}

interface Props {
  autoplay?: boolean;
  event?: EventEventType | null;
  eventState?: EventState;
  scheduledStartTime: Date | null;
  showChat?: boolean;
  twitchVideoId?: string;
  twitchChannelName?: string;
  youtubeVideoId?: string;
}

const hasVideo = ({
  eventState,
  twitchChannelName,
  youtubeVideoId,
}: EmbedSettings) => {
  switch (eventState) {
    case "PUBLISHED":
    case "PUBLISHED_OPEN":
    case "PUBLISHED_LOCKED":
      return !!youtubeVideoId;
    case "LIVE":
    case "LIVE_OPEN":
    case "LIVE_LOCKED":
      return !!(youtubeVideoId || twitchChannelName);
    case "ENDED":
    case "SETTLED":
      return true;
    default:
      return false;
  }
};

export const LiveStreamSection = ({
  autoplay = true,
  event,
  eventState,
  scheduledStartTime,
  showChat = false,
  twitchChannelName,
  twitchVideoId,
  youtubeVideoId,
}: Props) => {
  const containsVideo = hasVideo({
    eventState,
    twitchChannelName,
    youtubeVideoId,
  });

  const formattedWaitingDate =
    moment(scheduledStartTime).format("MMMM Do - h:mm A");

  const renderHeading = () => {
    if (scheduledStartTime) {
      return (
        <>
          Live stream begins at
          <span>{formattedWaitingDate}</span>
        </>
      );
    }

    return event === EventEventType.BREAK ? (
      <>
        Live stream
        <span>Scheduled when filled</span>
      </>
    ) : (
      <>
        Live stream
        <span>Coming Soon</span>
      </>
    );
  };

  const returnSingleEmbeddedPlayer = ({
    eventState,
    twitchChannelName,
    twitchVideoId,
    youtubeVideoId,
  }: EmbedSettings) => {
    switch (eventState) {
      case "PUBLISHED":
      case "PUBLISHED_OPEN":
      case "PUBLISHED_LOCKED":
        return (
          youtubeVideoId && (
            <YouTubeEmbed
              autoplay={autoplay}
              showChat={showChat}
              videoId={youtubeVideoId}
            />
          )
        );

      case "LIVE":
      case "LIVE_OPEN":
      case "LIVE_LOCKED":
        if (twitchChannelName) {
          return (
            <TwitchEmbed autoplay={autoplay} channel={twitchChannelName} />
          );
        }

        if (youtubeVideoId) {
          return <YouTubeEmbed autoplay={autoplay} videoId={youtubeVideoId} />;
        }

        break;

      case "ENDED":
      case "SETTLED":
        if (twitchVideoId) {
          return (
            <TwitchEmbed
              autoplay={autoplay}
              showChat={showChat}
              vodId={twitchVideoId}
            />
          );
        }

        if (youtubeVideoId) {
          return (
            <YouTubeEmbed
              autoplay={autoplay}
              showChat={showChat}
              videoId={youtubeVideoId}
            />
          );
        }

        return (
          <NoReplay title="Replay not available">
            Replay video not available.
          </NoReplay>
        );
    }
  };

  return (
    <Container id="livestream">
      <Wrapper>
        {containsVideo ? (
          <>
            <LiveContainer>
              <LiveHeading>▶️ Live stream</LiveHeading>
              <LiveCopy>
                Follow all the action or join the conversation in the live
                stream.
              </LiveCopy>
            </LiveContainer>

            <VideoContainer>
              {returnSingleEmbeddedPlayer({
                eventState,
                twitchChannelName,
                twitchVideoId,
                youtubeVideoId,
              })}
            </VideoContainer>
          </>
        ) : (
          <WaitingContainer>
            <WaitingHeading>{renderHeading()}</WaitingHeading>

            <WaitingCopy>
              The video will appear here shortly before the event begins.
            </WaitingCopy>
          </WaitingContainer>
        )}
      </Wrapper>
    </Container>
  );
};
