import styled from "styled-components";

import { Alert } from "@/components/Alert";
import { Colors } from "@/themes/Default";

export const LogosContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Colors.Border};
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 2rem 0;
  width: 100%;

  @media (max-width: 40rem) {
    flex-direction: column;
  }
`;

export const GrailLogoImg = styled.img`
  height: 3rem;
  width: auto;
`;

export const DiscordLogoImg = styled.img`
  height: 4rem;
  width: auto;
`;

export const LogoSeparator = styled.span`
  display: block;
  font-size: 2rem;
  padding: 0 1rem;
`;

export const Status = styled.p`
  font-weight: bold;
  margin: 0;
`;

export const StyledAlert = styled(Alert)`
  text-align: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  max-width: 35rem;
  padding: 2.5rem;
  place-items: center;
  width: 100%;
`;
