import styled, { css } from "styled-components";

import { Borders, Breakpoints, NewColors } from "@/themes/Default";

export const ApplyButton = styled.a`
  align-items: center;
  background-color: white;
  border: 1px solid ${NewColors.Primary};
  border-radius: 0.5rem;
  color: ${NewColors.Primary};
  display: flex;
  font-weight: 700;
  gap: 0.5rem;
  justify-content: center;
  padding: 0.75rem;
  text-align: center;
  width: 14rem;

  :active,
  :focus,
  :hover {
    background-color: ${NewColors.PrimaryOverlay};
    box-shadow: 0 2px 0 ${NewColors.PrimaryShadow};
    color: ${NewColors.Primary};
    text-decoration: none;
    transform: translateY(-2px);
    transition: 200ms ease-in-out;
  }
`;

export const Container = styled.div`
  background: white;
  border-bottom: ${Borders.User};
  color: ${NewColors.TextSpeak};
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  padding: 1rem;
  width: 100%;

  @media (min-width: ${Breakpoints.Mobile}) {
    border: ${Borders.User};
    border-radius: 1rem;
    box-shadow: 0 8px 16px 0 rgb(74 75 79 / 20%);
    max-width: 36rem;
    padding: 2rem;
  }
`;

export const CopyAddress = styled.button`
  background: transparent;
  border: 0;
  color: ${NewColors.Primary};
  display: inline;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.125rem;
  opacity: 0.8;
`;

export const Form = styled.div`
  display: grid;
  gap: 2rem;
`;

export const FormCopy = styled.p`
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0;

  & + & {
    margin-top: 1rem;
  }
`;

export const FormHeading = styled.h2`
  color: ${NewColors.TextShout};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin: 0;
`;

export const FormInput = styled.input<{ error?: boolean }>`
  border: ${Borders.User};
  border-radius: 0.5rem;
  color: ${NewColors.TextShout};
  font-weight: bold;
  line-height: 1.25rem;
  padding: 0.75rem;
  width: 100%;

  :disabled {
    background-color: ${NewColors.Deadfill};
  }

  ::placeholder {
    color: ${NewColors.TextWhisper};
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${NewColors.Negative};
    `}
`;

export const FormSection = styled.div`
  display: grid;
  gap: 1rem;
`;

export const Heading = styled.h1`
  color: ${NewColors.TextShout};
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.375rem;
  margin: 0;
`;

export const HeadingWithButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubmitButtonContainer = styled.div`
  width: 12rem;
`;

export const Success = styled.p`
  color: ${NewColors.Primary};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin: 0;
`;
