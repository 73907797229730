import { useState } from "react";

import { Button } from "@/components/Button";
import { EmptyTab } from "@/components/EmptyTab";
import { LoadingBoundary } from "@/components/LoadingBoundary";

import { useChannel } from "../../hooks/useChannel";
import { TeamMember } from "../TeamMember";

import { AddModal } from "./components/AddModal";
import { Container, Content, Copy, Heading, Members } from "./styles";

interface Props {
  channelSlug: string;
}

export const TeamMemberList = ({ channelSlug }: Props) => {
  const { admins, error, hasMembers, isOwner, loading, owners } =
    useChannel(channelSlug);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <AddModal
          channelSlug={channelSlug}
          handleClose={() => setShowModal(false)}
          isOpen={showModal}
        />
      )}

      <Container>
        {isOwner && (
          <div>
            <Button onClick={() => setShowModal(true)} variant="green">
              Add new team member
            </Button>
          </div>
        )}

        <div>
          <Heading>Channel owners</Heading>
          <Copy>
            Channel owners can carry out any actions on the channel, including
            adding or removing other team members.
          </Copy>

          <Content>
            <LoadingBoundary error={error} loading={loading}>
              <Members>
                {owners?.map((owner) => (
                  <TeamMember
                    key={owner?.node?.user.id}
                    channelSlug={channelSlug}
                    teamMemberId={owner.node?.id!}
                    walletAddress={owner?.node?.user.username!}
                  />
                ))}
              </Members>
            </LoadingBoundary>
          </Content>
        </div>

        <div>
          <Heading>Team members</Heading>
          <Copy>
            Can create and schedule auctions, import NFTs, and edit the channel
            profile. Cannot add or remove other team members.
          </Copy>

          <Content>
            <LoadingBoundary loading={loading} error={error}>
              {hasMembers ? (
                <Members>
                  {admins?.map((admin) => (
                    <TeamMember
                      key={admin?.node?.user.id}
                      channelSlug={channelSlug}
                      manage={isOwner}
                      teamMemberId={admin.node?.id!}
                      walletAddress={admin?.node?.user.username!}
                    />
                  ))}
                </Members>
              ) : (
                <EmptyTab copy="NO_MEMBER_PLACEHOLDER" heading="No members" />
              )}
            </LoadingBoundary>
          </Content>
        </div>
      </Container>
    </>
  );
};
