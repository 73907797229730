import DatePicker from "react-datepicker";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import "./ReactDatePicker.css";

import { Button } from "@/components/Button";

import { Input } from "../../styles";

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 9rem;
`;

export const StyledButton = styled(Button)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`;

export const StyledInput = styled(Input)`
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
`;

export const StyledDatePicker = styled(DatePicker)``;
