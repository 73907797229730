import { Helmet } from "react-helmet";
import { Navigate, useParams } from "react-router-dom";

import { LoadingBoundary } from "@/components/LoadingBoundary";

import { Copy, Layout } from "../components/Layout";
import { TeamMemberList } from "../components/TeamMemberList";
import { useChannel } from "../hooks/useChannel";

export const TeamMembers = () => {
  const { channelSlug } = useParams();
  const { channel, hasAccess, loading } = useChannel(channelSlug ?? "");

  return (
    <LoadingBoundary loading={loading}>
      {hasAccess && channelSlug ? (
        <>
          <Helmet>
            <title>{channel?.name} - Team members</title>
          </Helmet>

          <Layout heading="Team members">
            <Copy>
              Add additional team members to allow other connected Grail wallets
              to manage this channel.
            </Copy>

            <TeamMemberList channelSlug={channelSlug} />
          </Layout>
        </>
      ) : (
        <Navigate replace to="/" />
      )}
    </LoadingBoundary>
  );
};
