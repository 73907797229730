import { gql } from "@apollo/client";

export const UPDATE_LOT_NFT_APPROVAL_STATE_TO_APPROVING = gql`
  mutation updateLotNftApprovalStateToApproving($lotId: ID!) {
    updateLotNftApprovalStateToApproving(lotId: $lotId) {
      errors {
        field
        message
      }
    }
  }
`;
