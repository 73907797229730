import { useParams } from "react-router-dom";

import { NftDetails } from "../components/NftDetails";

export const Nft = () => {
  const { id } = useParams();

  return (
    <NftDetails id={id} />
  );
};
