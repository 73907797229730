import styled from "styled-components";

import { Colors } from "../../themes/Default";

import { AlertState } from "./Alert";

interface Color {
  background: string;
  border: string;
}

const states: { [key in AlertState]: Color } = {
  neutral: {
    background: "#E3EAF8",
    border: "#B4BFD6",
  },
  negative: {
    background: "#F9E7EB",
    border: "#F99B9B",
  },
  positive: {
    background: "#DCF7E5",
    border: "#9AEEA9",
  },
  warning: {
    background: "#F6F8F0",
    border: "#D7D88A",
  },
};

export const Container = styled.div<{ state: AlertState }>`
  background-color: ${({ state }) => states[state].background};
  border: 1px solid ${({ state }) => states[state].border};
  border-radius: 0.5rem;
  color: ${Colors.Astronaut};
  padding: 1rem;

  a {
    color: inherit;
    font-weight: 700;
  }

  & + & {
    margin-top: 1rem;
  }
`;
