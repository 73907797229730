import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";

import { escrowService } from "../abis/escrowService";

interface Props {
  smartContractLotId: number;
  value: number;
}

export const useCompleteSale = ({ smartContractLotId, value }: Props) => {
  const { REACT_APP_SMART_CONTRACT_ETH_ADDRESS: smartContractAddress } =
    process.env;

  const { account, Moralis } = useMoralis();
  const { data, error, fetch, isFetching } = useWeb3ExecuteFunction({
    abi: escrowService,
    contractAddress: smartContractAddress,
    functionName: "completeSale",
    msgValue: Moralis.Units.ETH(value),
    params: {
      user: account,
      lotId: smartContractLotId,
    },
  });

  return {
    data,
    error,
    fetch,
    isFetching,
  };
};
