import styled from "styled-components";

import { NewColors } from "@/themes/Default";

export const Container = styled.div`
  display: grid;
  min-height: 15rem;
  place-items: center;
`;

export const Content = styled.div`
  display: grid;
  gap: 0.875rem;
  max-width: 16.5rem;
  text-align: center;
`;

export const Copy = styled.p`
  color: ${NewColors.TextSpeak};
  line-height: 1.5rem;
  margin: 0;
  opacity: 0.8;
  text-align: center;
`;

export const Heading = styled.h3`
  color: ${NewColors.TextShout};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  margin: 0;
  opacity: 0.8;
`;
