import styled from "styled-components";

import { NewColors } from "@/themes/Default";

export const Container = styled.div`
  align-items: center;
  color: ${NewColors.TextSpeak};
  column-gap: 1rem;
  display: grid;
  font-family: Quicksand, sans-serif;
  grid-template-areas:
    "label input"
    ". error";
  grid-template-columns: 25% 1fr;
`;

export const ErrorWrapper = styled.div`
  grid-area: error;
  margin-top: 1rem;
`;

export const InputWrapper = styled.div`
  grid-area: input;
`;

export const LabelWrapper = styled.div`
  grid-area: label;
`;
